import { IconButton, Tooltip } from "@mui/material";

/**
 * Thgis file will supply an IconButton depending on the users input
 * @param {} param0
 * @returns
 */
function GenericIconButton({
  tooltip,
  onClick,
  color,
  children,
  ...restprops
}) {
  return (
    <>
      {tooltip && (
        <Tooltip title={tooltip}>
          <span>
            <IconButton
              onClick={onClick}
              color={color ?? "primary"}
              {...restprops}
            >
              {children}
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
}
export default GenericIconButton;
