import InvoiceOverview from "../components/invoices/invoiceOverview/InvoiceOverview";
import { Page } from "../components/shared/default/Page";

/**
 * Invoice Screen
 * @returns {JSX.Element}
 */
const InvoiceScreen = () => {
  return <Page content={<InvoiceOverview />} />;
};
export default InvoiceScreen;
