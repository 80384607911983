import PageviewIcon from "@mui/icons-material/Pageview";
import GenericIconButton from "./GenericIconButton";

export default function DetailButton({
  tooltip,
  onClick,
  color,
  ...restprops
}) {
  return (
    <GenericIconButton tooltip={tooltip} onClick={onClick} {...restprops}>
      <PageviewIcon color={color} />
    </GenericIconButton>
  );
}
