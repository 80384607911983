import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { number, object, string } from "yup";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stack,
} from "@mui/material";
import { FormTextfield } from "../../shared/forms/form-elements/FormTextfield";
import { FormNumberfield } from "../../shared/forms/form-elements/FormNumberField";
import { urlPostCost } from "./../../../api/urls/cost";
import { urlPostInvoiceLine } from "./../../../api/urls/invoiceline";
import { axiosInstance } from "../../../api/axios";

export default function CreateInvoiceLineModal({
  open,
  close,
  invoiceId,
  patientId,
  therapistId,
  handleSuccess,
  handleFailed,
  setFailed,
}) {
  const [t] = useTranslation("costs");
  const invoiceLineSchema = object({
    title: string().required().default(""),
    costAmount: string()
      .matches(/^[0-9]*\.?[0-9]*$/, t("amounterror")) //todo translate
      .required(),
  }).required();
  const {
    control,
    formState: { errors /*, isValid, isValidating */ },
    handleSubmit,
    setValue,
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(invoiceLineSchema),
  });

  useEffect(() => {
    setValue("title", "");
    setValue("costAmount", "");
    return () => {};
  }, [setValue]);

  const handleClose = () => close();

  const handleValidSubmit = (validObject) => {
    /*
     * Create cost
     */
    const urlCreateCost = urlPostCost();
    axiosInstance
      .post(urlCreateCost, {
        title: validObject.title,
        costAmount: Number(Number(validObject.costAmount).toFixed(2)),
        // Add additional fields to cost for POST request
        id: 0,
        therapistId: therapistId,
        patientId: patientId,
        compensationType: 0, // NONE
        complementaryPayBackAmount: 0,
        valuta: 0, // = euro
      })
      .then((response) => {
        const reponseCostId = response.data;
        /*
         * Create invoiceline
         */
        const urlCreateInvoiceline = urlPostInvoiceLine();
        axiosInstance
          .post(urlCreateInvoiceline, {
            costId: reponseCostId,
            invoiceId: Number(invoiceId),
            info: validObject.title,
            hasBeenCredited: false,
          })
          .then(() => {
            handleSuccess();
            close();
          })
          .catch(() => handleFailed(t("snackbars.failure.lines.add")));
      })
      .catch(() => setFailed(t("snackbars.failure.lines.addcost")));
  };

  const handleInvalidSubmit = (errors) =>
    setFailed(t("snackbars.failure.invalid"));

  return (
    <Dialog open={open} onClose={handleClose}>
      <form
        style={{ margin: "unset" }}
        onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}
      >
        <DialogTitle>{t("modal.addindividualcost.title")}</DialogTitle>
        <DialogContent>
          <FormTextfield
            control={control}
            name="title"
            label={t("form.fields.title")}
            errors={errors.title}
          />
          <FormNumberfield
            control={control}
            name="costAmount"
            label={t("form.fields.costAmount")}
            errors={errors.costAmount}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">{t("modal.addindividualcost.submit")}</Button>
          <Button onClick={handleClose}>
            {t("modal.addindividualcost.cancel")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
