import { initialstateFilters } from "../../constants/initialstates-constants";

/**
 * Checks if the filter
 * @param {any} value
 * @returns {boolean} checkFilter
 */
export const validUrlFilter = (value) => {
  return (
    value !== undefined &&
    value !== null &&
    value !== initialstateFilters.boolean &&
    value !== initialstateFilters.dateRange &&
    value !== initialstateFilters.string &&
    value !== initialstateFilters.number &&
    value !== initialstateFilters.userPicker
  );
};
