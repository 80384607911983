import { Card, CardContent, Stack } from "@mui/material";
import React from "react";

/**
 * A consultation set holds a list of consultations in that set, as well as details regarding the amount of consultations and payback status.
 * @returns
 */
function Detail({ children }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        maxHeight: "inherit",
      }}
    >
      <Card
        variant="outlined"
        className="card-list__item--wider card-list__item--normal"
        sx={{ minWidth: "50em", width: "fit-content", position: "relative" }}
      >
        <CardContent sx={{ overflowY: "auto" }}>
          <Stack direction="column" spacing={2}>
            {children}
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
}
export default Detail;
