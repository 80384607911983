import { Button, Tab, Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { axiosInstance, handleAxiosError } from "../../../api/axios";
import SimpleAlert from "../../shared/alerts/SimpleAlert";
import Header from "../../shared/default/Header";
import PageLoading from "../../shared/loading/PageLoading";
import ConfirmationModal from "../../shared/menus/modals/ConfirmationModal";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import HorizontalTabs from "../../shared/tabs/HorizontalTabs";
import TabPanel from "../../shared/tabs/TabPanel";
import CreateCreditnoteModal from "../shared/CreateCreditnoteModal";
import InvoiceExportModal from "../shared/InvoiceExportModal";
import SuccesSnackbar from "./../../shared/menus/snackbars/SuccesSnackbar";
import CreditNoteOverviewDetail from "./CreditNoteOverviewDetail";
import InvoiceOverviewDetail from "./InvoiceOverviewDetail";
import InvoiceOverviewLines from "./InvoiceOverviewLines";
import useAuth from "../../../auth/useAuth";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PaidIcon from "@mui/icons-material/Paid";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DownloadIcon from "@mui/icons-material/Download";

// Urls
import {
  urlDeleteInvoiceById,
  urlGetInvoiceById,
  urlPutInvoiceExportNote,
  urlPutInvoicePaid,
} from "../../../api/urls/invoice";

// Helpers
import {
  isCreditableInvoice,
  isDeleteableInvoice,
  isDeleteableInvoiceLine,
  isDownloadableInvoice,
  isEditableInvoice,
  isExportableInvoice,
  isPayableInvoice,
} from "../../../helpers/invoice";

import DownloadModal from "../../shared/menus/modals/DownloadModal";

/**
 * Invoice Overview
 * @returns {JSX.Element}
 */
export default function InvoiceOverview() {
  const { invoiceId } = useParams();
  const history = useHistory();
  const [t] = useTranslation("invoices");

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();

  const [failed, setFailed] = useState();
  const [succes, setSucces] = useState();

  const [invoice, setInvoice] = useState();
  const { id } = useAuth();
  const [changed, setChanged] = useState(false);
  const updateInvoice = () => setChanged(!changed);

  const [deletePrompted, setDeletePrompted] = useState(false);
  const [creditNotePrompted, setCreditNotePrompted] = useState(false);
  const [exportPrompted, setExportPrompted] = useState(false);
  const [paidPrompted, setPaidPrompted] = useState(false);
  const [downloadPrompted, setDownloadPrompted] = useState(false);
  const [hasRefundableCosts, setHasRefundableCosts] = useState(false); //This does noet include individual costs

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    /*
     * Get invoice by id
     */
    const url = urlGetInvoiceById(invoiceId);
    axiosInstance
      .get(url, {
        setsRequestCancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const invoiceFromResponse = response.data.invoice;
        setInvoice(invoiceFromResponse);

        invoiceFromResponse.invoiceLines.forEach((element) => {
          if (element.costDto.consultationId) {
            setHasRefundableCosts(true);
          }
        });
        setIsLoadingPage(false);
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: "error.request.get.invoice",
          axiosError: handleAxiosError(error),
        })
      );

    return () => {
      cancelTokenSource.cancel();
    };
  }, [invoiceId, t, changed]);

  /**
   * Tabs for different sub screens
   * @returns {Tab[]} tabs
   */
  const tabs = [
    <Tab key={0} label={t("detail.tab.detail")} />,
    <Tab key={1} label={t("detail.tab.lines")} />,
  ];

  /**
   * Deletes the invoice from the server
   * @param {number} id
   */
  const deleteInvoice = (id) => {
    const url = urlDeleteInvoiceById(id);
    axiosInstance
      .delete(url)
      .then((response) => {
        history.push("/invoices");
      })
      .catch((error) => {
        console.error(`DELETE ${url}`, error);
        setFailed(t("snackbars.failure.delete"));
      });
  };

  /**
   * Route to newly created creditnote for this invoice
   * @param {number} creditNoteId
   */
  const handleSuccesCreateCreditNote = (createdCreditNoteId) => {
    closeCreateCreditNoteModal();
    setSucces(true);
    history.push(`/invoices/${createdCreditNoteId}`);
  };

  /**
   * Updates the invoice to a paid one
   * @param {number} invoiceId invoiceId
   */
  const updateInvoiceToPaid = (invoiceId) => {
    const url = urlPutInvoicePaid(invoiceId);
    axiosInstance
      .put(url)
      .then((succes) => {
        setSucces(true);
        updateInvoice();
      })
      .catch((error) => {
        console.error(`PUT ${url}`, error);
        setFailed(t("snackbars.failure.paid"));
      });
  };

  /**
   * Updates the invoice exportNotes
   * @param {{exportNote: string}} validObject
   */

  const updateExportNote = (validObject) => {
    const invoiceId = Number(exportPrompted);
    const exportNotes = validObject.exportNotes;
    const url = urlPutInvoiceExportNote(invoiceId);
    axiosInstance
      .put(url, {
        exportNotes: exportNotes,
        hasRefundableCosts: hasRefundableCosts,
      })
      .then((response) => {
        //If there is a thirdpayer prompt modal else redirect to invoice
        if (invoice.thirdPayerSystem) {
          setDownloadPrompted(true);
          invoice.isProcessed = true;
        } else {
          const updatedInvoiceId = response.data.invoiceId;
          history.push(`/invoices/${updatedInvoiceId}/export`);
        }
      })
      .catch((error) => {
        console.error(`PUT ${url}`, error);
        setFailed(t("snackbars.failure.exportnote"));
      });
    closeInvoiceExportModal();
  };

  /**
   * Gives back the type of the invoice as a string
   * @returns {string} type of invoice
   */
  const getTypeInvoiceString = () => {
    return invoice && invoice.isCreditNote === true
      ? t("creditnote")
      : t("invoice");
  };

  /**
   * Executes the {@link getTypeinString} function so the JSX doesn't have to
   * @see {@link getTypeinString}
   * @returns {string} type of invoice
   */
  const typeInvoiceString = getTypeInvoiceString();

  /**
   * This function will act as an middleman between isDeletableInvoiceLine and the InvoiceOverviewLines component.
   * It will pass the boolean from the isDetetableInvoiceLine.
   * @see {@link isDeleteableInvoiceLine}
   * @see {@link InvoiceOverviewLines}
   * @returns {boolean} deletable
   */
  const canInvoiceLinesBeDeleted = () => isDeleteableInvoiceLine(invoice);

  /**
   * It will pass the boolean from isDeleteableInvoice.
   * @see {@link isDeleteableInvoice}
   * @returns {boolean} detetable
   */
  const canInvoiceBeDeleted = () => isDeleteableInvoice(invoice);

  /**
   * This function will act as an middleman between isEditableInvoice and the InvoiceOverviewLines component.
   * It will pass the boolean from the isEditableInvoice.
   * @see {@link isEditableInvoice}
   * @see {@link InvoiceOverviewLines}
   * @returns {boolean} editable
   */
  const canInvoiceBeEdited = () => isEditableInvoice(invoice);

  /**
   * This function will act as an middleman between isDownloadableInvoice and the InvoiceOverview component.
   * It will pass the boolean from the isDownloadableInvoice.
   * @see {@link isDownloadableInvoice}
   * @returns {boolean} downloadable
   */
  const canInvoiceBeDownloaded = () => isDownloadableInvoice(invoice);

  /**
   * This function will act as an middleman between isCreditdableInvoice and the InvoiceOverview component.
   * It will pass the boolean from the isCreditdableInvoice.
   * @returns {boolean} creditable
   */
  const canInvoiceBeCredited = () => isCreditableInvoice(invoice);

  /**
   * This function will act as an middleman between isExportableInvoice and the InvoiceOverview component.
   * It will pass the boolean from the isExportableInvoice.
   * @returns {boolean} exportable
   */
  const canInvoiceBeExported = () => isExportableInvoice(invoice);

  /**
   * This function will act as an middleman between isPayableInvoice and the InvoiceOverview component.
   * It will pass the boolean from the isPayableInvoice.
   * @returns {boolean} payable
   */
  const canInvoiceBePaid = () => isPayableInvoice(invoice);

  /**
   * Closes the Invoice export modal
   */
  const closeInvoiceExportModal = () => setExportPrompted(false);

  /**
   * Closes the download export modal
   */
  const closeDownloadModal = () => setDownloadPrompted(false);

  /**
   * Closes the Create creditnote modal
   */
  const closeCreateCreditNoteModal = () => setCreditNotePrompted(false);

  /**
   * loses the Create creditnote modal and displays the error message
   * @param {string} failedMessage
   */
  const closeCreateCreditNoteModalHandleFailed = (failedMessage) => {
    closeCreateCreditNoteModal();
    setFailed(failedMessage);
  };

  /**
   * Returns array with actions
   * @param {Invoice} invoice
   * @returns {Button | Tooltip[]} Array of buttons
   */
  const headerItems = (i) => {
    const headerActions = [];

    // Export button
    const exportable = canInvoiceBeExported();
    const exportButton = (
      <Button
        variant="outlined"
        disabled={!exportable}
        onClick={() => setExportPrompted(i.id)}
        startIcon={<ExitToAppIcon />}
      >
        {t("actions.export")}
      </Button>
    );
    if (exportable) {
      headerActions.push(
        <Tooltip
          title={t("tooltips.title.export", { type: typeInvoiceString })}
        >
          {exportButton}
        </Tooltip>
      );
    } else {
      headerActions.push(exportButton);
    }

    // Download button (Modal)
    const creditable = canInvoiceBeCredited();
    const downloadable = canInvoiceBeDownloaded();
    const downloadModal = (
      <Button
        variant="outlined"
        disabled={!downloadable}
        onClick={() => setDownloadPrompted(i.id)}
        startIcon={<DownloadIcon />}
      >
        {t("actions.download")}
      </Button>
    );
    if (downloadable) {
      var tooltipDownloadModalText = !creditable
        ? (tooltipDownloadModalText = t("actions.downloadcreditnote"))
        : (tooltipDownloadModalText = t("tooltips.title.downloadmodal"));
      headerActions.push(
        <Tooltip title={tooltipDownloadModalText}>{downloadModal}</Tooltip>
      );
    } else {
      headerActions.push(downloadModal);
    }

    // Pay button
    const payable = canInvoiceBePaid();
    const payButton = (
      <Button
        variant="outlined"
        disabled={!payable}
        onClick={() => setPaidPrompted(i.id)}
        startIcon={<PaidIcon />}
      >
        {t("actions.pay")}
      </Button>
    );
    if (payable) {
      headerActions.push(
        <Tooltip title={t("tooltips.title.pay", { type: typeInvoiceString })}>
          {payButton}
        </Tooltip>
      );
    } else {
      headerActions.push(payButton);
    }

    // Make Credit note button

    const creditButton = (
      <Button
        variant="outlined"
        disabled={!creditable}
        onClick={() => setCreditNotePrompted(i.id)}
        startIcon={<ReceiptIcon />}
      >
        {t("actions.makecreditnote")}
      </Button>
    );
    if (creditable) {
      headerActions.push(
        <Tooltip
          title={t("tooltips.title.makecreditnote", {
            type: typeInvoiceString,
          })}
        >
          {creditButton}
        </Tooltip>
      );
    } else {
      headerActions.push(creditButton);
    }

    // Delete button
    const deletable = canInvoiceBeDeleted();
    const deleteButton = (
      <Button
        variant="outlined"
        disabled={!deletable}
        onClick={() => setDeletePrompted(i.id)}
        startIcon={<DeleteIcon />}
      >
        {t("actions.delete")}
      </Button>
    );
    if (deletable) {
      headerActions.push(
        <Tooltip
          title={t("tooltips.title.delete", { type: typeInvoiceString })}
        >
          {deleteButton}
        </Tooltip>
      );
    } else {
      headerActions.push(deleteButton);
    }

    return headerActions;
  };

  if (isLoadingPage) {
    return (
      <PageLoading
        header={<Header title={typeInvoiceString} />}
        isLoading={isLoadingPage}
        errorLoading={errorLoadingPage}
      />
    );
  }

  return (
    <>
      {invoice && (
        <>
          <Header
            title={typeInvoiceString}
            items={invoice && headerItems(invoice)}
          />
          {/* Invoice can be exported section */}
          {canInvoiceBeExported() === true && (
            <SimpleAlert
              title={t("detail.alert.export.canbeexported", {
                type: typeInvoiceString,
              })}
              description={t("detail.alert.export.exportbypressing", {
                type: typeInvoiceString,
              })}
              severity="warning"
            />
          )}
          {/* Invoice can be downloaded section */}
          {canInvoiceBeDownloaded() === true && (
            <SimpleAlert
              title={t("detail.alert.download.canbedownloaded", {
                type: typeInvoiceString,
              })}
              description={t("detail.alert.download.downloadbypressing", {
                type: typeInvoiceString,
              })}
              severity="info"
            />
          )}
          {/* Normal Invoice*/}
          {invoice.isCreditNote === false && (
            <HorizontalTabs
              tabs={tabs}
              tabPanels={[
                <TabPanel
                  index={0}
                  key={0}
                  selectedTab={selectedTab}
                  content={<InvoiceOverviewDetail invoice={invoice} />}
                />,
                <TabPanel
                  index={1}
                  key={1}
                  selectedTab={selectedTab}
                  content={
                    <InvoiceOverviewLines
                      invoiceId={invoiceId}
                      invoicePatientId={invoice.customer.userId}
                      canInvoiceLinesBeDeleted={canInvoiceLinesBeDeleted}
                      canInvoiceBeEdited={canInvoiceBeEdited}
                    />
                  }
                />,
              ]}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          )}
          {/* Credit note */}
          {invoice.isCreditNote === true && (
            <CreditNoteOverviewDetail invoice={invoice} />
          )}
          {exportPrompted && (
            <InvoiceExportModal
              open={!!exportPrompted}
              close={closeInvoiceExportModal}
              handleSucces={updateExportNote}
              setFailed={setFailed}
            />
          )}

          {downloadPrompted && (
            <DownloadModal
              open={!!downloadPrompted}
              close={closeDownloadModal}
              setFailed={setFailed}
              invoiceId={invoiceId}
              patientId={invoice.customer.userId}
              thirdPayerSystem={invoice.thirdPayerSystem}
              hasRefundableCosts={hasRefundableCosts}
              isCreditNote={invoice.isCreditNote}
            />
          )}

          {creditNotePrompted && (
            <CreateCreditnoteModal
              open={!!creditNotePrompted}
              close={closeCreateCreditNoteModal}
              invoiceId={invoice?.id}
              therapistId={id}
              handleSucces={handleSuccesCreateCreditNote}
              handleFailed={closeCreateCreditNoteModalHandleFailed}
              setFailed={setFailed}
            />
          )}
          <ConfirmationModal
            open={!!deletePrompted}
            handleClose={() => setDeletePrompted(false)}
            command={() => deleteInvoice(deletePrompted)}
            content={t("modal.delete.content")}
            title={t("modal.delete.title")}
            positivetext={t("modal.actions.yes")}
            negativetext={t("modal.actions.no")}
          />
          <ConfirmationModal
            open={!!paidPrompted}
            handleClose={() => setPaidPrompted(false)}
            command={() => updateInvoiceToPaid(paidPrompted)}
            content={t("modal.edit.paid.content")}
            title={t("modal.edit.paid.title")}
            positivetext={t("modal.delete.yes")}
            negativetext={t("modal.delete.no")}
          />
        </>
      )}
      <SuccesSnackbar
        open={succes}
        setOpen={setSucces}
        text={t("snackbars.succes.succes")}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
