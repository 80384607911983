/**
 * Get Patient by ID
 * @param {number} patientId
 * @returns {string} url
 */
export const urlGetPatientId = (patientId) => `v2/patients/${patientId}`;

/**
 * Get Patient by ID (only info)
 * @param {number} patientId
 * @returns {string} url
 */
export const urlGetPatientIdInfo = (patientId) =>
  `v2/patients/${patientId}/info`;

/**
 * Get Patient by ID (only info)
 * @param {number} patientId
 * @returns {string} url
 */
export const urlGetPatientHealthInsuranceFund = (patientId) =>
  `v2/patients/${patientId}/healthInsuranceFund`;
