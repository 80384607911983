import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { FormTextfield } from "../../../shared/forms/form-elements/FormTextfield";
import { FormDropdownStandardValue } from './../../../shared/forms/form-elements/FormDropDownStandardValue';

export default function ExportCollectionStateFormStep({
  control,
  errors,
  healthInsuranceFundsList,
  value,
  setValue,
}) {
  const [t] = useTranslation("invoices");

  return (
    <>
      <form style={styles.form}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="column" spacing={0}>
              <Grid item>
                <legend>
                  {t("wizard.add.steps.addexportcollection.title")}
                </legend>
                <FormTextfield
                  label={t("exportcollectionstate.prescriptionnumber")}
                  name="prescriptionNumber"
                  control={control}
                  errors={errors.prescriptionNumber}
                />
              </Grid>

              <Grid item>
                <FormDropdownStandardValue
                  label={t("exportcollectionstate.healthinsurancefund")}
                  name="healthInsuranceFund"
                  value={value}
                  setValue={setValue}
                  control={control}
                  errors={errors.healthInsuranceFund}
                  options={healthInsuranceFundsList || []}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

const styles = {
  form: {
    margin: "unset",
    width: "100%",
  },
  fieldset: {
    borderWidth: 0,
  },
  wrapperButtons: {
    display: "flex",
    justifyContent: "end",
  },
};
