import { Text, View } from "@react-pdf/renderer";
import { mapValutaType } from "../../../../helpers/enumMappers/index";
import { roundValutaNumber } from "./../../../../helpers/numbers/roundValutaNumber";

/**
 * Total cost section for the Invoice PDF
 * @param {{ styles: {}, invoiceAmount: {}}} props
 * @returns {View} Total cost
 */
export default function InvoicePdfTotalCostSection({
  styles,
  invoiceAmount,
  valutaType,
  invoicePdfTotalCostSectionText,
  isCreditNote
}) {
  return (
    <View style={[styles.row, styles.rowTotalCost]}>
      <View style={styles.totalCostSection}>
        {invoicePdfTotalCostSectionText.map((item) => {
          return (
            <View key={item.id}>
              <Text style={styles.textText}>{item.text}</Text>
            </View>
          );
        })}
      </View>
      <View style={styles.totalCostItems}>
        <Text style={styles.textText}>
          {isCreditNote && "- " }
          {mapValutaType(valutaType)}
          {roundValutaNumber(Number(invoiceAmount.amountBeforePayBack))}
        </Text>
        <Text style={styles.textText}>
          {isCreditNote && "- " }
          {mapValutaType(valutaType)}
          {roundValutaNumber(Number(invoiceAmount.totalPayedBack))}
        </Text>
        <Text style={styles.textText}>
          {isCreditNote && "- " }
          {mapValutaType(valutaType)}
          {roundValutaNumber(Number(invoiceAmount.totalAmount))}
        </Text>
      </View>
    </View>
  );
}
