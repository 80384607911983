export const getRange = (viewName, currentDate) => {
  let fromDate = currentDate;
  let toDate = currentDate;

  function getCurrentDayOfTheWeek(d, dayOfTheWeek) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : dayOfTheWeek); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  if (viewName === "Day")
    return {
      from: new Date(fromDate.setDate(currentDate.getDate() - 1)).toISOString(),
      to: new Date(toDate.setDate(currentDate.getDate() + 1)).toISOString(),
    };
  if (viewName === "Week") {
    var fromWeek = getCurrentDayOfTheWeek(fromDate, 1);
    var toWeek = getCurrentDayOfTheWeek(fromDate, 7);

    fromWeek.setHours(0, 0, 0, 0);
    toWeek.setHours(24, 59, 59, 999);
    return {
      from: fromWeek.toISOString(),
      to: toWeek.toISOString(),
    };
  }

  if (viewName === "Month")
    return {
      from: new Date(
        new Date().setMonth(currentDate.getMonth() - 1)
      ).toISOString(),
      to: new Date(
        new Date().setMonth(
          currentDate.getMonth() + 1,
          currentDate.getDate() + 14
        )
      ).toISOString(),
    };
};
