import { Tab, Tooltip, Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import FilteredConsultationSetList from "../consultationsets/consultationSetList/FilteredConsultationSetList";
import Header from "../shared/default/Header";
import HorizontalTabs from "../shared/tabs/HorizontalTabs";
import TabPanel from "../shared/tabs/TabPanel";
import FilteredConsultationList from "./consultationList/FilteredConsultationList";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

/**
 * A consultation overview adds filtering and paging functionality the the overview of consultations. It also dictates whether it is shown as a list or a collection of sets
 * @returns
 */
function ConsultationOverview() {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = useTranslation("consultations");
  const [showFilters, setShowFilters] = useState(false);
  const history = useHistory();

  const tabs = [
    <Tab label={t("consultations")} key={0} />,
    <Tab label={t("consultationSets")} key={1} />,
  ];

  const tabPanels = [
    <TabPanel
      selectedTab={selectedTab}
      index={0}
      key={0}
      content={
        <FilteredConsultationList
          showFilters={showFilters}
          stylesFilters={stylesFilters}
        />
      }
    />,
    <TabPanel
      selectedTab={selectedTab}
      index={1}
      key={1}
      content={
        <FilteredConsultationSetList
          showFilters={showFilters}
          stylesFilters={stylesFilters}
        />
      }
    />,
  ];

  return (
    <>
      <Header
        title={t("files")}
        items={[
          selectedTab === 1 && (
            <Tooltip title={t("titles.new.consultationSet")}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => history.push(`/consultationset/new`)}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t("titles.new.consultationSet")}
              </Button>
            </Tooltip>
          ),
          selectedTab === 0 && (
            <Tooltip title={t("titles.new.consultation")}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => history.push(`/consultations/new`)}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t("titles.new.consultation")}
              </Button>
            </Tooltip>
          ),
          showFilters === true ? (
            <Tooltip title={t("filters.hide")}>
              <Button variant="outlined" onClick={() => setShowFilters(false)}>
                {t("filters.hide")}
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t("filters.show")}>
              <Button variant="outlined" onClick={() => setShowFilters(true)}>
                {t("filters.show")}
              </Button>
            </Tooltip>
          ),
        ]}
      />
      <HorizontalTabs
        tabs={tabs}
        tabPanels={tabPanels}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </>
  );
}

const stylesFilters = {
  stack: {
    marginBottom: "1em",
  },
  booleanFilterObject: {
    minWidth: "220px",
  },
  containerActions: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  buttonApply: {
    color: "green",
    borderColor: "green",
  },
  buttonReset: {
    marginRight: "0.5em",
    color: "red",
    borderColor: "red",
  },
};

export default ConsultationOverview;
