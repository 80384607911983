import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@mui/material";

/**
 * A modal with a form as content
 * @param {Dialog} Dialog
 */
export default function FormModal({
  open,
  title,
  description,
  content,
  handleSubmit,
  handleClose,
  submitButtonContent,
  closeButtonContent,
}) {
  const [t] = useTranslation("common");
  return (
    <Dialog open={open} keepMounted onClose={handleClose}>
      <form style={{ margin: "unset" }} onSubmit={handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {description}
          </DialogContentText>
          {content}
        </DialogContent>
        <DialogActions>
          <Button type="submit">
            {submitButtonContent
              ? submitButtonContent
              : t("modal.confirmation.submit")}
          </Button>
          <Button onClick={handleClose}>
            {closeButtonContent
              ? closeButtonContent
              : t("modal.confirmation.cancel")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
