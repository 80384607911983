export const eventConstants = {
  NAVIGATION: {
    FILES: "NAVIGATION_FILES",
    INVOICES: "NAVIGATION_INVOICES",
    SCHEDULER: "NAVIGATION_SCHEDULER"
  }
}

export const pageViewConstants = {
  FILES: "FILES",
  INVOICES: "INVOICES",
  SCHEDULER: "SCHEDULER",
};
