/**
 * Get consultation by ID
 * @param {number} consultationId
 * @returns {string} url
 */
export const urlGetConsultationById = (consultationId) =>
  `v2/consultations/${consultationId}`;

/**
 * Get all consultations by patient id where there are still costs that need to be invoiced
 * @param {number} patientId
 * @returns {string} url
 */
export const urlGetConsultationsPatientIdTherapistIdUninvoicedCosts = (
  patientId,
  therapistId
) =>
  `v2/consultations/overviewConsultations?PatientId=${patientId}&TherapistId=${therapistId}&HasUninvoicedCosts=true`;

/**
 * Get notes by consultation id
 * @param {number} consultationId
 * @returns {string} url
 */
export const urlGetConsultationByIdNotes = (consultationId) =>
  `v2/consultations/${consultationId}/notes`;

/**
 * Get costs by consultation id
 * @param {number} consultationId
 * @returns {string} url
 */
export const urlGetConsultationByIdCosts = (consultationId) =>
  `v2/consultations/${consultationId}/costs`;

/**
 * Get overview of consultations
 * @returns {string} url
 */
export const urlGetConsultations = () =>
  `v2/consultations/OverviewConsultations`;
