/**
 * Get Invoice by ID
 * @param {number} invoiceId
 * @returns {string} url
 */
export const urlGetInvoiceById = (invoiceId) => `v2/invoices/${invoiceId}`;

/**
 * Get invoice export
 * @param {number} invoiceId
 * @returns {string} url
 */
export const urlGetInvoiceExport = (invoiceId) =>
  `v2/invoices/${invoiceId}/export`;

/**
 * Get Latest Invoice Number based on therapistId
 * @param {number} therapistId
 * @returns {string} url
 */
export const urlGetLatestTherapistInvoiceNumber = (therapistId) =>
  `v2/invoices/${therapistId}/invoiceNumber`;

/**
 * Check if the invoiceNumber is valid to use with this therapist
 * (Gives back an boolean if it is valid)
 * @param {number} therapistId
 * @param {number} invoiceNumber
 * @returns {string} url
 */
export const urlGetCheckValidInvoiceNumberForTherapist = (
  therapistId,
  invoiceNumber
) =>
  `v2/invoices/${therapistId}/invoiceNumber/valid?invoiceNumber=${invoiceNumber}`;

/**
 * Get invoices by Therapist ID
 * @param {number} therapistId
 * @returns {string} url
 */
export const urlGetInvoicesByTherapistId = (therapistId) =>
  `v2/invoices/${therapistId}/invoices`;

/**
 * Get invoices lines from invoice by ID
 * @param {number} invoiceId
 * @returns {string} url
 */
export const urlGetInvoiceByIdInvoiceLines = (invoiceId) =>
  `v2/invoices/${invoiceId}/invoiceLines`;
