import { Typography } from "@mui/material";

export function Copyright(props) {
  return (
    <Typography variant="body2" color="textSecondary" align="center" {...props}>
      {"Copyright © "}
      TelePHON.digital {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
