import { compensationTypes } from "../../constants/cost-constants";

/**
 * Maps compensation Enum from backend to text
 * @param {number} value
 * @returns {string} compensationType
 */


function MapCompensationType(value) {

  var type = compensationTypes.find((x) => x.value === value)?.label;
  return type
}

export default MapCompensationType;
