import useAuth from "../auth/useAuth";
import InvoicesOverviewTherapist from "../components/invoices/invoicesOverview/InvoicesOverviewTherapist";
import { Page } from "../components/shared/default/Page";
import { pageViewConstants } from "../constants/insight-constants";
import { useAppInsightsContext } from "../insights/AppInsightsContext";
import { trackPageView } from "../insights/AppInsightsTrack";
import { isAppInsigthsEnabled } from "./../insights/isAppInsightsEnabled";

/**
 * Invoices Screen
 * @returns {JSX.Element}
 */
export default function InvoicesTherapistScreen() {
  const { id } = useAuth();

  const isAiEnabled = isAppInsigthsEnabled();
  const appInsights = useAppInsightsContext();
  const trackProps = {
    user: { id: id },
  };

  // prettier-ignore
  trackPageView(isAiEnabled, appInsights, pageViewConstants.INVOICES, trackProps);

  return <Page content={<InvoicesOverviewTherapist />} />;
}
