import { Text, View } from "@react-pdf/renderer";

/**
 * Users section for the Invoice PDF
 * @param {{ styles: {}, users: []}} props
 * @returns {View} Users
 */
export default function CollectionStatePdfPatientSection({
  styles,
  details,
  patientSectionText,
}) {
  return (
    <View style={[styles.row, styles.rowInvoiceDetail]}>
      {details.map((item, index) => {
        return (
          <View key={item.id}>
            {patientSectionText[index] && (
              <Text style={{ ...styles.textText, ...styles.textBold }}>
                {patientSectionText[index]["value"]}
              </Text>
            )}
            <Text style={styles.textText}>{item.value}</Text>
          </View>
        );
      })}
    </View>
  );
}
