import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { axiosInstance, handleAxiosError } from "../../../api/axios";
import { urlGetTherapistPatients } from "../../../api/urls/therapist";
import useAuth from "../../../auth/useAuth";
import Header from "../../shared/default/Header";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import PageLoading from "./../../shared/loading/PageLoading";
import SelectPatientList from "./SelectPatientList";

/**
 * Select patient component with afterwards a route
 * @param {{ header: { title: string, items: []}, route: (id: number) => {}}} props
 * @returns {JSX.Element}
 */
export default function SelectPatientWithRoute({
  header: { title, items },
  route,
}) {
  const [t] = useTranslation("patients");
  const history = useHistory();

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();

  const [failed, setFailed] = useState();

  const [patients, setPatients] = useState();

  // Pagination
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);

  const { id } = useAuth();

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    /**
     * Get all patients for therapist
     */
    let url = urlGetTherapistPatients(id);
    url += `?PageNumber=${page + 1}`;
    url += `&PageSize=${pageSize}`;
    axiosInstance
      .get(url, {
        setsRequestCancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const pagedResponse = response.data;
        setPatients(pagedResponse.data.patientDtos);
        setPage(pagedResponse.pageNumber - 1);
        setPageSize(pagedResponse.pageSize);
        setRowCount(pagedResponse.totalRecords);
        setIsLoadingPage(false);
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: "error.request.get.therapistpatients",
          axiosError: handleAxiosError(error),
        })
      );

    return () => {
      cancelTokenSource.cancel();
    };
  }, [page, pageSize, t, id]);

  /**
   * This function will execute the route to the right url.
   * It gets the {@link route} from the parent component.
   * If undefined it will display an error.
   * @see {@link route} route
   * @param {number} patientId
   */
  const executeRoute = (patientId) => {
    if (route === undefined) setFailed("snackbars.failure.patientroute");
    else {
      const completeUrl = route(patientId);
      history.push(completeUrl);
    }
  };

  if (isLoadingPage) {
    return (
      <PageLoading isLoading={isLoadingPage} errorLoading={errorLoadingPage} />
    );
  }

  return (
    <>
      <Header title={title && title} items={items && items} />
      {patients && (
        <SelectPatientList
          patients={patients}
          action={executeRoute}
          rowCount={rowCount}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
