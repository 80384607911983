import InvoiceExportOverview from "../components/invoices/invoiceExport/InvoiceExportOverview";

/**
 * Invoice Export Screen
 * @returns {JSX.Element}
 */
function InvoiceExportScreen() {
  return <InvoiceExportOverview />;
}
export default InvoiceExportScreen;
