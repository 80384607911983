import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router";
import axios from "axios";
import { axiosInstance, handleAxiosError } from "../../api/axios";
import PageLoading from "./../shared/loading/PageLoading";
import { Page } from "./../shared/default/Page";
import Header from "./../shared/default/Header";
import { Button } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import PageviewIcon from "@mui/icons-material/Pageview";
import FailureSnackbar from "../shared/menus/snackbars/FailureSnackbar";
import AddConsultationWizard from "../consultations/AddConsultationWizard";
import SimpleAlert from "./../shared/alerts/SimpleAlert";
import { urlGetAgendaitemById } from "../../api/urls/agendaitem";

/**
 * This will choose the right type of wizard
 * @returns {JSX.Element}
 */
export default function CreateAgendaitemType() {
  const [t] = useTranslation("agendaitem");
  const { agendaitemId } = useParams();
  const history = useHistory();

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();

  const [failed, setFailed] = useState();

  const [agendaItemType, setAgendaItemType] = useState();
  const [agendaItemExternalObjectId, setAgendaItemExternalObjectId] =
    useState();

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    /*
     * Get agendaitem by ID
     */
    const url = urlGetAgendaitemById(agendaitemId);
    axiosInstance
      .get(url)
      .then((response) => {
        const agendaItem = response.data;
        setAgendaItemType(agendaItem.agendaType);
        setAgendaItemExternalObjectId(agendaItem.externalObjectId);
        setIsLoadingPage(false);
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: "error.request.get.agendaitem",
          axiosError: handleAxiosError(error),
        })
      );

    return () => {
      cancelTokenSource.cancel();
    };
  }, [agendaitemId, t]);

  /**
   * Gives back the right URl for going to the externalObjectId.
   * @param {number} type
   * @param {number} externalObjectId
   * @returns {string | null} url
   */
  const getUrlExternalObjectId = (type, externalObjectId) => {
    let url = null;
    const urlConstultation = (consultationId) =>
      `/consultations/${consultationId}/detail`;
    switch (type) {
      case 1:
        // Consultation
        url = urlConstultation(externalObjectId);
        break;
      case 2:
        // Video consultation
        url = urlConstultation(externalObjectId);
        break;
      default:
        break;
    }
    return url;
  };

  /**
   * Route to external object
   */
  const routeToExternalObject = () => {
    const url = getUrlExternalObjectId(
      agendaItemType,
      agendaItemExternalObjectId
    );
    if (url !== null) history.push(url);
    else {
      const tType = t(`type.${agendaItemType}`);
      setFailed(
        t("snackbars.failure.routetoexternalobject", {
          agendaItemType: tType,
        })
      );
    }
  };

  /**
   * Gives back info to the user that there is no wizard available for this type.
   * This function can be removed once every type has been implemented.
   * @param {number} type
   * @returns {SimpleAlert}
   */
  const alertNoWizardForThisType = (type) => {
    return (
      <SimpleAlert
        title={t("alerts.noWizardForAgendaitemType.title", {
          agendaItemType: t(`type.${agendaItemType}`),
        })}
        severity={"warning"}
      />
    );
  };

  /**
   * Gives back the wizard based on the type of the agendaitem
   * @param {number} type
   */
  const wizardForType = (type = -1) => {
    switch (type) {
      case 1:
        return <AddConsultationWizard />;
      case 2:
        return <AddConsultationWizard />;
      default:
        return alertNoWizardForThisType(type);
    }
  };

  if (isLoadingPage) {
    return (
      <PageLoading
        header={<Header title={t("wizards.title")} />}
        isLoading={isLoadingPage}
        errorLoading={errorLoadingPage}
      />
    );
  }

  // When externalObjectId already exists it will show an alert with notifying the user
  // there is already an object linked to the agendaitem.
  if (
    agendaItemType &&
    agendaItemExternalObjectId &&
    agendaItemExternalObjectId !== null
  ) {
    return (
      <Page
        content={
          <>
            <Header
              title={t("wizards.title")}
              items={[
                <Button
                  variant="outlined"
                  onClick={() => history.goBack()}
                  startIcon={<ArrowBack />}
                >
                  {t("actions.back")}
                </Button>,
              ]}
            />
            <SimpleAlert
              title={t("alerts.externalObjectIdIsNotNull.title")}
              description={t("alerts.externalObjectIdIsNotNull.description", {
                agendaItemType: t(`type.${agendaItemType}`),
              })}
              action={
                <Button
                  variant="outlined"
                  onClick={routeToExternalObject}
                  startIcon={<PageviewIcon />}
                >
                  {t("actions.view")}
                </Button>
              }
            />
          </>
        }
      />
    );
  }

  return (
    <>
      {agendaItemType && wizardForType(agendaItemType)}
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
