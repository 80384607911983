import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import axios from "axios";
import { axiosInstance } from "../../../api/axios";
import { urlGetConsultationSetsByPatientId } from "./../../../api/urls/consultationset";

/**
 * Consultation Details Step
 * @returns {JSX.Element}
 */
export default function ConsultationDetailsStep({
  patientId,
  setFailed,
  description,
  setDescription,
  nomenclature,
  setNomenclature,
  type,
  setType,
  selectedConsultationSetId,
  setSelectedConsultationSetId,
}) {
  const [t] = useTranslation("agendaitem");

  const [consultationSets, setConsultationSets] = useState();

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    /*
     * Get consultationsets by patientid
     */
    const url = urlGetConsultationSetsByPatientId(patientId);
    axiosInstance
      .get(url)
      .then((response) => {
        const consultationsets = response.data.consultationSets;
        setConsultationSets(consultationsets);
      })
      .catch((error) => {
        console.error(`GET ${url}`, error);
        setFailed(t("snackbars.failure.getconsultationsets"));
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [patientId, setFailed, t]);

  /**
   * Options for consultation types
   * @type {[{label: string, value: string, control: JSX.Element}]}
   */
  const optionsTypes = [
    {
      label: t("types.0"),
      value: "0",
    },
    {
      label: t("types.1"),
      value: "1",
    },
  ];

  return (
    <>
      <h3>{t("fields.consultation.description")}</h3>
      <TextField
        id="consultationdescription"
        //label={t("fields.consultation.description")}
        value={description ?? ""}
        onChange={(event) => setDescription(event.target.value)}
      />

      <h3>{t("fields.type")}</h3>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="type"
          name="radio-buttons-group"
          value={type}
          onChange={(event) => {
            const type = event.target.value;
            if (type === optionsTypes[0].value)
              setSelectedConsultationSetId("");
            setType(type);
          }}
        >
          {optionsTypes.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              label={option.label}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {type && type === "1" && (
        <>
          {consultationSets && (
            <FormControl fullWidth>
              <InputLabel id="input-consultationsetid-label">
                {t("fields.existingconsultationset")}
              </InputLabel>
              <Select
                labelId="input-consultationsetid-label"
                id="input-consultationsetid"
                label={t("fields.existingconsultationset")}
                value={selectedConsultationSetId ?? ""}
                onChange={(event) =>
                  setSelectedConsultationSetId(event.target.value)
                }
              >
                {consultationSets.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.title} ({c.amountOfConsultations})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}
      <h3>{t("fields.consultation.nomenclature")}</h3>
      <TextField
        id="consultationnomenclature"
        //label={t("fields.consultation.nomenclature")}
        value={nomenclature ?? null}
        onChange={(event) => setNomenclature(event.target.value)}
        type="number"
        inputProps={{ inputMode: "numeric", pattern: "^[0-9]{6}$" }}
      />
    </>
  );
}
