import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../auth/useAuth.jsx";
import { axiosInstance } from "./../../api/axios";
import FailureSnackbar from "./../shared/menus/snackbars/FailureSnackbar";
import SuccesSnackbar from "./../shared/menus/snackbars/SuccesSnackbar";
import DefaultNoteSetting from "./DefaultNoteSetting";

export default function DefaultNoteSettings() {
  //State
  const [defaultNote, setDefaultNote] = useState();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("settings");
  const { id } = useAuth();

  const [failed, setFailed] = useState();
  const [succes, setSucces] = useState();

  // useEffect to receive user settings
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/v2/therapists/${id}`)
      .then((res) => {
        setDefaultNote(res.data.defaultNote);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
      });
  }, [id, setLoading, t]);

  const onSubmit = () => {
    axiosInstance
      .put(`v2/therapists/${id}/update/note`, {
        defaultNote: defaultNote,
      })
      .then((response) => {
        setSucces(true);
      })
      .catch(function (error) {
        // handle error
        setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
      });
  };

  return (
    <>
      {!!loading ? (
        <CircularProgress />
      ) : (
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5" gutterBottom component="div">
            {t("notes.title")}
          </Typography>
          <Stack
            sx={{ width: "90%", maxWidth: "900px", pt: 5 }}
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
            spacing={1}
          >
            <DefaultNoteSetting
              value={defaultNote}
              setValue={setDefaultNote}
              name={t("notes.name")}
              description={t("notes.number_desc")}
              type="text"
            />
          </Stack>
          <Box
            sx={{
              width: "85%",
              display: "flex",
              padding: 5,
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <Button variant="outlined" onClick={onSubmit}>
              {t("submit")}
            </Button>
          </Box>
          <SuccesSnackbar
            open={!!succes}
            setOpen={setSucces}
            text={t("snackbars.succes")}
          />
          <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
        </Container>
      )}
    </>
  );
}
