import { Button, MenuItem, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../../../api/axios";
import { urlGetTherapistId } from "../../../api/urls/therapist";
import useAuth from "../../../auth/useAuth";
import { locations } from "../../../constants/consultation-constants";
import MyDateRangePicker from "../../shared/controlled-inputs/DateRangePicker";
import UserPickerByName from "../../shared/controlled-inputs/userPickerByName";
import { initialstateFilters } from "../../../constants/initialstates-constants";

function ConsultationFilterBar({
  styles,
  setLocationFilter,
  setDateFilter,
  setPatientFilter,
  setTherapistFilter,
}) {
  const { t } = useTranslation("consultations");

  const [location, setLocation] = useState(initialstateFilters.number);
  const [startTime, setStartTime] = useState(initialstateFilters.dateRange);
  const [patient, setPatient] = useState(initialstateFilters.userPicker);
  const [therapist, setTherapist] = useState(initialstateFilters.userPicker);

  const { id } = useAuth();

  useEffect(() => {
    let isMounted = true;
    axiosInstance.get(urlGetTherapistId(id)).then((response) => {
      let therapist = response.data.userProfile;
      if (isMounted)
        setTherapist({
          userId: therapist.id,
          id: therapist.id,
          label: `${therapist.firstName} ${therapist.lastName}`,
        });
    });
    return () => {
      isMounted = false;
    };
  }, [id]);

  const applyFilters = () => {
    setLocationFilter(location);
    setDateFilter(startTime);
    setPatientFilter(patient?.userId);
    setTherapistFilter(id);
  };

  const resetFilters = () => {
    setLocation(initialstateFilters.number);
    setLocationFilter(initialstateFilters.number);
    setStartTime(initialstateFilters.dateRange);
    setDateFilter(initialstateFilters.dateRange);
    setPatient(initialstateFilters.userPicker);
    setPatientFilter(initialstateFilters.userPicker);
    setTherapistFilter(id); // Therapist needs to stay the same no matter what
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <MyDateRangePicker
          value={startTime}
          setValue={setStartTime}
          startText={t("filters.labels.daterange")}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <UserPickerByName
          user={patient}
          setUser={setPatient}
          label={t("filters.labels.patient")}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          style={{ minWidth: "220px" }}
          label={t("filters.labels.location")}
          select
          variant="outlined"
          onChange={(event) => setLocation(event.target.value)}
          value={location ?? ""}
        >
          {locations.map((option) => {
            return (
              <MenuItem key={option.id} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>
      <Stack direction="row" spacing={2} style={styles.stack}>
        <div style={styles.containerActions}>
          <Button
            variant="outlined"
            color="secondary"
            sx={styles.buttonReset}
            onClick={resetFilters}
          >
            {t("filters.reset")}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={styles.buttonApply}
            onClick={applyFilters}
          >
            {t("filters.apply")}
          </Button>
        </div>
      </Stack>
    </>
  );
}
export default ConsultationFilterBar;
