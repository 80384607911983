/**
 * Get cost by ID
 * @param {number} costId
 * @returns {string} url
 */
export const urlGetCostById = (costId) => `v2/costs/${costId}`;

/**
 * Get all individual costs based on patient and therapist
 * @param {number} patientId
 * @param {number} therapistId
 * @returns {string} url
 */
export const urlGetIndividualCostsPatientIdTherapistId = (
  patientId,
  therapistId
) =>
  `v2/costs/individualCosts?PatientId=${patientId}&TherapistId=${therapistId}`;
