import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { axiosInstance } from "../../api/axios";
import BasicButtonBar from "../shared/button-bar/basicButtonBar";
import DeleteButton from "../shared/iconButtons/DeleteButton";
import EditButton from "../shared/iconButtons/EditButton";
import Tooltip from "@mui/material/Tooltip";

function ConsultationButtonBar({ id, setFailed, deletable }) {
  const { t } = useTranslation("consultations");
  const history = useHistory();

  const toolTipText = deletable
    ? t("tooltips.delete")
    : t("tooltips.unabletodelete");

  const mainItems = (
    <Stack direction="row" spacing={1}>
      <EditButton
        tooltip={t("tooltips.edit")}
        onClick={() => history.push(`/consultations/${id}/edit`)}
      />

        <span>
          <DeleteButton
            disabled={!deletable}
            tooltip={toolTipText}
            onClick={() => {
              axiosInstance
                .delete(`v2/consultations/${id}`)
                .then(history.goBack())
                .catch((error) => {
                  setFailed(`${t("snackbars.failure")} \n ${error.message}`);
                });
            }}
          />
        </span>
    </Stack>
  );
  return <BasicButtonBar mainItems={mainItems} />;
}
export default ConsultationButtonBar;
