import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";
import { axiosInstance } from "../../../api/axios";
import Wizard from "../../shared/wizards/Wizard";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import AddConsultationDetails from "./AddConsulationDetails";
import AddCosts from "./AddCosts";
import AddVerificationAndNomenclature from "./AddVerificationAndNomenClature";
import EditConsultationOverview from "./EditConsultationOverview";
import useAuth from "../../../auth/useAuth";
import { urlPostConsultationToConsultationSet } from "../../../api/urls/consultationset.js";

function EditConsultationWizard() {
  const { consultationId } = useParams();
  const history = useHistory();
  const [failed, setFailed] = useState();
  const [selectedSet, setSelectedSet] = useState();
  const [sets, setSets] = useState();
  const [deletedCosts, setDeletedCosts] = useState([]);
  const { id } = useAuth();
  const [addAppointment, setAddAppointment] = useState(false);
  const [patientId, setPatientId] = useState();

  const [loadingConsultation, setLoadingConsultation] = useState(false);

  const { t } = useTranslation("consultations");
  const moment = require("moment");
  const schema = Yup.object({
    description: Yup.string().nullable(),
    patient: Yup.object({
      userId: Yup.number().required("error.required"),
      label: Yup.string().required("error.required"),
      firstName: Yup.string(),
      lastName: Yup.string(),
      userType: Yup.number(),
    }).default(null),
    location: Yup.number().required("error.required"),
    set: Yup.number().nullable(),
    note: Yup.string(),
    nomenclature: Yup.string()
      .required()
      .matches(/^[0-9]+$/, t("yup.onlydigits")) //todo translate
      .min(6, t("yup.exactsix")) //todo translate
      .max(6, t("yup.exactsix")), //todo translate
    date: Yup.date().required("error.required").default(new Date()),
    starttime: Yup.date().required("error.required").default(new Date()),
    endtime: Yup.date()
      .default(null)
      .required("error.required")
      .test("is-greater", "error.endBeforeStart", function (value) {
        const { starttime } = this.parent;
        return moment(value, "HH:mm").isAfter(moment(starttime, "HH:mm"));
      }),
    costs: Yup.array().of(
      Yup.object()
        .shape({
          title: Yup.string().required("error.required"),
          id: Yup.string(),
          costType: Yup.number().min(0, "error.required").required(),
          paybackType: Yup.number()
            .transform((o, v) => Number(v.toString().replace(/,/g, ".")))
            .required("error.required")
            .when("costType", {
              is: 0,
              then: Yup.number().min(0, "error.required").required(),
            }),
          paybackAmount: Yup.string()
            .matches(/^[0-9]*\.?[0-9]*$/, t("yup.amounterror")) //todo translate
            .required("error.required")
            .when("costType", {
              is: 101,
              then: Yup.number()
                .min(0.01, "error.required")
                .required("error.required"),
            }),
          amount: Yup.string()
            .matches(/^[0-9]*\.?[0-9]*$/, t("yup.amounterror")) //todo translate
            .min(0.01, "error.required")
            .required(),
        })
        .required()
    ),
  }).required();

  const {
    control,
    reset,
    formState: { errors },
    trigger,
    handleSubmit,
    setValue,
    getValues,
    watch,
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "costs",
  });

  const setId = useWatch({
    control,
    name: "set",
  });

  const patient = useWatch({
    control,
    name: "patient",
  });

  //Creating date to string to the right format
  const y = new Date();
  const date = ("0" + y.getDate()).slice(-2);
  const month = ("0" + (y.getMonth() + 1)).slice(-2);
  const year = y.getFullYear();
  const time = `${year}/${month}/${date}`;
  const addHoursToDate = (date, hours) => {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  };

  useEffect(() => {
    //Parsing the dateTime of type string to a dateTime object & setting it as a standard value
    setValue("date", new Date(Date.parse(time)));
    setValue("starttime", new Date());
    setValue("endtime", addHoursToDate(getValues("starttime"), 1));
  }, []);

  useEffect(() => {
    if (getValues("patient")) {
      setPatientId(getValues("patient").userId);
    }
  }, [getValues, getValues("patient")]);

  useEffect(() => {
    // Actions below are to prevent the fields from being updated when unmounted, this is as a result of warning
    // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
    // isMounted acts as a failsave, as the canceltoken should cancel requests sent to the backend
    if (consultationId !== undefined) {
      setLoadingConsultation(true);
      let isMounted = true;
      const cancelTokenSource = axios.CancelToken.source();

      const consultationUrl = `/v2/consultations/${consultationId}`;

      axiosInstance
        .get(consultationUrl, {
          consRequestCancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          // handle success
          if (isMounted) {
            let consultation = response.data;
            let patientId = consultation.patientId;
            let getNotesQuery = axiosInstance.get(
              `/v2/consultations/${consultationId}/notes`
            );
            let getPatientQuery = axiosInstance.get(
              `/v2/patients/${patientId}/info`
            );
            let getCostsQuery = axiosInstance.get(
              `/v2/consultations/${consultationId}/costs`
            );

            let getSetsQuery = (patientId) =>
              axiosInstance.get(
                `/v2/consultationSets/overviewConsultationSets?patientId=${patientId}`
              );

            axios
              .all([
                getNotesQuery,
                getPatientQuery,
                getCostsQuery,
                getSetsQuery(patientId),
              ])
              .then(
                axios.spread((notes, patient, costs, sets) => {
                  consultation = {
                    ...parseConsultationResponse(consultation),
                    ...parseNoteResponse(notes),
                    ...parsePatientResponse(patient, patientId),
                    ...parseCostResponse(costs),
                    deletedCosts: [],
                  };
                  setSets(parseSetsResponse(sets));

                  if (isMounted) {
                    reset(consultation, { keepValues: false });
                    setLoadingConsultation(false);
                  }
                })
              );
          }
        })
        .catch(function (error) {
          // handle error
          handleFailed(error, setFailed, t);
        });

      return () => {
        isMounted = false;
        cancelTokenSource.cancel();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultationId]);

  /* will get the consultation set*/
  useEffect(() => {
    // Actions below are to prevent the fields from being updated when unmounted, this is as a result of warning
    // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
    // isMounted acts as a failsave, as the canceltoken should cancel requests sent to the backend
    let isMounted = true;
    const cancelTokenSource = axios.CancelToken.source();

    if (setId !== null && setId !== undefined && setId > 0) {
      const setUrl = `/v2/consultationSets/${setId}/remainingSlots`;

      axiosInstance
        .get(setUrl, { setRequestCancelToken: cancelTokenSource.token })
        .then((response) => {
          // handle success
          if (isMounted) {
            let set = response.data;

            setSelectedSet(set);
            setValue(
              "nomenclature",
              response.data.consultationSetDto.nomenclature
            );
          }
        })
        .catch(function (error) {
          // handle error
          if (isMounted)
            setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
        });
    }

    return () => {
      isMounted = false;
      cancelTokenSource.cancel();
    };
  }, [setId, t, reset]);

  useEffect(() => {
    if (patient)
      axiosInstance
        .get(
          `/v2/consultationSets/overviewConsultationSets?patientId=${patient.userId}`
        )
        .then((response) => {
          setSets(parseSetsResponse(response));
        });
  }, [patient]);

  const onValid = (data) => {
    if (selectedSet && selectedSet?.remainingSlots <= 0) {
      onInvalid(null, t("snackbars.noconsultationsleft"));
    } else {
      if (consultationId !== undefined) {
        axiosInstance
          .put(
            `/v2/consultations/${consultationId}`,
            parseConsultation(patientId, id, consultationId, data)
          )
          .then(function (response) {
            if (data.costs || data.note) {
              let costRequests = [];
              if (data.costs) {
                data.costs.forEach((c) => {
                  if (c.costId) {
                    costRequests.push(
                      axiosInstance.put(
                        `/v2/costs/${c.costId}`,
                        parseCost(response.data.id, c)
                      )
                    );
                  } else
                    costRequests.push(
                      axiosInstance.post(
                        "/v2/costs",
                        parseCost(response.data.id, c)
                      )
                    );
                });
              }
              let noteRequest = undefined;
              if (data.note) {
                noteRequest = axiosInstance.post(
                  `/v2/consultations/${consultationId}/notes`,
                  JSON.stringify(data.note),
                  { headers: { "Content-Type": "application/json" } }
                );
              }

              let deleteCostRequests = [];
              deletedCosts.forEach((dc) => {
                deleteCostRequests.push(
                  axiosInstance.delete(`/v2/costs/${dc}`)
                );
              });

              axios
                .all([...costRequests, noteRequest, ...deleteCostRequests])
                .then(history.push("/files"))
                .catch(function (error) {
                  handleFailed(error, setFailed, t);
                });
            } else {
              history.push("/files");
            }
          })
          .catch(function (error) {
            handleFailed(error, setFailed, t);
          });
      } else {
        if (
          data.set !== 0 &&
          selectedSet !== null &&
          selectedSet?.remainingSlots > 0
        ) {
          const url = urlPostConsultationToConsultationSet(data.set);
          axiosInstance
            .post(url, parseConsultation(patientId, id, consultationId, data))
            .then(function (response) {
              if (data.costs || data.note || addAppointment) {
                let costRequests = [];
                let consultationId = response.data;
                if (data.costs) {
                  data.costs.forEach((c) =>
                    costRequests.push(
                      axiosInstance.post(
                        "/v2/costs",
                        parseCost(consultationId, c)
                      )
                    )
                  );
                }
                let noteRequest = undefined;
                if (data.note) {
                  noteRequest = axiosInstance.post(
                    `/v2/consultations/${consultationId}/notes`,
                    JSON.stringify(data.note),
                    { headers: { "Content-Type": "application/json" } }
                  );
                }
                let scheduleRequest = undefined;
                if (addAppointment) {
                  scheduleRequest = axiosInstance.post(
                    "/v2/agendaItems",
                    parseAppointment(patient.userId, id, response.data, data)
                  );
                }
                axios
                  .all([...costRequests, noteRequest, scheduleRequest])
                  .then(() => history.push("/files"))
                  .catch(function (error) {
                    setFailed(error.message);
                  });
              } else {
                history.push("/files");
              }
            })
            .catch(function (error) {
              setFailed(error.message);
            });
        } else {
          data.set = 0; //TODO: FIx this
          axiosInstance
            .post(
              "/v2/consultations",
              parseConsultation(patientId, id, consultationId, data)
            )
            .then(function (response) {
              if (data.costs || data.note || addAppointment) {
                let costRequests = [];
                let consultationId = response.data;
                if (data.costs) {
                  data.costs.forEach((c) =>
                    costRequests.push(
                      axiosInstance.post(
                        "/v2/costs",
                        parseCost(consultationId, c)
                      )
                    )
                  );
                }
                let noteRequest = undefined;
                if (data.note) {
                  noteRequest = axiosInstance.post(
                    `/v2/consultations/${consultationId}/notes`,
                    JSON.stringify(data.note),
                    { headers: { "Content-Type": "application/json" } }
                  );
                }
                let scheduleRequest = undefined;
                if (addAppointment) {
                  scheduleRequest = axiosInstance.post(
                    "/v2/agendaItems",
                    parseAppointment(patient.userId, id, response.data, data)
                  );
                }
                axios
                  .all([...costRequests, noteRequest, scheduleRequest])
                  .then(() => history.push("/files"))
                  .catch(function (error) {
                    setFailed(error.message);
                  });
              } else {
                history.push("/files");
              }
            })
            .catch(function (error) {
              setFailed(error.message);
            });
        }
      }
      // Send to backend
    }
  };
  const onInvalid = (errors, errorMsg) => {
    if (errorMsg) {
      alert(errorMsg);
    } else {
      alert(t("snackbars.incomplete"));
    }
  };

  const removeCost = (fieldId) => {
    if (fields[fieldId].costId)
      setDeletedCosts([...deletedCosts, fields[fieldId].costId]);

    remove(fieldId);
  };

  const removeConsultationSet = () => {
    setSelectedSet(null);
    setValue("nomenclature", "");
    setValue("set", -1);
    setValue("set", undefined);
    setValue("set", null);
  };

  const steps = [
    {
      id: 0,
      title: t("wizards.consultation.0.title"),
      content: (
        <AddConsultationDetails
          control={control}
          errors={errors}
          editing={!!consultationId}
          loading={loadingConsultation}
        />
      ),
      description: t("wizards.consultation.0.description"),
      onNext: () =>
        trigger([
          "patient",
          "location",
          "date",
          "starttime",
          "endtime",
          "notes",
        ]),
    },
    {
      id: 1,
      title: t("wizards.consultation.1.title"),
      content: (
        <AddCosts
          control={control}
          fields={fields}
          editing={!!consultationId}
          append={append}
          remove={(fieldid) => {
            removeCost(fieldid)
          }}
          watch={watch}
          errors={errors}
        />
      ),
      description: t("wizards.consultation.1.description"),
      onNext: () => trigger("costs"),
    },
    {
      id: 2,
      title: t("wizards.consultation.2.title"),
      content: (
        <AddVerificationAndNomenclature
          control={control}
          editing={!!consultationId}
          onSubmit={() => {
            if (selectedSet?.remainingSlots <= 0) {
              setFailed(t("messages.noconsultationleft"));
            }
          }}
          errors={errors}
          selectedSet={selectedSet}
          setId={setId}
          sets={sets}
          onClick={removeConsultationSet}
        />
      ),
      description: t("wizards.consultation.2.description"),
      onNext: () => trigger(),
    },
    {
      id: 3,
      title: t("wizards.consultation.3.title"),
      content: (
        <EditConsultationOverview
          control={control}
          errors={errors}
          onSubmit={() => handleSubmit(onValid, onInvalid)}
          loading={loadingConsultation}
          fields={fields}
          editing={!!consultationId}
          append={append}
          remove={remove}
          selectedSet={selectedSet}
          setId={setId}
          sets={sets}
          watch={watch}
          addAppointment={addAppointment}
          setAddAppointment={setAddAppointment}
        />
      ),
      description: t("wizards.consultation.3.description"),
      onNext: () => trigger(),
    },
  ];

  const parseCost = (consultationId, cost) => {
    return {
      patientId: patientId,
      id: cost.costId,
      consultationId: consultationId,
      title: cost.title,
      compensationType:
        cost.costType === 0
          ? cost.paybackType
          : cost.costType === 5
          ? 0
          : cost.costType,
      complementaryPayBackAmount: Number(Number(cost.paybackAmount).toFixed(2)),
      costAmount: Number(Number(cost.amount).toFixed(2)),
    };
  };

  return (
    <>
      <Wizard steps={steps} handleFinish={handleSubmit(onValid, onInvalid)} />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
export default EditConsultationWizard;

const handleFailed = (error, setFailed, t) => {
  setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
};

const parseConsultation = (patientId, therapistId, id, consultation) => {
  // Set start and endtime to hold the correct date
  consultation.starttime = new Date(consultation.starttime);
  consultation.endtime = new Date(consultation.endtime);
  consultation.date = new Date(consultation.date);
  consultation.starttime.setUTCDate(consultation.date.getDate());
  consultation.starttime.setUTCMonth(consultation.date.getMonth());
  consultation.starttime.getUTCFullYear(consultation.date.getFullYear());

  consultation.endtime.setUTCDate(consultation.date.getDate());
  consultation.endtime.setUTCMonth(consultation.date.getMonth());
  consultation.endtime.getUTCFullYear(consultation.date.getFullYear());

  //When no consultation Set is selected id will be set to 0, but we send null to backend
  if (consultation.set === 0) consultation.set = null;
  return {
    description: consultation.description,
    id: id ? Number(id) : undefined,
    startTime: consultation.starttime,
    endTime: consultation.endtime,
    patientId: patientId,
    therapistId: therapistId,
    consultationSetId: consultation.set,
    Location: Number(consultation.location),
    nomenclature: consultation.nomenclature,
  };
};

const parseConsultationResponse = (response) => {
  let consultation = response;
  return {
    description: consultation.description,
    nomenclature: consultation.nomenclature,
    location: consultation.location,
    date: new Date(consultation.startTime),
    starttime: new Date(consultation.startTime),
    endtime: new Date(consultation.endTime),
    set:
      consultation.consultationSetId !== null
        ? consultation.consultationSetId
        : undefined,
  };
};
const parseNoteResponse = (response) => {
  let notes = response.data;
  let lastNote = notes[notes.length - 1];
  if (lastNote === undefined) return;
  return {
    note: lastNote.content,
  };
};
const parsePatientResponse = (response, patientId) => {
  let patient = response.data.userProfile;
  return {
    patient: {
      userId: patientId,
      label: `${patient.firstName} ${patient.lastName}`,
      firstName: patient.firstName,
      lastName: patient.lastName,
    },
  };
};
const parseCostResponse = (response) => {
  let costs = response.data.costs;

  return {
    costs: costs.map((c) => {
      return {
        title: c.title,
        costId: c.id,
        costType:
          c.compensationType === 0 ? 5 : c.compensationType === 101 ? 101 : 0,
        paybackType: c.compensationType,
        paybackAmount: Number(Number(c.complementaryPayBackAmount).toFixed(2)),
        amount: Number(Number(c.costAmount).toFixed(2)),
      };
    }),
  };
};

const parseSetsResponse = (response) => {
  let data = response.data?.data?.consultationSets;
  return data?.map((conSet) => {
    return {
      label: `${new Date(conSet.startDate).toLocaleDateString("en-GB")} ${
        conSet.title ?? ""
      }`,
      value: conSet.id,
      id: conSet.id,
    };
  });
};

const parseAppointment = (patientId, therapistId, id, consultation) => {
  // Set start and endtime to hold the correct date

  consultation.starttime = new Date(consultation.starttime);
  consultation.endtime = new Date(consultation.endtime);
  consultation.date = new Date(consultation.date);

  consultation.starttime.setUTCDate(consultation.date.getDate());
  consultation.starttime.setUTCMonth(consultation.date.getMonth());
  consultation.starttime.setUTCFullYear(consultation.date.getFullYear());

  consultation.endtime.setUTCDate(consultation.date.getDate());
  consultation.endtime.setUTCMonth(consultation.date.getMonth());
  consultation.endtime.setUTCFullYear(consultation.date.getFullYear());

  return {
    title: consultation.description,
    Location: Number(consultation.location),
    startDate: consultation.starttime,
    endDate: consultation.endtime,
    externalObjectId: id,
    agendaType: Number(1),
    ...{ organiserId: therapistId, userIds: [patientId] },
  };
};
