import AccessibilityIcon from "@mui/icons-material/Accessibility";
import BusinessIcon from "@mui/icons-material/Business";
// Icons
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PersonIcon from "@mui/icons-material/Person";
import ShortTextIcon from "@mui/icons-material/ShortText";
import SummarizeIcon from "@mui/icons-material/Summarize";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../../../api/axios";
import { mapLocationType } from "../../../helpers/enumMappers";
import CostTable from "../../costs/CostTable";
import { IconTable, IconTableRow } from "../../shared/tables/iconTable";
import { urlGetConsultationById } from "./../../../api/urls/consultation";
import ContentLoadingProgress from "./../../shared/progress/ContentLoadingProgress";

/**
 * Consultation overview
 * @returns {JSX.Element}
 */
export default function ConsultationOverviewStep({
  consultationId,
  patient,
  selectedSet,
  therapist,
  setFailed,
}) {
  const [t] = useTranslation("agendaitem");
  const [isLoading, setIsLoading] = useState(true);

  const [consultation, setConsultation] = useState();

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    /**
     * Get consultation by id
     */
    const url = urlGetConsultationById(consultationId);
    axiosInstance
      .get(url, {
        setsRequestCancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const consultation = response.data;
        setConsultation(consultation);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(`GET ${url}`, error);
        setFailed(t("snackbars.failure.getconsultation"));
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [consultationId, setFailed, t]);

  /**
   * Rows for IconTable
   * @returns {IconTableRow[]} rows
   */
  // prettier-ignore
  const rows = [
    new IconTableRow(0, <DateRangeIcon/>, t("fields.consultation.startTime"), moment(new Date(consultation?.startTime)).format("DD/MM/YYYY HH:mm")),
    new IconTableRow(1, <EventAvailableIcon/>, t("fields.consultation.endTime"), moment(new Date(consultation?.endTime)).format("DD/MM/YYYY HH:mm")),
    new IconTableRow(2, <ShortTextIcon/>, t("fields.consultation.description"), consultation?.description ? consultation?.description : ""),
    new IconTableRow(3, <PersonIcon/>, t("fields.consultation.therapist"), `${therapist?.userProfile.firstName} ${therapist?.userProfile.lastName}`),
    new IconTableRow(4, <AccessibilityIcon/>, t("fields.consultation.patient"), `${patient?.userProfile.firstName} ${patient?.userProfile.lastName}`),
    new IconTableRow(5, <SummarizeIcon/>, t("fields.consultation.consultationset"), consultation?.consultationSetId !== null ? consultation?.consultationSetId : "None"),
    new IconTableRow(6, <BusinessIcon/>, t("fields.consultation.location"), mapLocationType(consultation?.location)),
  ];

  if (isLoading) {
    return <ContentLoadingProgress />;
  }

  return (
    <>
      {consultation && (
        <>
          <h3>{t("section.general")}</h3>
          {patient && therapist && <IconTable rows={rows} size="small" />}
          <h3>{t("section.costs")}</h3>
          <CostTable costs={consultation.costs} />
        </>
      )}
    </>
  );
}
