/**
 * Make new invoice
 * @returns {string} url
 */
export const urlPostInvoice = () => `v2/invoices`;

/**
 * Make new credit note based on invoiceId
 * @param {number} invoiceId
 * @returns {string} url
 */
export const urlPostInvoiceCreditnote = (invoiceId) =>
  `v2/invoices/${invoiceId}/creditNote`;
