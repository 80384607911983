import {
  TableSortLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Box,
  TableRow,
  TablePagination,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
function DataTable({
  rows,
  columns,
  page,
  pageSize,
  rowCount,
  setPage,
  setPageSize,
  createSortHandler = () => {},
  orderBy,
  order,
}) {
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div style={{ width: "100%" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns &&
                  columns.map((column) => (
                    <TableCell
                      data-testid="column"
                      key={column.id}
                      align={"center"}
                      style={{ minWidth: column.minWidth, width: column.width }}
                    >
                      <TableSortLabel
                        disabled={column.sortable === false}
                        active={orderBy === column.field.toUpperCase()}
                        direction={
                          orderBy === column.field.toUpperCase() ? order : "asc"
                        }
                        onClick={createSortHandler(column.id)}
                      >
                        {column.headerName}
                        {orderBy === column.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    data-testid="row"
                  >
                    {columns.map((column) => {
                      const value = row[column.field];
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format && value instanceof Date
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!!rowCount &&
          page !== null &&
          pageSize !== null &&
          rows.length <= pageSize &&
          page <= rowCount / pageSize &&
          typeof setPage === "function" &&
          typeof setPageSize === "function" && (
            <TablePagination
              data-testid="datatable-pagination-block"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rowCount}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
      </Paper>
    </div>
  );
}
export default DataTable;

export class Column {
  constructor(id, field, headerName, minWidth, width = undefined) {
    this.id = id;
    this.field = field;
    this.headerName = headerName;
    this.minWidth = minWidth;
    this.width = width;
    this.sortable = false;
  }
}
export class FormattedColumn {
  constructor(id, field, headerName, minWidth, format, width = undefined) {
    this.id = id;
    this.field = field;
    this.headerName = headerName;
    this.minWidth = minWidth;
    this.format = format;
    this.width = width;
    this.sortable = false;
  }
}
export class SortableColumn {
  constructor(id, field, headerName, minWidth, width = undefined) {
    this.id = id;
    this.field = field;
    this.headerName = headerName;
    this.minWidth = minWidth;
    this.width = width;
    this.sortable = true;
  }
}
export class SortableFormattedColumn {
  constructor(id, field, headerName, minWidth, format, width = undefined) {
    this.id = id;
    this.field = field;
    this.headerName = headerName;
    this.minWidth = minWidth;
    this.format = format;
    this.width = width;
    this.sortable = true;
  }
}
