import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function BasicTable({ rows, columns, textAlign = "center" }) {
  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns &&
              columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={textAlign}
                  style={{ minWidth: column.minWidth, width: column.width }}
                >
                  {column.headerName}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                {columns.map((column) => {
                  const value = row[column.field];
                  return (
                    <TableCell key={column.id} align={textAlign}>
                      {column.format ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default BasicTable;
