import { useParams } from "react-router";
import useAuth from "../../../auth/useAuth";
import FilteredInvoicesOverview from "./FilteredInvoicesOverview";

/**
 * Overview of invoices of one patient for the therapist
 * @returns {JSX.Element}
 */
export default function InvoicesOverviewTherapistPatient() {
  const { patientId } = useParams();
  const { id } = useAuth();
  return <FilteredInvoicesOverview therapistId={id} customerId={patientId} />;
}
