import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";

/**
 * Datagrid can not be used as it will limit us further on, adding a checkboxes column in an undedited datatable
 *
 * columns should have a selected property
 * @returns
 */
function CheckedTable({
  rows,
  columns,
  selected,
  setSelected,
  tableSizeSmall,
  tableCellAlign,
}) {
  const [allSelected, setAllSelected] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [dirty, setDirty] = useState(false);

  const setSelectAll = useCallback(() => {
    let val = allSelected;
    setAllSelected(!allSelected);
    let newSelected = selected;
    if (!val) {
      rows.forEach((r) => {
        newSelected.add(r.id);
      });
    } else {
      rows.forEach((r) => {
        newSelected.delete(r.id);
      });
    }
    setSelected(newSelected);
    setDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelected, rows, selected, setSelected, dirty]);

  const handleChange = useCallback(
    (id) => {
      let newSelected = selected;
      if (!newSelected.has(id)) {
        newSelected.add(id);
      } else {
        newSelected.delete(id);
      }
      setSelected(newSelected);
      setDirty(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected, setSelected, dirty]
  );

  useEffect(() => {
    setDirty(false);
  }, [handleChange, setSelectAll]);

  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader size={tableSizeSmall && "small"}>
        <TableHead>
          <TableRow>
            <TableCell
              key={-1}
              align={tableCellAlign ? tableCellAlign : "center"}
              style={{ width: "3em" }}
            >
              <Checkbox
                checked={allSelected}
                onClick={(event) => setSelectAll(event.target.value)}
              />
            </TableCell>
            {columns &&
              columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={tableCellAlign ? tableCellAlign : "center"}
                  style={{ minWidth: column.minWidth, width: column.width }}
                >
                  {column.headerName}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell
                  key={-1}
                  align={tableCellAlign ? tableCellAlign : "center"}
                >
                  <Checkbox
                    checked={selected.has(row.id)}
                    onClick={() => handleChange(row.id)}
                  />
                </TableCell>
                {columns.map((column) => {
                  const value = row[column.field];
                  return (
                    <TableCell
                      key={column.id}
                      align={tableCellAlign ? tableCellAlign : "center"}
                    >
                      {column.format && value instanceof Date
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default CheckedTable;

export class CheckedColumn {
  constructor(id, field, headerName, minWidth, width = undefined) {
    this.id = id;
    this.field = field;
    this.headerName = headerName;
    this.minWidth = minWidth;
    this.width = width;
  }
}
