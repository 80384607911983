import { List } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { Translation } from "react-i18next";
import { NavLink } from "react-router-dom";
import theme from "../../theme";
import useAuth from "../../auth/useAuth";
import { isAppInsigthsEnabled } from "./../../insights/isAppInsightsEnabled";
import { useAppInsightsContext } from "../../insights/AppInsightsContext";
import { trackEvent } from "./../../insights/AppInsightsTrack";
import { eventConstants } from "./../../constants/insight-constants";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import ReceiptIcon from "@mui/icons-material/Receipt";
import EventNoteIcon from "@mui/icons-material/EventNote";

function NavListItem({
  pathname,
  translationKey,
  fileIcon,
  open,
  onClick = () => {},
}) {
  return (
    <NavLink
      exact
      to={pathname}
      style={{ textDecoration: "none", color: "inherit" }}
      activeStyle={{
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.extraLight,
        fontSize: "36px",
      }}
      onClick={onClick}
    >
      <ListItem button style={{ backgroundColor: "inherit" }}>
        <ListItemIcon>{fileIcon}</ListItemIcon>
        {open && (
          <Translation ns={"dashboard"}>
            {(t) => <ListItemText primary={t(translationKey)} />}
          </Translation>
        )}
      </ListItem>
    </NavLink>
  );
}

export function MainListItems({ open }) {
  const { id } = useAuth();

  const isAiEnabled = isAppInsigthsEnabled();
  const appInsights = useAppInsightsContext();
  const trackProps = {
    user: { id: id },
  };

  return (
    <List>
      <NavListItem
        pathname={"/files"}
        translationKey={"navigation.files"}
        fileIcon={<AttachFileIcon />}
        open={open}
        // prettier-ignore
        onClick={() => trackEvent(isAiEnabled, appInsights, eventConstants.NAVIGATION.FILES, trackProps)}
      />
      <NavListItem
        pathname={"/invoices"}
        translationKey={"navigation.invoices"}
        fileIcon={<ReceiptIcon />}
        open={open}
        // prettier-ignore
        onClick={() => trackEvent(isAiEnabled, appInsights, eventConstants.NAVIGATION.INVOICES, trackProps)}
      />
      <NavListItem
        pathname={"/scheduler"}
        translationKey={"navigation.scheduler"}
        fileIcon={<EventNoteIcon />}
        open={open}
        // prettier-ignore
        onClick={() => trackEvent(isAiEnabled, appInsights, eventConstants.NAVIGATION.SCHEDULER, trackProps)}
      />
    </List>
  );
}

export const secondaryListItems = <div />;
