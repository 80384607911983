import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormDatePicker } from "../../shared/forms/form-elements/FormDateTimePicker";
import { FormTextfield } from "../../shared/forms/form-elements/FormTextfield";
import { FormUserPicker } from "../../shared/forms/form-elements/FormUserPicker";

function EditConsultationSetStep({
  control,
  errors,
  editing,
  loading,
  onSubmit,
}) {
  const { t } = useTranslation("consultations");
  return (
    <>
      <form onSubmit={onSubmit}>
        {loading === true && <CircularProgress />}
        {loading === false && (
          <fieldset
            style={{
              border: "none",
              display: "flex",
              flexDirection: "column",
              maxWidth: "900px",
            }}
          >
            <legend>
              {editing
                ? t("edit.titles.edit.consultation")
                : t("edit.titles.new.consultation")}
            </legend>
            {!editing && (
              <FormUserPicker
                displayAsRequired
                control={control}
                name="patient"
                label={t("edit.fields.patient")}
                errors={errors.patient}
              />
            )}
            <FormTextfield
              displayAsRequired
              control={control}
              name="title"
              label={t("edit.fields.title")}
              errors={errors.title}
            />
            <FormTextfield
              displayAsRequired
              control={control}
              name="maxConsultationCount"
              label={t("edit.fields.maxConsultationCount")}
              errors={errors.maxConsultationCount}
            />
            <FormTextfield
              control={control}
              name="nomenclature"
              label={t("edit.fields.nomenclature")}
              errors={errors.nomenclature}
            />
            <FormTextfield
              control={control}
              name="notes"
              label={t("edit.fields.notes")}
              multiline
              rows="3"
              errors={errors.notes}
            />
            <FormDatePicker
              displayAsRequired
              control={control}
              name="startdate"
              label={t("edit.fields.startdate")}
              errors={errors.startdate}
            />
            <FormDatePicker
              control={control}
              name="enddate"
              label={t("edit.fields.enddate")}
              errors={errors.enddate}
            />
          </fieldset>
        )}
      </form>
    </>
  );
}
export default EditConsultationSetStep;
