import PageNotFound from "../components/global/PageNotFound";
import { Page } from "../components/shared/default/Page";

/**
 * Page Not Found Screen (404)
 * @returns {JSX.Element}
 */
export default function PageNotFoundScreen() {
  return <Page content={<PageNotFound />} />;
}
