import {
  Button,
  Container,
  Divider,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SuccesSnackbar from "./../shared/menus/snackbars/SuccesSnackbar";
import FailureSnackbar from "../shared/menus/snackbars/FailureSnackbar";
import Setting from "./Setting";
import useAuth from "../../auth/useAuth.jsx";
import { axiosInstance } from "../../api/axios";
import ContentLoadingProgress from './../shared/progress/ContentLoadingProgress';

export default function AddressSetting() {
  const { t } = useTranslation("settings");
  const { id } = useAuth();
  const [failed, setFailed] = useState();
  const [succes, setSucces] = useState();
  const [loading, setLoading] = useState(true);

  const [streetName, setStreetName] = useState();
  const [city, setCity] = useState();
  const [zipcode, setZipcode] = useState();
  const [country, setCountry] = useState();
  const [houseNumber, setHouseNumber] = useState();

    // useEffect to receive user settings
    useEffect(() => {
      setLoading(true);
      axiosInstance
        .get(`/v2/therapists/${id}`)
        .then((res) => {
          const userProfile = res.data.userProfile;
          setStreetName(userProfile.streetName === null ? "" : userProfile.streetName)
          setCity(userProfile.city === null ? "" : userProfile.city);
          setZipcode(userProfile.zipcode === null ? "" : userProfile.zipcode)
          setCountry(userProfile.country === null ? "" : userProfile.country)
          setHouseNumber(userProfile.houseNumber === null ? "" : userProfile.houseNumber)
          setLoading(false);
        })
        .catch(function (error) {
          // handle error
          setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
        });
    }, [id, setLoading, t]);
    if(loading) return <ContentLoadingProgress/>

    onsubmit = () => {
      axiosInstance
        .put(`/v2/user/${id}/address`, {
          zipCode: zipcode,
          streetName: streetName,
          houseNumber: houseNumber,
          city: city,
          country: country,
        })
        .then((response) => {
          setSucces(true);
        })
        .catch(function (error) {
          // handle error
          setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
        });
    };
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5" gutterBottom component="div">
        {t("address.title")}
      </Typography>
      <Stack
        sx={{ width: "90%", maxWidth: "900px", pt: 5 }}
        direction="column"
        divider={<Divider orientation="horizontal" flexItem />}
        spacing={1}
      >
        <Setting
          value={streetName}
          setValue={setStreetName}
          name={t("address.streetname")}
          description={t("address.streetname")}
          type="text"
        />

        <Setting
          value={houseNumber}
          setValue={setHouseNumber}
          name={t("address.houseNumber")}
          description={t("address.houseNumber")}
          type="text"
        />
        <Setting
          value={zipcode}
          setValue={setZipcode}
          name={t("address.zipcode")}
          description={t("address.zipcode")}
          type="text"
        />
        <Setting
          value={city}
          setValue={setCity}
          name={t("address.city")}
          description={t("address.city")}
          type="text"
        />

        <Setting
          value={country}
          setValue={setCountry}
          name={t("address.country")}
          description={t("address.country")}
          type="text"
        />
      </Stack>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          padding: 10,
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        <Button variant="outlined" onClick={onsubmit}>
          {t("submit")}
        </Button>
      </Box>

      <SuccesSnackbar
        open={!!succes}
        setOpen={setSucces}
        text={t("snackbars.succes")}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </Container>
  );
}
