import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Tooltip, Link } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DataTable, { Column } from "../../shared/tables/Datatable";
import { mapGenderType } from "./../../../helpers/enumMappers";

/**
 * Select Patient List
 * @param {props} props
 * @returns {JSX.Element}
 */
export default function SelectPatientList({
  patients,
  action,
  rowCount,
  page,
  setPage,
  pageSize,
  setPageSize,
}) {
  const [t] = useTranslation(["patients", "translations"]);
  const [rows, setRows] = useState([]);

  /**
   * Columns
   * @type {{Column}
   */
  const columns = [
    new Column(0, "fullName", t("tables.columns.fullName"), 20),
    new Column(1, "email", t("tables.columns.email"), 20),
    new Column(2, "gender", t("tables.columns.gender"), 20),
    new Column(3, "actions", t("tables.columns.actions"), 20),
  ];

  useEffect(() => {
    const mapPatients = () => {
      return patients.map((p) => {
        return {
          id: p.id,
          fullName: (
            <Link href={`/patients/${p.id}`} underline="none">
              {`${p.userProfile.lastName} ${p.userProfile.firstName}`}
            </Link>
          ),
          email: (
            <Link href={`mailto:${p.userProfile.email}`} underline="none">
              {p.userProfile.email}
            </Link>
          ),
          gender: t(`${mapGenderType(p.userProfile.gender)}`, { ns: "translations"}),
          actions: (
            <>
              <Tooltip title={t("tooltips.createinvoiceforpatient")}>
                <Button
                  variant="outlined"
                  onClick={() => action(p.id)}
                  startIcon={<ReceiptIcon />}
                >
                  {t("tooltips.createinvoiceforpatient")}
                </Button>
              </Tooltip>
            </>
          ),
        };
      });
    };

    const parsedPatients = mapPatients();
    setRows(parsedPatients);

    return () => {};
  }, [action, patients, t]);

  return (
    <>
      {rows && rows.length > 0 && (
        <DataTable
          rows={rows}
          columns={columns}
          rowCount={rowCount}
          pageSize={pageSize}
          page={page}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      )}
    </>
  );
}
