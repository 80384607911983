import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";

function FailureSnackbar({ open, setOpen, text }) {
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {text}
      </Alert>
    </Snackbar>
  );
}
export default FailureSnackbar;
