import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  Stack,
  Typography,
} from "@mui/material";
import FailureSnackbar from "../menus/snackbars/FailureSnackbar";
import ConfirmationModal from "../menus/modals/ConfirmationModal";

/**
 * This wizard is called Single Flow because you can only go in one direction.
 * There is no back button.
 * With every press on the continue button it can executes a handle<?> function if available.
 * It is also possible to show a modal before going to the next step.
 * @param {{steps: [{ id: number, title: string, description: string, content: JSX.Element , modal: { title: string, description: string, positiveText: string, negativeText: string } | undefined, handleAfter: () => {Promise} | undefined }], wide: boolean}} props
 * @returns {JSX.Element} Single Flow Wizard
 */
export default function SingleFlowWizard({
  steps,
  handleFinish,
  wide = false,
}) {
  const [t] = useTranslation("wizard");
  const [activeStep, setActiveStep] = useState(0);
  const [failed, setFailed] = useState();
  const [modalPrompted, setModalPrompted] = useState(false);

  /**
   * This function will open the modal.
   */
  const handleModalOpen = () => setModalPrompted(true);

  /**
   * This function will close the modal.
   */
  const handleModalClose = () => setModalPrompted(false);

  /**
   * This function will be executed when the user presses on the "Continue" or "Finish" button.
   * It will look if the {@link activeStep} has an modal.
   * If there is an modal it will open it ({@link handleModalOpen}) and don't do anything else.
   * If not, it will continue the flow without showing the modal ({@link handleFlow}).
   */
  const handleButton = () => {
    if (steps[activeStep].modal !== undefined) handleModalOpen();
    else handleFlow();
  };

  /**
   * This function is responsible for handling the flow.
   * It will look at the {@link activeStep}.
   * If the activeStep is the last step, it will execute the handle function with {@link handleFinish} as parameter.
   * If not, it will execute the handle function with {@link goNextStep} as parameter.
   * @see {@link handle}
   */
  const handleFlow = () => {
    if (activeStep === steps.length - 1) handle(handleFinish);
    else handle(goNextStep);
  };

  /**
   * This function will handle the step.
   * If there is a {@link modalPrompted} open, it will close it.
   * If there is a handleAfter function defined it will execute that function and after a succes the {@link executeFinalFunction}.
   * If there is no handleAfter function it will execute the {@link executeFinalFunction}.
   * @param {() => {}} executeFinalFunction
   */
  const handle = (executeFinalFunction) => {
    if (modalPrompted === true) handleModalClose();
    if (steps[activeStep].handleAfter !== undefined) {
      steps[activeStep]
        .handleAfter()
        .then((res) => {
          executeFinalFunction();
        })
        .catch((error) => {
          if (error === undefined) setFailed("Failed");
          else setFailed(error);
        });
    } else {
      executeFinalFunction();
    }
  };

  /**
   * This function will make sure the {@link activeStep} will be the next step by using the {@link setActiveStep} function
   */
  const goNextStep = () => setActiveStep(activeStep + 1);

  return (
    <Box
      sx={{
        ...styles.box,
        width: wide ? "100%" : "fit-content",
      }}
    >
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={styles.stepper}
      >
        {steps.map((step, index) => (
          <Step key={step.id} data-testid="singleflowwizard-step">
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">{t("final-step")}</Typography>
                ) : null
              }
            >
              {step.title}
            </StepLabel>
            <StepContent>
              <Typography variant="caption">{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Stack sx={styles.stackContentAndActions}>
        <div
          data-testid={"singleflowwizard-step__content"}
          sx={styles.wrapperContent}
        >
          {steps[activeStep].content}
        </div>
        <Box sx={styles.boxActions}>
          <Button
            variant="contained"
            onClick={handleButton}
            sx={styles.buttonAction}
            data-testid="singleflowwizard-step__next-button"
          >
            {activeStep === steps.length - 1
              ? t("actions.finish")
              : t("actions.continue")}
          </Button>
        </Box>
      </Stack>
      <ConfirmationModal
        open={!!modalPrompted}
        handleClose={() => handleModalClose()}
        command={() => handleFlow()}
        content={steps[activeStep].modal?.content}
        title={steps[activeStep].modal?.title}
        positivetext={steps[activeStep].modal?.positiveText}
        negativetext={steps[activeStep].modal?.negativeText}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </Box>
  );
}

const styles = {
  box: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
  },
  stepper: {
    height: "fit-content",
    padding: "1em 1em 0 1em",
    width: "20em",
  },
  stackContentAndActions: {
    minWidth: "40em",
    width: "80%",
    justifyContent: "space-between",
    padding: "1em",
  },
  wrapperContent: {
    width: "fit-content",
  },
  boxActions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    padding: "1em",
    margin: 0,
  },
  buttonAction: {
    mt: 1,
    mr: 1,
  },
};
