import { Text, View } from "@react-pdf/renderer";
import { dateToString } from "../../../../helpers/dates/dateToString";
import { roundValutaNumber } from "./../../../../helpers/numbers/roundValutaNumber";
import {
  mapLocationType,
  mapValutaType,
} from "../../../../helpers/enumMappers/index";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

/**
 * Lines Table section for the Invoice PDF
 * @param {{ styles: {}, keyValues: [{key: {}, value: {}}]}} props
 * @returns {View} Table section
 */
export default function InvoicePdfLinesTableSection({
  styles,
  keyValues,
  invoicePdfLinesTableSectionText,
  isCreditNote,
}) {
  return (
    <>
      {keyValues && keyValues.length !== 0 && (
        <View style={[styles.row, styles.rowConsultationCosts]}>
          {keyValues.map(({ key, value }) => (
            <View style={styles.consultation} key={key.id}>
              <Text style={styles.textText}>
                {invoicePdfLinesTableSectionText} {dateToString(key.startDate)}{" "}
                {"- "}
                {key.location}
              </Text>
              <View style={styles.tableCosts}>
                {value.map((line) => {
                  return (
                    <View style={styles.tableRowCosts} key={line.id}>
                      <Text style={styles.textText}>{line.costDto.title}</Text>
                      <Text style={styles.textText}>
                        {isCreditNote && "- "}
                        {mapValutaType(line.costDto.valuta)}
                        {roundValutaNumber(Number(line.costDto.costAmount))}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
          ))}
        </View>
      )}
    </>
  );
}
