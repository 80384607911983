import InvoiceExportScreen from "../../screens/InvoiceExportScreen";
import CreateInvoicePatientScreen from "../../screens/CreateInvoicePatientScreen";
import InvoiceScreen from "../../screens/InvoiceScreen";
import InvoicesTherapistScreen from "../../screens/InvoicesTherapistScreen";
import CreateCollectionStateInvoiceScreen from "../../screens/CreateCollectionStateInvoiceScreen";
import ExportOverviewScreen from "./../../screens/ExportOverviewScreen";

/**
 * @type {Array<{path: string, screen: JSX.Element}>}
 */
// prettier-ignore
export const routesInvoices = [
  { path: "/invoices/export/invoice/:invoiceId", screen: <ExportOverviewScreen type={1}/>, },
  { path: "/invoices/export/collectionstate/:invoiceId", screen: <ExportOverviewScreen type={2}/>, },
  { path: "/invoices/:invoiceId/export/collectionState/user/:patientId", screen: <CreateCollectionStateInvoiceScreen />, },
  { path: "/invoices/:invoiceId/export", screen: <InvoiceExportScreen />, },
  { path: "/invoices/new", screen: <CreateInvoicePatientScreen />, },
  { path: "/invoices/:invoiceId", screen: <InvoiceScreen />, },
  { path: "/invoices", screen: <InvoicesTherapistScreen />, },
  
];
