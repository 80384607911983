import { FormDatePicker } from "../../shared/forms/form-elements/FormDateTimePicker";
import { FormTextfield } from "../../shared/forms/form-elements/FormTextfield";
import { FormNumberfield } from "./../../shared/forms/form-elements/FormNumberField";
import { useTranslation } from "react-i18next";
import { FormCheckbox } from "./../../shared/forms/form-elements/FormCheckbox";
import { useState, useEffect } from "react";

export default function CreateInvoiceStep({
  control,
  errors,
  checked,
}) {
  const [t] = useTranslation("invoices");

  return (
    <>
      <form style={styles.form}>
        <div style={styles.fieldsetWrapper}>
          <fieldset style={styles.fieldset}>
            <legend>{t("form.legends.dates")}</legend>
            <FormDatePicker
              control={control}
              displayAsRequired
              name="date"
              label={t("form.fields.date")}
              errors={errors.date}
            />
            <FormDatePicker
              control={control}
              displayAsRequired
              name="dueDate"
              label={t("form.fields.dueDate")}
              errors={errors.dueDate}
            />
          </fieldset>
          <fieldset style={styles.fieldset}>
            <legend>{t("form.legends.numberandreference")}</legend>
            <FormNumberfield
              control={control}
              displayAsRequired
              name="number"
              label={t("form.fields.number")}
              errors={errors.number}
            />
            <FormTextfield
              control={control}
              name="reference"
              label={t("form.fields.reference")}
              errors={errors.reference}
            />
          </fieldset>
        </div>
        <div styles={styles.fieldsetWrapper}>
          <fieldset style={styles.fieldset}>
            <legend>{t("form.legends.remarks")}</legend>
            <FormTextfield
              control={control}
              name="remarks"
              label={t("form.fields.remarks")}
              errors={errors.remarks}
            />
          </fieldset>
        </div>
        <div styles={styles.fieldsetWrapper}>
          <fieldset style={styles.fieldset}>
            <legend>{t("form.legends.thirdpayersystem")}</legend>
            <FormCheckbox
              control={control}
              name="thirdPayerSystem"
              label={t("form.fields.thirdpayersystem")}
              errors={errors.remarks}
              checked={checked}
            />
          </fieldset>
        </div>
      </form>
    </>
  );
}

const styles = {
  form: {
    margin: "unset",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  fieldsetWrapper: {
    display: "flex",
    flexDirector: "row",
    justifyContent: "space-between",
  },
  fieldset: {
    border: "none",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};
