import Wizard from "../../shared/wizards/Wizard";

import { object, string, number, date } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { axiosInstance } from "../../../api/axios";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import axios from "axios";
import { useTranslation } from "react-i18next";
import EditConsultationSetStep from "./EditConsultationSetStep";

const schema = object({
  id: number("error.number"),
  title: string("error.string").required("error.required"),
  patient: object({
    userId: number().required("error.required"),
    label: string().required("error.required"),
    firstName: string(),
    lastName: string(),
    userType: number(),
  }).required(),
  nomenclature: string()
    .matches(/^[0-9]+$/, "Must be only digits") //todo translate
    .min(6, "Must be exactly 6 digits") //todo translate
    .max(6, "Must be exactly 6 digits"), //todo translate
  startdate: date("error.date").default(new Date()).required("error.required"),
  enddate: date("error.date").optional(),
  maxConsultationCount: number("error.number").required("error.required"),
  notes: string("error.string").optional(),
}).required();

function EditConsultationSetWizard() {
  const { id } = useParams();
  const history = useHistory();
  const [failed, setFailed] = useState();

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation("consultations");
  const {
    control,
    reset,
    formState: { errors, isValid, isValidating },
    trigger,
    handleSubmit,
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // Actions below are to prevent the fields from being updated when unmounted, this is as a result of warning
    // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
    // isMounted acts as a failsave, as the canceltoken should cancel requests sent to the backend
    let isMounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    if (id !== undefined) {
      // the loading tag enables spinners showing status
      setLoading(true);

      // parsers
      const parseFields = (data) => {
        return {
          title: data.title,
          notes: data.notes ?? undefined,
          startdate: new Date(data.startDate),
          enddate: data.endDate ? new Date(data.endDate) : undefined,
          maxConsultationCount: data.amountOfConsultations,
        };
      };

      const consultationsUrl = `/v2/consultationsets/${id}/consultations`;

      axiosInstance
        .get(consultationsUrl, {
          setsRequestCancelToken: cancelTokenSource.token,
        })
        .then((responses) => {
          // handle success
          if (isMounted) {
            let set = responses.data.consultationSetDto;
            let previousData = parseFields(set);
            axiosInstance
              .get(`/v2/patients/${set.patientId}/info`)
              .then((response) => {
                if (isMounted) {
                  let patient = response.data.userProfile;

                  previousData = {
                    ...previousData,
                    ...{
                      patient: {
                        id: patient.id,
                        userId: set.patientId,
                        label: `${patient.firstName} ${patient.lastName}`,
                        firstName: patient.firstName,
                        lastName: patient.lastName,
                      },
                    },
                  };
                  reset(previousData, { keepValues: false });
                }
              })
              .catch(function (error) {
                // handle error
                if (isMounted)
                  setFailed(
                    `${t("snackbars.data-failure")} \n ${error.message}`
                  );
              });
          }
        })
        .catch(function (error) {
          // handle error
          if (isMounted)
            setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
        })
        .then(function () {
          // always executed
          if (isMounted) setLoading(false);
        });

      return () => {
        isMounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [id, t, reset]);

  const onValid = (data) => {
    // Send to backend
    if (!!id) {
      axiosInstance
        .put(`/v2/consultationsets/${id}`, {
          id: parseInt(id),
          startDate: data.startdate,
          endDate: data.enddate,
          title: data.title,
          notes: data.notes,
          patientId: data.patient.userId,
          consultationDtos: [],
          amountOfConsultations: data.maxConsultationCount,
        })
        .then(function () {
          history.push("/files");
        })
        .catch(function (error) {
          setFailed(error.message);
        });
    } else {
      axiosInstance
        .post("/v2/consultationsets", {
          startDate: data.startdate,
          endDate: data.enddate,
          title: data.title,
          nomenclature: data.nomenclature,
          notes: data.notes,
          patientId: data.patient.userId,
          amountOfConsultations: data.maxConsultationCount,
          consultationDtos: [],
        })
        .then(function () {
          history.push("/files");
        })
        .catch(function (error) {
          setFailed(error.message);
        });
    }
  };
  const onInvalid = () => {
  };

  const steps = [
    {
      id: 0,
      title: t(`titles.${id !== undefined ? "edit" : "new"}.consultationSet`),
      content: (
        <EditConsultationSetStep
          control={control}
          errors={errors}
          editing={!!id}
          loading={loading}
          onSubmit={handleSubmit(onValid, onInvalid)}
        />
      ),
      description: t("wizards.consultationsets.0.description"),
      onNext: () =>
        trigger(["startdate", "title", "patient", "maxConsultationCount"]),
      isValid: isValid,
      isValidating: isValidating,
    },
  ];

  return (
    <>
      <Wizard steps={steps} handleFinish={handleSubmit(onValid, onInvalid)} />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
export default EditConsultationSetWizard;
