import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { axiosInstance } from "../../../api/axios";
import CompleteConsultationList from "../../consultations/consultationList/CompleteConsultationList";
import ConfirmationModal from "../../shared/menus/modals/ConfirmationModal";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import SuccesSnackbar from "../../shared/menus/snackbars/SuccesSnackbar";
import CollapsingTable from "../../shared/tables/CollapsingTable";
import { Column, FormattedColumn } from "../../shared/tables/Datatable";

/**
 * A consultation list has the responsability of getting the consultationSets from the api matching the filters handed down.
 * @returns a list of all consultations matching the parameters (paged, filtered) as consultation-list-items
 */
function SingleSetWithCons({ setId }) {
  const [t] = useTranslation("consultations");
  const columns = [
    new Column(0, "title", t("tables.columns.title"), 80),
    new FormattedColumn(
      1,
      "startDate",
      t("tables.columns.startDate"),
      80,
      (value) => value.toLocaleDateString("nl-BE")
    ),
  ];

  const [rows, setRows] = useState();
  const [succes, setSucces] = useState(false);
  const [failed, setFailed] = useState();
  const [deletePrompted, setDeletePrompted] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const calculateRows = (data) => {
      return [
        {
          ...data,

          ...{
            startDate: new Date(data.startDate),
            collapsingContentTitle: t("tables.titles.consultations"),
            collapsingContent: <CompleteConsultationList setFilter={data.id} />,
          },
        },
      ];
    };

    axiosInstance
      .get(`/v2/consultationSets/${setId}`)
      .then(function (response) {
        // handle success
        setRows(calculateRows(response.data));
      })
      .catch(function (error) {
        // handle error
        setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
      });
  }, [t, history, succes, setId]);

  return (
    <div className="card-list" style={{ marginBottom: 15 }}>
      <CollapsingTable rows={rows} columns={columns} />
      <ConfirmationModal
        open={!!deletePrompted}
        handleClose={() => setDeletePrompted(false)}
        content={t("confirmations.remove.consultationset")}
        title={t("labels.remove.consultationset")}
      />
      <SuccesSnackbar
        open={succes}
        setOpen={setSucces}
        text={t("snackbars.succes")}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </div>
  );
}
export default SingleSetWithCons;
