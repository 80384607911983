export const agendaTypes = [
  { id: 1, value: 1, label: "agenda.consultation.label", text: "agenda.consultation.label" },
  { id: 2, value: 2, label: "agenda.videoconsultation.label", text: "agenda.videoconsultation.label" },
  // { id: 3, value: 3, label: "Meeting", text: "Meeting" },
  { id: 4, value: 4, label: "agenda.other.label", text: "agenda.other.label" },
];
export const titledAgendaTypes = [
  { id: -1, value: undefined, label: "agenda.select.title", disabled: true },
  ...agendaTypes,
];
