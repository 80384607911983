import { Tabs } from "@mui/material";
import { Box } from "@mui/system";

function VerticalTabs({ tabs, tabPanels, selectedTab, setSelectedTab }) {
  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        minHeight: "40vh",
      }}
    >
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        orientation="vertical"
        variant="scrollable"
        value={selectedTab}
        onChange={handleChange}
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {tabs && tabs}
      </Tabs>
      {tabPanels && tabPanels}
    </Box>
  );
}
export default VerticalTabs;
