import FilteredInvoicesOverview from "./../invoicesOverview/FilteredInvoicesOverview";
import useAuth from "../../../auth/useAuth";

/**
 * Overview of invoices of all patients for the therapist
 * @returns {JSX.Element}
 */
export default function InvoicesOverviewTherapist() {
  const { id } = useAuth();
  return <FilteredInvoicesOverview therapistId={id} />;
}
