import { Tabs } from "@mui/material";
import { Box } from "@mui/system";

function HorizontalTabs({ tabs, tabPanels, selectedTab, setSelectedTab }) {
  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        value={selectedTab}
        onChange={handleChange}
        sx={{ borderColor: "divider" }}
      >
        {tabs && tabs}
      </Tabs>
      {tabPanels && tabPanels}
    </Box>
  );
}
export default HorizontalTabs;
