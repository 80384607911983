/**
 * Track Press event
 * @param {boolean} isAiEnabled
 * @param {ReactPlugin} appInsights
 * @param {string} name
 * @param {{}} props
 */
export function trackEvent(
  isAiEnabled = false,
  appInsights,
  name = "UNKNOWN EVENT",
  props = {}
) {
  if (isAiEnabled && appInsights && appInsights !== null)
    appInsights.trackEvent({ name: name }, props);
}

/**
 * Track PageView
 * @param {boolean} isAiEnabled
 * @param {ReactPlugin} appInsights
 * @param {string} name
 * @param {{}} props
 */
export function trackPageView(
  isAiEnabled = false,
  appInsights,
  name = "UNKNOWN PAGEVIEW",
  props = {}
) {
  if (isAiEnabled && appInsights && appInsights !== null)
    appInsights.trackPageView({
      name: name,
      properties: props,
    });
}
