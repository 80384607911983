import { PropTypes } from "prop-types";
import Header from "../default/Header";
import PageLoadingProgress from "../progress/PageLoadingProgress";
import PageLoadingErrorAlert from "../alerts/PageLoadingErrorAlert";

/**
 * Page Loading
 *
 * This component will act as a wrapper and will handle the loading of the page.
 *
 * By default it will show a progress.
 * When errorLoadingPage property is present, the user will see a friendly error.
 *
 * @param {{ header: Header | JSX.Element | undefined, isLoading: boolean, errorLoading: {} | undefined }} props
 * @returns {JSX.Element}
 */
export default function PageLoading({ header, isLoading, errorLoading }) {
  if (errorLoading) {
    return (
      <>
        {header && header}
        <PageLoadingErrorAlert error={errorLoading} />
      </>
    );
  }
  if (isLoading) {
    return (
      <>
        {header && header}
        <PageLoadingProgress />
      </>
    );
  }
}

PageLoading.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.instanceOf(Header),
  ]),
  isLoading: PropTypes.bool.isRequired,
  errorLoading: PropTypes.object,
};
