import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../../api/axios";
import FailureSnackbar from "../shared/menus/snackbars/FailureSnackbar";
import Setting from "./Setting";
import { isValidIBANNumber } from "./../../helpers/ibanValidator";
import SuccesSnackbar from "./../shared/menus/snackbars/SuccesSnackbar";
import useAuth from "../../auth/useAuth.jsx";

function InvoiceSettings() {
  // state
  const { id } = useAuth();
  const [invoiceNumber, setInvoiceNumber] = useState(1);
  const [iban, setIban] = useState();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("settings");

  const [failed, setFailed] = useState();
  const [succes, setSucces] = useState();

  // useEffect to receive user settings
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/v2/therapists/${id}`)
      .then((res) => {
        setInvoiceNumber(res.data.invoiceNumber);
        setIban(res.data.iban === null ? "" : res.data.iban);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
      });
  }, [id, setLoading, t]);

  onsubmit = () => {
    if (isValidIBANNumber(iban) || iban === "") {
      axiosInstance
        .put(`v2/therapists/users/${id}/settings/invoices`, {
          newInvoiceNumber: Number(invoiceNumber),
          iban: iban,
        })
        .then((response) => {
          setSucces(true);
        })
        .catch(function (error) {
          // handle error
          setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
        });
    } else {
      setFailed(`${t("invoices.iban_invalid")}`);
    }
  };

  return (
    <>
      {!!loading ? (
        <CircularProgress />
      ) : (
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5" gutterBottom component="div">
            {t("invoices.title")}
          </Typography>
          <Stack
            sx={{ width: "90%", maxWidth: "900px", pt: 5 }}
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
            spacing={1}
          >
            <Setting
              value={invoiceNumber}
              setValue={setInvoiceNumber}
              name={t("invoices.number")}
              description={t("invoices.number_desc")}
              type="number"
            />

            <Setting
              value={iban}
              setValue={setIban}
              name={t("invoices.iban")}
              description={t("invoices.iban_desc")}
              type="text"
            />
          </Stack>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              padding: 10,
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <Button variant="outlined" onClick={onsubmit}>
              {t("submit")}
            </Button>
          </Box>

          <SuccesSnackbar
            open={!!succes}
            setOpen={setSucces}
            text={t("snackbars.succes")}
          />
          <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
        </Container>
      )}
    </>
  );
}
export default InvoiceSettings;
