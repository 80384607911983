import { Card, CardContent, CardHeader, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { FormDropdown } from "../../shared/forms/form-elements/FormDropdown";
import { FormTextfield } from "../../shared/forms/form-elements/FormTextfield";
import ClearIcon from "@mui/icons-material/Clear";

function AddVerificationAndNomenclature({
  control,
  errors,
  selectedSet,
  sets,
  setId,
  onClick,
  onSubmit,
}) {
  const { t } = useTranslation("translations");
  const card = (
    <>
      <CardContent>
        <CardHeader
          subheader={t("consultation.selectedcontainer.label", {value1 : `${selectedSet?.consultationSetDto.title}`})}
        ></CardHeader>
        <p>
          {t("container.consultationcount.label", {
            value: `${selectedSet?.consultationSetDto.amountOfConsultations}`,
          })}
        </p>
        <p>
          {t("container.remainingslots.label", {
            value: `${selectedSet?.remainingSlots}`,
          })}
        </p>
      </CardContent>
    </>
  );

  return (
    <>
      <form onSubmit={onSubmit()}>
        <fieldset
          style={{
            border: "none",
            display: "flex",
            flexDirection: "column",
            maxWidth: "900px",
          }}
        >
          <legend>{t("consultation.addcontainerandnomenclature.title")}</legend>
          <FormDropdown
            control={control}
            name="set"
            label={t("general.container.label")}
            errors={errors.set}
            options={
              sets && sets.length > 0
                ? sets
                : [
                    {
                      value: `${t("nocontainers")}`,
                      id: -1,
                      label: `${t("nocontainers")}`,
                    },
                  ]
            }
          />
          {setId !== -1 && setId !== undefined && setId !== null && (
            <Box sx={{ minWidth: 275 }}>
              <Card variant="outlined">
                <Button color="primary" onClick={onClick}>
                  <ClearIcon />
                </Button>
                {card}
              </Card>
            </Box>
          )}
          <FormTextfield
            control={control}
            name="nomenclature"
            label={t("general.nomenclature.label")}
            standardValue={selectedSet?.nomenclature}
            errors={errors.nomenclature}
          />
          <FormTextfield
            control={control}
            name="note"
            label={t("general.note.label")}
            multiline
            rows="3"
            errors={errors.note}
          />
        </fieldset>
      </form>
    </>
  );
}
export default AddVerificationAndNomenclature;
