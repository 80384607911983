import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { axiosInstance, handleAxiosError } from "../../api/axios";
import useAuth from "../../auth/useAuth";
import Header from "../shared/default/Header";
import FailureSnackbar from "../shared/menus/snackbars/FailureSnackbar";
import { checkNotEmptyString } from "./../../helpers/checkNotEmptyString";
import { isConsultationNomenclature } from "./../../helpers/consultation/isConsultationNomenclature";
import PageLoading from "./../shared/loading/PageLoading";
import SuccesSnackbar from "./../shared/menus/snackbars/SuccesSnackbar";
import SingleFlowWizard from "./../shared/wizards/SingleFlowWizard";

// Urls
import { urlGetPatientId } from "./../../api/urls/patient";
import { urlGetTherapistId } from "./../../api/urls/therapist";
import {
  urlGetAgendaitemById,
  urlPutAgendaitem,
} from "./../../api/urls/agendaitem";
import {
  urlGetConsultationById,
  urlPostConsultation,
  urlPostNotesToConsultation,
  urlPutConsultation,
} from "../../api/urls/consultation";

// Steps
import {
  ConfirmPatientConsultationStep,
  ConsultationCostsStep,
  ConsultationDetailsStep,
  ConsultationNotesStep,
  ConsultationOverviewStep,
} from "./addConsultationWizardSteps";

/**
 * Add Consultation Wizard
 * @returns {JSX.Element}
 */
export default function AddConsultationWizard() {
  const [t] = useTranslation("agendaitem");
  const { agendaitemId } = useParams();
  const history = useHistory();
  const { id } = useAuth();

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();

  const [succes, setSucces] = useState();
  const [failed, setFailed] = useState();

  const [agendaItem, setAgendaItem] = useState();
  const [patient, setPatient] = useState();
  const [therapist, setTherapist] = useState();
  const [consultationId, setConsultationId] = useState();
  const [consultation, setConsultation] = useState();

  // Uncontrolled values
  const [selectedSet, setSelectedSet] = useState();
  const [consultationDescription, setConsultationDescription] = useState("");
  const [consultationNomenclature, setConsultationNomenclature] = useState("");
  const [consultationType, setConsultationType] = useState("0"); // "0" means Indivicual consultation and "1" means it is part of a consultationset
  const [selectedConsultationSetId, setSelectedConsultationSetId] = useState();
  const [noteContent, setNoteContent] = useState("");

  /**
   * This useEffect will execute at the beginning.
   */
  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    /*
     * Get agendaitem by ID
     */
    const url = urlGetAgendaitemById(agendaitemId);
    axiosInstance
      .get(url)
      .then((response) => {
        const agendaItem = response.data;
        setAgendaItem(agendaItem);
        setConsultationDescription(agendaItem.title);
        /*
         * Get patient from agendaitem by ID
         */
        const patientUrl = urlGetPatientId(agendaItem.userIds[0]);
        axiosInstance
          .get(patientUrl)
          .then((response) => {
            const patient = response.data;
            setPatient(patient);
            setIsLoadingPage(false);
          })
          .catch((error) =>
            setErrorLoadingPage({
              title: "error.request.get.patient",
              axiosError: handleAxiosError(error),
            })
          );
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: "error.request.get.agendaitem",
          axiosError: handleAxiosError(error),
        })
      );

    return () => {
      cancelTokenSource.cancel();
    };
  }, [agendaitemId, setFailed, t]);

  /*
   * Get the logedin therapist
   * (At the beginning)
   */
  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    /*
     * Get therapist by ID
     */
    const url = urlGetTherapistId(id);
    axiosInstance
      .get(url)
      .then((reponse) => {
        const therapistFromReponse = reponse.data;
        setTherapist(therapistFromReponse);
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: "error.request.get.therapist",
          axiosError: handleAxiosError(error),
        })
      );

    return () => {
      cancelTokenSource.cancel();
    };
  }, [t, id]);

  /**
   * This useEffect will execute when the consultationId is available after the first handleAfter execution.
   */
  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    /*
     * Get consultation by ID
     */
    if (consultationId) {
      const url = urlGetConsultationById(consultationId);
      axiosInstance
        .get(url, {
          setsRequestCancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          const consultation = response.data;
          setConsultation(consultation);
        })
        .catch((error) => {
          console.error(`GET ${url}`, error);
          setFailed(t("snackbars.failure.getconsultation"));
        });
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, [consultationId, t]);

  /* will get the consultation set*/
  useEffect(() => {
    // Actions below are to prevent the fields from being updated when unmounted, this is as a result of warning
    // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
    // isMounted acts as a failsave, as the canceltoken should cancel requests sent to the backend
    let isMounted = true;
    const cancelTokenSource = axios.CancelToken.source();

    if (
      selectedConsultationSetId &&
      selectedConsultationSetId !== undefined &&
      selectedConsultationSetId !== null
    ) {
      const setUrl = `/v2/consultationSets/${selectedConsultationSetId}`;

      axiosInstance
        .get(setUrl, { setRequestCancelToken: cancelTokenSource.token })
        .then((response) => {
          // handle success
          if (isMounted) {
            let set = response.data;

            setSelectedSet(set);
            response.data.nomenclature !== null
              ? setConsultationNomenclature(response.data.nomenclature)
              : setConsultationNomenclature(" ");
          }
        })
        .catch(function (error) {
          // handle error
          if (isMounted)
            setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
        });
    }

    return () => {
      isMounted = false;
      cancelTokenSource.cancel();
    };
  }, [selectedConsultationSetId, t]);

  /**
   * Creates a new consultation based on the agendaitem and it will update the agendaItem based on the id of the consultation
   * @returns {Promise}
   */
  const createConsultation = () =>
    new Promise((resolve, reject) => {
      /*
       * Create consultation for this agendaitem
       */
      const url = urlPostConsultation();
      const newConsultation = {
        id: 0,
        therapistId: id,
        patientId: patient?.id,
        description: consultationDescription,
        consultationSetId: null,
        nomenclature: null,
        isVideoConsultation: agendaItem?.agendaType === 2 ? true : false,
        startTime: agendaItem?.startDate,
        endTime: agendaItem?.endDate,
        location: agendaItem?.location,
        costs: [],
      };
      axiosInstance
        .post(url, newConsultation)
        .then((response) => {
          const createdConsultationId = response.data;
          setConsultationId(createdConsultationId);
          /*
           * Update externalObjectId from agendaitem
           */
          const agendaItemUrl = urlPutAgendaitem(agendaItem.id);
          const newAgendaItem = {
            ...agendaItem,
            externalObjectId: createdConsultationId,
          };
          axiosInstance
            .put(agendaItemUrl, newAgendaItem)
            .then((response) => {
              setSucces(true);
              resolve();
            })
            .catch((error) => {
              console.error(`PUT ${url}`, error);
              reject(t("snackbars.failure.updateagendaitem"));
            });
        })
        .catch((error) => {
          console.error(`POST ${url}`, error);
          reject(t("snackbars.failure.createconsultation"));
        });
    });

  /**
   * Updates the values of the second step into the consultation
   * @returns {Promise}
   */
  const updateConsultationDetails = () =>
    new Promise((resolve, reject) => {
      if (consultation) {
        const updatedConsultation = consultation;
        // Description
        if (checkNotEmptyString(consultationDescription))
          updatedConsultation.description = consultationDescription;
        // Consultation set
        if (consultationType === "1")
          updatedConsultation.consultationSetId = selectedSet.id;
        // Nomenclature
        if (consultationNomenclature !== null) {
          if (checkNotEmptyString(consultationNomenclature)) {
            if (isConsultationNomenclature(consultationNomenclature)) {
              updatedConsultation.nomenclature = consultationNomenclature;
            } else {
              reject(t("snackbars.failure.invalidnomenclature"));
              setFailed(true);
            }
          }
        }
        /*
         * Update consultation by consultation id
         */
        const url = urlPutConsultation(consultationId);
        axiosInstance
          .put(url, updatedConsultation)
          .then((response) => {
            setSucces(true);
            resolve();
          })
          .catch((error) => {
            console.error(`PUT ${url}`, error);
            reject(t("snackbars.failure.updateconsultation"));
          });
      } else reject(t("snackbars.failure.updateconsultation"));
    });

  /**
   * Add a note to consultation
   * @returns {Promise}
   */
  const addNoteToConsultation = () =>
    new Promise((resolve, reject) => {
      if (noteContent) {
        if (checkNotEmptyString(noteContent)) {
          const url = urlPostNotesToConsultation(consultationId);
          axiosInstance
            .post(url, JSON.stringify(noteContent), {
              headers: { "Content-Type": "text/json" },
            })
            .then((response) => {
              setSucces(true);
              resolve();
            })
            .catch((error) => {
              console.error(`POST ${url}`, error);
              reject(t("snackbars.failure.addconsultationnote"));
            });
        }
        // If noteContent still contains an empty string it will notify the user
        else reject(t("fields.invalid"));
      }
      // If noteContent hasn't been touched it won't send a POST request
      else resolve();
    });

  /**
   * Steps for the SingleFlowWizard
   */
  const steps = [
    {
      id: 0,
      title: t("wizards.type.1.steps.0.title"),
      description: t("wizards.type.1.steps.0.description"),
      content: (
        <ConfirmPatientConsultationStep
          agendaItem={agendaItem}
          patient={patient}
        />
      ),
      modal: {
        title: t("wizards.type.1.steps.0.modal.title"),
        content: t("wizards.type.1.steps.0.modal.content"),
        positiveText: t("wizards.type.1.steps.0.modal.positiveText"),
        negativeText: t("wizards.type.1.steps.0.modal.negativeText"),
      },
      handleAfter: createConsultation,
    },
    {
      id: 1,
      title: t("wizards.type.1.steps.2.title"),
      description: t("wizards.type.1.steps.2.description"),
      content: (
        <ConsultationNotesStep
          noteContent={noteContent}
          setNoteContent={setNoteContent}
        />
      ),
      modal: {
        title: t("wizards.type.1.steps.1.modal.title"),
        content: t("wizards.type.1.steps.1.modal.content"),
        positiveText: t("wizards.type.1.steps.1.modal.positiveText"),
        negativeText: t("wizards.type.1.steps.1.modal.negativeText"),
      },
      handleAfter: addNoteToConsultation,
    },
    {
      id: 2,
      title: t("wizards.type.1.steps.1.title"),
      description: t("wizards.type.1.steps.1.description"),
      content: (
        <ConsultationDetailsStep
          patientId={patient?.id}
          setFailed={setFailed}
          description={consultationDescription}
          setDescription={setConsultationDescription}
          nomenclature={consultationNomenclature}
          setNomenclature={setConsultationNomenclature}
          type={consultationType}
          setType={setConsultationType}
          selectedConsultationSetId={selectedConsultationSetId}
          setSelectedConsultationSetId={setSelectedConsultationSetId}
        />
      ),
      modal: {
        title: t("wizards.type.1.steps.1.modal.title"),
        content: t("wizards.type.1.steps.1.modal.content"),
        positiveText: t("wizards.type.1.steps.1.modal.positiveText"),
        negativeText: t("wizards.type.1.steps.1.modal.negativeText"),
      },
      handleAfter: updateConsultationDetails,
    },
    {
      id: 3,
      title: t("wizards.type.1.steps.3.title"),
      description: t("wizards.type.1.steps.3.description"),
      content: (
        <ConsultationCostsStep
          consultationId={consultationId}
          patientId={patient?.id}
          setSucces={setSucces}
          setFailed={setFailed}
        />
      ),
    },
    {
      id: 4,
      title: t("wizards.type.1.steps.4.title"),
      description: t("wizards.type.1.steps.4.description"),
      content: (
        <ConsultationOverviewStep
          consultationId={consultationId}
          selectedSet={selectedSet}
          patient={patient}
          therapist={therapist}
          setFailed={setFailed}
        />
      ),
    },
  ];

  /**
   * This function will be executed when the wizard finalizes
   */
  const handleFinish = () =>
    history.push(`/consultations/${consultationId}/detail`);

  if (isLoadingPage) {
    return (
      <PageLoading
        header={<Header title={t("wizards.title")} />}
        isLoading={isLoadingPage}
        errorLoading={errorLoadingPage}
      />
    );
  }

  return (
    <>
      <Header
        title={t("wizards.type.1.title", {
          type: t(`type.${agendaItem?.agendaType}`),
        })}
      />
      <SingleFlowWizard steps={steps} handleFinish={handleFinish} wide={true} />
      <SuccesSnackbar
        open={!!succes}
        setOpen={setSucces}
        text={t("snackbars.succes")}
      />
      <FailureSnackbar
        open={!!failed}
        setOpen={setFailed}
        text={t("snackbars.failure")}
      />
    </>
  );
}
