import { DialogActions } from "@mui/material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * a comfirmation modal prompts the user to confirm an action, when clicking outside of the border, the action is declined
 * @returns {Dialog} dialog
 */
function ConfirmationModal({
  open,
  handleClose,
  command,
  title,
  content,
  positivetext,
  negativetext,
}) {
  const { t } = useTranslation("common");
  const handleNegative = () => {
    handleClose();
  };
  const handlePositive = () => {
    command();
    handleClose();
  };
  return (
    <Dialog open={open} keepMounted onClose={handleNegative}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePositive}>
          {positivetext ? positivetext : t("confirmation.agree")}
        </Button>
        <Button onClick={handleClose}>
          {negativetext ? negativetext : t("confirmation.disagree")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ConfirmationModal;
