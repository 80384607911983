import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { axiosInstance } from "../../../api/axios";
import {
  urlDeleteConsultationSetId,
  urlGetConsultationSets,
} from "../../../api/urls/consultationset";
import CompleteConsultationList from "../../consultations/consultationList/CompleteConsultationList";
import DetailButton from "../../shared/iconButtons/DetailButton";
import EditButton from "../../shared/iconButtons/EditButton";
import ConfirmationModal from "../../shared/menus/modals/ConfirmationModal";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import SuccesSnackbar from "../../shared/menus/snackbars/SuccesSnackbar";
import ContentLoadingProgress from "../../shared/progress/ContentLoadingProgress";
import CollapsingTable from "../../shared/tables/CollapsingTable";
import moment from "moment";
import {
  Column,
  SortableColumn,
  SortableFormattedColumn,
} from "../../shared/tables/Datatable";
import { applyFilters } from "./../../../helpers/filters/applyFilters";
import useAuth from "./../../../auth/useAuth";

/**
 * A consultation list has the responsability of getting the consultationSets from the api matching the filters handed down.
 * @returns a list of all consultations matching the parameters (paged, filtered) as consultation-list-items
 */
function ConsultationSetList({
  fromFilter,
  untilFilter,
  consultationsFilter,
  patientFilter,
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("STARTDATE");
  const { id } = useAuth();

  const [t] = useTranslation("consultations");
  const columns = [
    new Column(0, "patient", t("tables.columns.patient"), 80),
    new SortableColumn(1, "title", t("tables.columns.title"), 80),
    new SortableFormattedColumn(
      2,
      "startDate",
      t("tables.columns.startDate"),
      80,
      (value) => value.toLocaleDateString("nl-BE")
    ),
    new Column(3, "actions", "", undefined, 250),
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState();
  const [succes, setSucces] = useState(false);
  const [failed, setFailed] = useState();
  const [deletePrompted, setDeletePrompted] = useState(false);
  const history = useHistory();

  const onDelete = (value) => {
    const url = urlDeleteConsultationSetId(value);
    axiosInstance
      .delete(url)
      .then(setSucces(true))
      .catch((error) => {
        setFailed(`${t("snackbars.failure")} \n ${error.message}`);
      });
  };

  useEffect(() => {
    const calculateRows = (data) => {
      return data?.map((conSet) => {
        return {
          patient: (
            <Button
              onClick={() => history.push(`/patients/${conSet.patient.userId}`)}
            >
              {`${conSet.patient.lastName.toUpperCase()} ${
                conSet.patient.firstName
              }`}
            </Button>
          ),
          startDate: moment(new Date(conSet.startDate)).format("DD/MM/YYYY"),
          title: conSet.title,
          id: conSet.id,
          actions: (
            <>
              <DetailButton
                onClick={() =>
                  history.push(`/consultationset/${conSet.id}/detail`)
                }
                tooltip={t("tooltips.detail")}
              />
              <EditButton
                onClick={() =>
                  history.push(`/consultationset/${conSet.id}/edit`)
                }
                tooltip={t("tooltips.edit")}
              />
            </>
          ),
          collapsingContent: <CompleteConsultationList setFilter={conSet.id} />,
        };
      });
    };

    let url = urlGetConsultationSets(id);
    url += `?PageNumber=${page + 1}`;
    url += `&PageSize=${pageSize}`;
    if (orderBy !== undefined) url += `&OrderBy=${orderBy}`;
    if (order !== undefined)
      url += `&IsAscending=${order === "asc" ? true : false}`;
    url += applyFilters([
      { queryName: "patientId", filterObject: patientFilter },
      { queryName: "AmountOfConsultations", filterObject: consultationsFilter },
      { queryName: "StartDate", filterObject: fromFilter },
      { queryName: "EndDate", filterObject: untilFilter },
    ]);
    axiosInstance
      .get(url)
      .then(function (response) {
        // handle success
        setRows(calculateRows(response.data?.data?.consultationSets));
        setPage(response.data?.pageNumber - 1);
        setPageSize(response.data?.pageSize);
        setRowCount(response.data?.totalRecords);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
        setIsLoading(false);
      });
  }, [
    t,
    history,
    order,
    orderBy,
    page,
    pageSize,
    consultationsFilter,
    fromFilter,
    untilFilter,
    succes,
    patientFilter,
    id,
  ]);

  const handleSort = (row) => () => {
    let newOrderBy = columns[row].field.toUpperCase();
    if (orderBy === newOrderBy) {
      // Change order
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      // Change orderBy
      setOrderBy(newOrderBy);
    }
  };

  if (isLoading) {
    return <ContentLoadingProgress />;
  }

  return (
    <div
      className="card-list"
      style={{ marginBottom: 50, marginLeft: 20, marginRight: 20 }}
    >
      {rows?.length ? (
        <CollapsingTable
          rows={rows}
          columns={columns}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          setPage={setPage}
          setPageSize={setPageSize}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          createSortHandler={handleSort}
        />
      ) : (
        <Typography variant="caption" display="block" gutter={2}>
          {t("messages.noConsultationSets")}
        </Typography>
      )}
      <ConfirmationModal
        open={!!deletePrompted}
        handleClose={() => setDeletePrompted(false)}
        command={() => onDelete(deletePrompted)}
        content={t("confirmations.remove.consultationset")}
        title={t("labels.remove.consultationset")}
      />
      <SuccesSnackbar
        open={succes}
        setOpen={setSucces}
        text={t("snackbars.succes")}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </div>
  );
}
export default ConsultationSetList;
