import { Text, View } from "@react-pdf/renderer";
import { mapValutaType } from "../../../../helpers/enumMappers/index";
import { roundValutaNumber } from './../../../../helpers/numbers/roundValutaNumber';

/**
 * Lines Table section for the Invoice PDF
 * @param {{ styles: {}, additionalCosts: []}} props
 * @returns {View} Table section
 */
export default function InvoicePdfAdditionalLinesTableSection({
  styles,
  additionalCosts,
  invoicePdfAdditionalLinesTableSection,
  isCreditNote
}) {
  return (
    <>
      {additionalCosts && additionalCosts.length !== 0 && (
        <View style={[styles.row, styles.rowConsultationCosts]}>
          <Text style={styles.textText}>{invoicePdfAdditionalLinesTableSection}</Text>
          <View style={styles.consultation}>
            <View style={styles.tableCosts}>
              {additionalCosts.map((cost) => {
                return (
                  <View style={styles.tableRowCosts} key={cost.id}>
                    <Text style={styles.textText}>{cost.info}</Text>
                    <Text style={styles.textText}>
                      {isCreditNote && "- "}
                      {mapValutaType(cost.costDto.valuta)}
                      {roundValutaNumber(Number(cost.costDto.costAmount))}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      )}
    </>
  );
}
