import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

/**
 * Page Loading Linear Progress
 * @link https://mui.com/components/progress/#linear
 * @link https://codesandbox.io/s/6b8hm?file=/demo.js orignal file
 * @returns {LinearProgress}
 */
export default function PageLoadingProgress() {
  const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    margin: "1em",
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "primary" : "#1a90ff",
    },
  }));
  return <CustomLinearProgress />;
}
