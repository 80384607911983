import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Container,
  CssBaseline,
  Divider,
  IconButton,
  List,
  Toolbar,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import useAuth from "../auth/useAuth";
import { AppBar } from "../components/dashboard/AppBar";
import { Copyright } from "../components/dashboard/Copyright";
import { Drawer } from "../components/dashboard/Drawer";
import {
  MainListItems,
  secondaryListItems,
} from "../components/dashboard/ListItems";
import { Routes } from "../routes/Routes.jsx";
import theme from "../theme";
import { useTranslation } from 'react-i18next';

function DashboardContent() {
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="absolute" open={open} sx={{ zIndex: 5 }}>
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
                zIndex: 5,
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              />
              <IconButton color="inherit">
                <NavLink
                  exact
                  to={"/settings"}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <SettingsIcon />
                </NavLink>
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <MainListItems open={open} />
            <Divider />
            <List>{secondaryListItems}</List>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey.light
                  : theme.palette.grey.dark,
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
              <Routes />
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  const { token, login, lang } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    login();
  });

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang])

  return token ? <DashboardContent /> : <p>please log in</p>; //TODO: prettify
}
