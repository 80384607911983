import { AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import { Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../../../api/axios";
import MultipleUserPickerByName from "../../shared/controlled-inputs/MultipleUserPickerByName";

function TDAppointmentForm() {
  const { t } = useTranslation("scheduler");
  const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
    const [users, setUsers] = useState([]);
    const [warning, setWarning] = useState("");

    useEffect(() => {
      let isMounted = true;
      let userRequests = [];
      if (appointmentData.userIds)
        appointmentData.userIds.forEach((a) =>
          userRequests.push(axiosInstance.get(`/v2/patients/${a}/info`))
        );
      if (isMounted)
        axios.all(userRequests).then((calls) =>
          setUsers(
            calls.map((call) => {
              return {
                id: call.data.userProfile.id,
                userId: call.data.userProfile.id,
                label: `${call.data.userProfile.firstName} ${call.data.userProfile.lastName}`,
              };
            })
          )
        );
      return () => {
        isMounted = false;
      };
    }, [appointmentData.userIds]);
    const onIdListChange = (nextValue) => {
      //if(appointmentData.)
      setWarning("");
      setUsers(nextValue);
      onFieldChange({ userIds: nextValue.map((user) => user.userId) });
    };

    const onAddressChange = (nextValue) => {
      onFieldChange({ address: nextValue });
    };

    return (
      <AppointmentForm.BasicLayout
        appointmentData={appointmentData}
        onFieldChange={onFieldChange}
        {...restProps}
      >
        <AppointmentForm.Label text={t("form.titles.adress")} type="title" />
        <AppointmentForm.TextEditor
          value={appointmentData.address}
          onValueChange={(i) => onAddressChange(i)}
          placeholder={t("form.placeholders.adress")}
        />
        <AppointmentForm.Label text={t("form.titles.users")} type="title" />
        <MultipleUserPickerByName setUsers={onIdListChange} users={users} />
        {warning && (
          <Typography
            variant="caption"
            color="error"
            display="block"
            gutterBottom
          >
            {warning}
          </Typography>
        )}
      </AppointmentForm.BasicLayout>
    );
  };
  const BooleanEditor = (props) => {
    return (
      <AppointmentForm.BooleanEditor
        {...props}
        readOnly={props.label === "Repeat"}
      />
    );
  };

  const DateEditor = ({ excludeTime, ...restProps }) => {
    const dateFormat = excludeTime ? "DD/MM/YYYY" : "DD/MM/YYYY HH:mm";
    console.log();
    document
      .getElementsByClassName(
        "MuiFormControlLabel-root Mui-disabled MuiFormControlLabel-labelPlacementEnd css-fr051l-MuiFormControlLabel-root"
      )[0]
      ?.style.setProperty('visibility', 'hidden');
    return (
      <>
        <AppointmentForm.DateEditor
          {...restProps}
          excludeTime={excludeTime}
          inputFormat={dateFormat}
          ampm={false}
        />
      </>
    );
  };
  const RecurrenceEditor = ({
    appointmentData,
    onFieldChange,
    ...restProps
  }) => {
    const onWeekdayChange = (nextValue) => {
      onFieldChange({ onWeekends: nextValue });
    };
    return (
      <AppointmentForm.RecurrenceLayout
        appointmentData={appointmentData}
        onFieldChange={onFieldChange}
        {...restProps}
      >
        <AppointmentForm.Label
          text={t("form.fields.onWeekends")}
          type="ordinaryLabel"
        />
        <AppointmentForm.Select
          value={appointmentData.onWeekends ?? false}
          onValueChange={(input) => onWeekdayChange(input)}
          availableOptions={[
            { id: false, text: t("form.buttons.decline") },
            { id: true, text: t("form.buttons.confirm") },
          ]}
          placeholder={t("form.placeholder.userId")}
        />
      </AppointmentForm.RecurrenceLayout>
    );
  };

  return (
    <AppointmentForm
      basicLayoutComponent={BasicLayout}
      dateEditorComponent={DateEditor}
      recurrenceLayoutComponent={RecurrenceEditor}
      booleanEditorComponent={BooleanEditor}
    />
  );
}
export default TDAppointmentForm;
