import { useParams } from "react-router";
import InvoiceExportOverview from "./../invoices/invoiceExport/InvoiceExportOverview";
import CollectionStateExportOverview from "../invoices/invoiceExport/CollectionStateExportOverview";

export default function ExportOverview({ type }) {
  const { invoiceId, collectionStateId } = useParams();

  if (type) {
    return (
      <>
        {type === 1 && invoiceId && <InvoiceExportOverview />}
        {type === 2 && invoiceId && <CollectionStateExportOverview />}
      </>
    );
  }

  return <></>;
}
