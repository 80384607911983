import { Autocomplete, TextField } from "@mui/material";
import { axiosInstance } from "../../../api/axios";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import throttle from "lodash.throttle";
import useAuth from "./../../../auth/useAuth";

function MultipleUserPickerByName({
  label,
  setUsers,
  users,
  errors,
  type = 2,
  throttleTime = 1000,
  minChars = 2,
}) {
  const [options, setOptions] = useState([]);
  const { t } = useTranslation("common");
  const { id } = useAuth();
  const [isDirtyInputField, setIsDirtyInputField] = useState(false);
  const mapOptions = (res) => {
    return res.map((op) => {
      return { ...op, ...{ label: `${op.firstName} ${op.lastName}` } };
    });
  };
  const fetchUsers = useCallback(
    (input) => {
      if (input.length >= minChars) {
        axiosInstance
          .get(`/v2/therapists/${id}/patients/search?name=${input}`)
          .then((res) => {
            setOptions(mapOptions(res.data.users));
          });
      } else setOptions([]);
    },
    [minChars, id]
  );

  const throttledFetch = useMemo(
    () => throttle((input) => fetchUsers(input), throttleTime),
    [fetchUsers, throttleTime]
  );

  const handleInputChange = (_event, value) => {
    throttledFetch(value);
    if (value === "") {
      setIsDirtyInputField(false);
    } else {
      setIsDirtyInputField(true);
    }
  };
  const handleChange = (_event, newValue) => setUsers(newValue);

  return (
    <div className="App">
      <Autocomplete
        disablePortal
        autoHighlight
        autoSelect
        isOptionEqualToValue={(option, value) => option.userId === value.userId}
        multiple
        value={users}
        onChange={handleChange}
        noOptionsText={
          isDirtyInputField ? t("users.noresult") : t("users.noOptions")
        }
        options={options ?? []}
        fullWidth
        onInputChange={handleInputChange}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField fullWidth {...params} label={label} error={!!errors} />
        )}
      />
    </div>
  );
}
export default MultipleUserPickerByName;
