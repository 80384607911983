/**
 * Post a note to an consultation
 * @param {number} patientId
 * @returns {string} url
 */
export const urlPostNotesToConsultation = (consultationId) =>
  `v2/consultations/${consultationId}/notes`;

/**
 * Create a consultation
 * @param {}
 * @returns {string} url
 */
export const urlPostConsultation = () => `v2/consultations`;

/**
 * Post cost to an consultation
 * @param {number} consultationId
 * @returns {string} url
 */
export const urlPostCostToConsultation = (consultationId) =>
  `v2/consultations/${consultationId}/costs`;
