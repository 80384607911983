import { Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";

function Setting({ name, value, setValue, description, type }) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Stack spacing={0}>
      <Box
        direction="row"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        <div>{name}</div>
        <Stack spacing={5} direction="row">
          <InfoIcon
            sx={{ fontSize: "medium" }}
            color="primary"
            onClick={() => setCollapsed(!collapsed)}
          />
          <TextField
            variant="standard"
            type={type}
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
        </Stack>
      </Box>
      {description && !collapsed && (
        <Typography variant="caption" display="block" gutterBottom>
          {description}
        </Typography>
      )}
    </Stack>
  );
}
export default Setting;
