import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Returns Check Icon when bool is true and unchecked when false
 * @param {boolean} bool
 * @returns {SvgIcon} Material Icon
 */
export function checkOrUnCheckIcon(bool) {
  return bool ? (
    <CheckIcon style={{ color: "green" }} />
  ) : (
    <CloseIcon style={{ color: "red" }} />
  );
}
