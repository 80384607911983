import { useState, useEffect } from "react";
import axios from "axios";
import { axiosInstance } from "../../../api/axios";
import ContentLoadingProgress from "./../../shared/progress/ContentLoadingProgress";
import { useTranslation } from "react-i18next";
import CheckedTable, { CheckedColumn } from "../../shared/tables/CheckedTable";
import { urlGetIndividualCostsPatientIdTherapistId } from "./../../../api/urls/cost";
import CreateIndividualCostModal from "./CreateIndividualCostModal";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FailureSnackbar from "./../../shared/menus/snackbars/FailureSnackbar";
import SuccesSnackbar from "./../../shared/menus/snackbars/SuccesSnackbar";

export default function IndividualCostsStep({
  therapistId,
  patientId,
  selected,
  setSelected,
}) {
  const [t] = useTranslation("costs");
  const [isLoading, setIsLoading] = useState(true);
  const [succes, setSucces] = useState();
  const [failed, setFailed] = useState();

  const [individualCosts, setIndividualCosts] = useState([]);
  const [changed, setChanged] = useState();
  const updateIndividualCosts = () => setChanged(!changed);

  const [addIndividualCostPrompted, setAddIndividualCostPrompted] =
    useState(false);

  /**
   * The columns for the costs table
   * @type {CheckedColumn[]} CheckedColumns
   */
  const columns = [
    new CheckedColumn(0, "title", t("tables.columns.title"), 80),
    new CheckedColumn(1, "cost", t("tables.columns.cost"), 80),
  ];

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    /**
     * Get individual costs for patient & loggedin therapist
     */
    const url = urlGetIndividualCostsPatientIdTherapistId(
      patientId,
      therapistId
    );
    axiosInstance
      .get(url, {
        setsRequestCancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const costs = response.data.costs;
        const parsedFields = costs.map((c) => {
          return {
            id: c.id,
            title: c.title,
            cost: c.costAmount,
          };
        });
        setIndividualCosts(parsedFields);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setFailed(t("snackbars.failure.getindividualcosts"));
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [therapistId, patientId, setFailed, t, selected, changed]);

  /**
   * Handle succes.
   * Add new individualcost id to the selected and updates the list
   * @param {number} newIndividualCostId 
   */
  const handleSucces = (newIndividualCostId) => {
    const newSelected = selected;
    newSelected.add(newIndividualCostId);
    setSelected(newSelected);
    updateIndividualCosts();
    setSucces(true);
    closeModal();
  };

  /**
   * Open modal
   */
  const openModal = () => setAddIndividualCostPrompted(true);

  /**
   * Close modal
   */
  const closeModal = () => setAddIndividualCostPrompted(false);

  if (isLoading) {
    return <ContentLoadingProgress />;
  }

  return (
    <>
      {!addIndividualCostPrompted && (
        <Button variant="outlined" onClick={openModal} startIcon={<AddIcon />}>
          {t("actions.addindividualcost")}
        </Button>
      )}
      <CreateIndividualCostModal
        open={addIndividualCostPrompted}
        close={closeModal}
        patientId={patientId}
        therapistId={therapistId}
        handleSucces={handleSucces}
        setFailed={setFailed}
      />
      {individualCosts && (
        <>
          {individualCosts.length === 0 ? (
            <p>{t("tables.noindividualcostsfortherapistandpatient")}</p>
          ) : (
            <>
              <CheckedTable
                rows={individualCosts}
                columns={columns}
                selected={selected}
                setSelected={setSelected}
                tableSizeSmall={true}
                tableCellAlign="left"
              />
            </>
          )}
        </>
      )}
      <SuccesSnackbar
        open={!!succes}
        setOpen={setSucces}
        text={t("snackbars.succes")}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
