import { Card, CardContent, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { axiosInstance, handleAxiosError } from "../../api/axios";
import { urlGetPatientId } from "./../../api/urls/patient";
import PageLoading from "./../shared/loading/PageLoading";
import CompleteConsultationList from "../consultations/consultationList/CompleteConsultationList";
import ConsultationSetList from "../consultationsets/consultationSetList/ConsultationSetList";
import PatientButtonBar from "./PatientButtonBar";

function PatientDetail() {
  const { id } = useParams();
  const [t] = useTranslation("patients");

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();

  const [patient, setPatient] = useState();

  useEffect(() => {
    const url = urlGetPatientId(id);
    axiosInstance
      .get(url)
      .then((response) => {
        setPatient(response.data);
        setIsLoadingPage(false);
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: "error.request.get.patient",
          axiosError: handleAxiosError(error),
        })
      );
  }, [id]);

  if (isLoadingPage) {
    return (
      <PageLoading isLoading={isLoadingPage} errorLoading={errorLoadingPage} />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Card
        variant="outlined"
        className="card-list__item--wider card-list__item--normal"
        data-testid="consultation-set"
        sx={{ minWidth: "50em", width: "fit-content", position: "relative" }}
      >
        <CardContent sx={{ overflowY: "auto" }}>
          <div style={{ marginRight: 12 }}>
            <PatientButtonBar id={patient?.id} />
          </div>

          <Typography
            sx={{ mt: 2, mb: 2, ml: 2.5 }}
            variant="h5"
            display="block"
            gutter
            gutterBottom
          >
            {t("titles.consultationSets", {
              name:
                patient?.userProfile?.firstName +
                " " +
                patient?.userProfile.lastName,
            })}
          </Typography>
          <ConsultationSetList patientFilter={id} />

          <Divider />
          <Typography
            sx={{ mt: 2, mb: 2, ml: 2.5 }}
            variant="h5"
            display="block"
            gutter
            gutterBottom
          >
            {t("titles.consultations", {
              name:
                patient?.userProfile?.firstName +
                " " +
                patient?.userProfile.lastName,
            })}
          </Typography>
          <CompleteConsultationList patientFilter={id} />
        </CardContent>
      </Card>
    </div>
  );
}
export default PatientDetail;
