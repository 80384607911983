import { Stack } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import SuccesSnackbar from "../../shared/menus/snackbars/SuccesSnackbar";
import ConsultationSetFilterBar from "./ConsultationSetFilterBar";
import ConsultationSetList from "./ConsultationSetList";

function FilteredConsultationSetList({ showFilters, stylesFilters }) {
  const [succes, setSucces] = useState(false);
  const [failed, setFailed] = useState();
  const { t } = useTranslation("consultations");

  const [consultationsFilter, setConsultationsFilter] = useState();
  const [patientFilter, setPatientFilter] = useState();
  const [fromFilter, setFromFilter] = useState();
  const [untilFilter, setUntilFilter] = useState();

  return (
    <div className="card-list">
      <Stack spacing={2}>
        {showFilters === true && (
          <ConsultationSetFilterBar
            styles={stylesFilters}
            setConsultationsFilter={setConsultationsFilter}
            setPatientFilter={setPatientFilter}
            setDateFilter={(values) => {
              setFromFilter(
                values[0] ? moment(values[0]).format("YYYY-MM-DD") : undefined
              );
              setUntilFilter(
                values[1] ? moment(values[1]).format("YYYY-MM-DD") : undefined
              );
            }}
          />
        )}
        <ConsultationSetList
          consultationsFilter={consultationsFilter}
          patientFilter={patientFilter}
          fromFilter={fromFilter}
          untilFilter={untilFilter}
        />
      </Stack>
      <SuccesSnackbar
        open={!!succes}
        setOpen={setSucces}
        text={t("snackbars.succes")}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </div>
  );
}
export default FilteredConsultationSetList;
