import moment from "moment";

/**
 * Difference between 2 dates
 * @param {Date} date1
 * @param {Date} date2
 * @returns amount of days
 */
export function daysBetweenTwoDates(date1, date2) {
  const date1Moment = moment(date1);
  const date2Moment = moment(date2);
  const diff = moment.duration(date1Moment.diff(date2Moment)).asDays();
  return Math.floor(diff);
}
