import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { axiosInstance, handleAxiosError } from "../../api/axios";
import PageLoading from "./../shared/loading/PageLoading";
import { urlGetTherapistId } from "./../../api/urls/therapist";
import CompleteConsultationList from "../consultations/consultationList/CompleteConsultationList";
import TherapistButtonBar from "./TherapistButtonBar";

function TherapistDetail() {
  const { id } = useParams();
  const [t] = useTranslation("therapists");

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();

  const [therapist, setTherapist] = useState();

  useEffect(() => {
    const url = urlGetTherapistId(id);
    axiosInstance
      .get(url)
      .then((response) => {
        setTherapist(response.data);
        setIsLoadingPage(false);
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: "error.request.get.therapist",
          axiosError: handleAxiosError(error),
        })
      );
  }, [id]);

  if (isLoadingPage) {
    return (
      <PageLoading isLoading={isLoadingPage} errorLoading={errorLoadingPage} />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Card
        variant="outlined"
        className="card-list__item--wider card-list__item--normal"
        data-testid="consultation-set"
        sx={{ minWidth: "50em", width: "fit-content", position: "relative" }}
      >
        <CardContent sx={{ overflowY: "auto" }}>
          <TherapistButtonBar />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "end",
              justifyContent: "space-around",
            }}
          >
            <Box sx={{ display: "block" }}>
              <CardHeader
                title={`${therapist?.userProfile?.firstName ?? ""} ${
                  therapist?.userProfile.lastName ?? "-"
                }`}
              />
            </Box>
            <Box
              sx={{
                display: "block",
                width: "fit-content",
                position: "relative",
              }}
            ></Box>
          </Box>

          <Typography variant="h5" display="block" gutter gutterBottom>
            {t("titles.consultations")}
          </Typography>
          <CompleteConsultationList therapistFilter={id} />
        </CardContent>
      </Card>
    </div>
  );
}
export default TherapistDetail;
