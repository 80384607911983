import { validUrlFilter } from "./validUrlFilter";

/**
 * Apply's filters to the url
 *
 * @param {Array<{queryName: string, filterObject: any}>} arrayCustomFilterObject
 * @param {boolean?} beginWithQuestionMark
 * @returns {string} queryParams
 */
export const applyFilters = (
  arrayCustomFilterObject,
  beginWithQuestionMark = false
) => {
  let queryParams = "";
  let count = 0;
  for (const filter of arrayCustomFilterObject) {
    if (validUrlFilter(filter.filterObject)) {
      let divider = "&";
      if (beginWithQuestionMark === true && count === 0) divider = "?";
      queryParams += `${divider}${filter.queryName}=${filter.filterObject}`;
      count += 1;
    }
  }
  return queryParams;
};
