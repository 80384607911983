import { Box } from "@mui/system";
import React from "react";

function TabPanel({ selectedTab, index, content }) {
  return (
    <div
      role="tabpanel"
      style={{ width: "100%" }}
      hidden={selectedTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {selectedTab === index && (
        <Box
          sx={{
            p: 3,
            width: "100%",
          }}
        >
          {content}
        </Box>
      )}
    </div>
  );
}
export default TabPanel;
