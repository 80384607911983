import { FormHelperText, MenuItem, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const FormDropdown = ({
  name,
  control,
  label,
  options,
  errors,
  displayAsRequired = false,
  margin,
}) => {
  const generateSelectOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem
          key={option.id}
          value={option.value}
          disabled={option.id === -1}
        >
          {t(`${option.label}`, { ns: "translations" })}
        </MenuItem>
      );
    });
  };

  const { t } = useTranslation(["forms", "translations"]);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <>
          <TextField
            sx={{ minWidth: 120 }}
            select
            label={`${label}${displayAsRequired ? " *" : ""}`}
            margin={margin ?? "normal"}
            variant="outlined"
            onChange={onChange}
            value={value ?? ""}
            error={!!errors}
          >
            {generateSelectOptions()}
          </TextField>

          {!!errors && errors.message && (
            <FormHelperText error>{t(errors.message)}</FormHelperText>
          )}
        </>
      )}
    />
  );
};
