import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import { Room } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import PageviewIcon from "@mui/icons-material/Pageview";
import NotesIcon from "@mui/icons-material/Notes";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useHistory } from "react-router";
import { agendaTypes } from "../../../constants/scheduler-constants";

function TDAppointmentTooltip({ style, action, appointmentData }) {
  const { t } = useTranslation("scheduler");
  const history = useHistory();
  const Header = withStyles(style, { name: "Header" })(
    ({ children, appointmentData, classes, ...restProps }) => (
      <AppointmentTooltip.Header
        {...restProps}
        appointmentData={appointmentData}
      >
        {!appointmentData.externalObjectId &&
          (appointmentData?.agendaType === 1 || // check wether consultation
            appointmentData?.agendaType === 2) && (
            <Tooltip
              title={
                t("tooltips.start") +
                " " +
                agendaTypes.find(
                  (at) => at.value === appointmentData.agendaType
                )?.text
              }
            >
              <IconButton
                /* eslint-disable-next-line no-alert */
                onClick={() =>
                  history.push(`scheduler/${appointmentData.id}/new`)
                }
                className={classes.commandButton}
                disabled={
                  !(
                    (appointmentData?.agendaType === 1 || // check wether consultation
                      appointmentData?.agendaType === 2) &&
                    appointmentData.userIds && // check if users
                    appointmentData?.userIds?.length === 1
                  ) &&
                  !(
                    appointmentData.agendaType !== 1 &&
                    appointmentData.agendaType !== 2
                  )
                }
              >
                <AddIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}

        {appointmentData.externalObjectId && (
          <Tooltip
            title={
              t("tooltips.view") +
              " " +
              agendaTypes.find((at) => at.value === appointmentData.agendaType)
                ?.text
            }
          >
            <IconButton
              /* eslint-disable-next-line no-alert */
              onClick={() =>
                history.push(
                  `/consultations/${appointmentData.externalObjectId}/detail`
                )
              }
              className={classes.commandButton}
            >
              <PageviewIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        )}
      </AppointmentTooltip.Header>
    )
  );
  const Content = withStyles(style, { name: "Content" })(
    ({ children, appointmentData, classes, ...restProps }) => (
      <AppointmentTooltip.Content
        {...restProps}
        appointmentData={appointmentData}
      >
        <Grid container alignItems="center">
          {!!appointmentData.address && (
            <>
              <Grid item xs={2} className={classes.textCenter}>
                <Room className={classes.icon} />
              </Grid>
              <Grid item xs={10}>
                <span>{appointmentData.address}</span>
              </Grid>
            </>
          )}
          {appointmentData.notes && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={2} className={classes.textCenter}>
                <NotesIcon className={classes.icon} />
              </Grid>
              <Grid item xs={10}>
                <span>{appointmentData.notes}</span>
              </Grid>
            </>
          )}
          {(appointmentData?.agendaType === 1 ||
            appointmentData?.agendaType === 2) &&
            (!appointmentData.userIds ||
              appointmentData?.userIds?.length === 0) && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={2} className={classes.textCenter}>
                  <ErrorOutlineIcon className={classes.icon} />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="caption"
                    color="error"
                    display="block"
                    gutterBottom
                  >
                    {t("warnings.noPatients")}
                  </Typography>
                </Grid>
              </>
            )}
        </Grid>
      </AppointmentTooltip.Content>
    )
  );
  const CommandButton = withStyles(style, { name: "CommandButton" })(
    ({ classes, ...restProps }) => (
      <AppointmentTooltip.CommandButton
        {...restProps}
        className={classes.commandButton}
      />
    )
  );
  return (
    <AppointmentTooltip
      headerComponent={Header}
      contentComponent={Content}
      commandButtonComponent={CommandButton}
      showOpenButton
      showDeleteButton
      showCloseButton
    />
  );
}
export default TDAppointmentTooltip;
