import axios from "axios";

/**
 * returns an axios instance with the development baseurl entered.
 * adding authentication can be done here.
 * @returns {AxiosInstance} axiosInstance
 */
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  headers: {
    Authorization: "Bearer " + localStorage.token,
    // Here we take the session id from the application insights and send it with every request in the header (x-ai-session-id)
    // When there is no ai_session id, it will send an empty header
    "x-ai-session-id": document.cookie
      ? document.cookie.split("; ").find((row) => row.startsWith("ai_session="))
        ? document.cookie
            .split("; ")
            .find((row) => row.startsWith("ai_session="))
            .split("=")[1]
            .split("|")[0]
        : "" || ""
      : "",
  },
});
