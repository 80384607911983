/**
 * This will return true if the invoice can be exported.
 * @param {Invoice} invoice
 * @returns {boolean}
 */
export function isExportableInvoice(invoice) {
  /* The invoice can only be exported when isProcessed is false */
  if (invoice.isProcessed === false) {
    return true;
  }
  return false;
}
