import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { isAppInsigthsEnabled } from "./isAppInsightsEnabled";

const browserHistory = createBrowserHistory({ basename: "" });

const reactPlugin = new ReactPlugin();

const instrumentationKey =
  process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY || "";

const ai = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

if (isAppInsigthsEnabled()) ai.loadAppInsights();

ReactPlugin.prototype.trackEvent = function (event, customProperties) {
  this._analyticsPlugin.trackEvent(event, customProperties);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
export { reactPlugin };
