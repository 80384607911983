import AccessibilityIcon from "@mui/icons-material/Accessibility";
import BusinessIcon from "@mui/icons-material/Business";
// Icons
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PersonIcon from "@mui/icons-material/Person";
import ShortTextIcon from "@mui/icons-material/ShortText";
import { Box, CardHeader, Divider, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { axiosInstance, handleAxiosError } from "../../api/axios";
import { mapLocationType } from "../../helpers/enumMappers";
import { parsePatientResponse } from "../../helpers/patients/parsers";
import { parseTherapistResponse } from "../../helpers/therapists/parsers";
import "../../scss/main.scss";
import SingleSetWithCons from "../consultationsets/consultationSetList/SingleSetTableWithCons";
import CostTable from "../costs/CostTable";
import Detail from "../shared/default/Detail";
import FailureSnackbar from "../shared/menus/snackbars/FailureSnackbar";
import { IconTable } from "../shared/tables/iconTable";
import { IconTableRow } from "../shared/tables/iconTable/IconTableRow";
import { urlGetConsultationById } from "./../../api/urls/consultation/get";
import PageLoading from "./../shared/loading/PageLoading";
import ConsultationButtonBar from "./ConsultationButtonBar";
import ConsultationNote from "./Note";

/**
 * A consultation list item will show all features a consultation should show in an (expanded list view), it also holds the responsablility of editing and deleting a consultation when prompted by the users if requirements are met
 * @param {*} id
 * @returns
 */
function ConsultationDetail() {
  const { id } = useParams();
  const [t] = useTranslation(["consultations", "translations"]);
  const [patient, setPatient] = useState();
  const [therapist, setTherapist] = useState();
  const [failed, setFailed] = useState(false);

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();

  const [consultation, setConsultation] = useState({});
  const options = { year: "numeric", month: "long", day: "numeric" };

  useEffect(() => {
    const url = urlGetConsultationById(id);
    axiosInstance
      .get(url)
      .then((response) => {
        setConsultation(response.data);
        setIsLoadingPage(false);
        let getPatientQuery = axiosInstance.get(
          `/v2/patients/${response.data.patientId}/info`
        );
        let getTherapistQuery = axiosInstance.get(
          `/v2/therapists/${response.data.therapistId}`
        );
        axios
          .all([getPatientQuery, getTherapistQuery])
          .then(
            axios.spread((patient, therapist) => {
              setPatient(
                parsePatientResponse(patient, response.data.patientId).patient
              );
              setTherapist(
                parseTherapistResponse(therapist, response.data.therapistId)
                  .therapist
              );
            })
          )
          .catch(setFailed);
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: "error.request.get.consultation",
          axiosError: handleAxiosError(error),
        })
      );
  }, [id]);

  if (isLoadingPage) {
    return (
      <PageLoading isLoading={isLoadingPage} errorLoading={errorLoadingPage} />
    );
  }

  const rows = [
    new IconTableRow(
      0,
      <DateRangeIcon />,
      t("fields.consultation.startTime"),
      moment(new Date(consultation?.startTime)).format("DD/MM/YYYY HH:mm")
    ),
    new IconTableRow(
      1,
      <EventAvailableIcon />,
      t("fields.consultation.endTime"),
      moment(new Date(consultation?.endTime)).format("DD/MM/YYYY HH:mm")
    ),
    new IconTableRow(
      2,
      <ShortTextIcon />,
      t("fields.consultation.description"),
      consultation?.description ? consultation?.description : ""
    ),
    new IconTableRow(
      3,
      <PersonIcon />,
      t("fields.consultation.therapist"),
      therapist?.label
    ),
    new IconTableRow(
      4,
      <AccessibilityIcon />,
      t("fields.consultation.patient"),
      patient?.label
    ),
    new IconTableRow(
      6,
      <BusinessIcon />,
      t("fields.consultation.location"),
      t(`${mapLocationType(consultation?.location)}`, { ns: "translations" })
    ),
  ];

  return (
    <Detail>
      <ConsultationButtonBar
        id={consultation.id}
        setFailed={setFailed}
        deletable={!consultation.costs.length}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "inherit",
        }}
      >
        <Box sx={{ display: "block", width: "30%" }}>
          <CardHeader title={consultation?.description} sx={{ p: 5 }} />
        </Box>
        <Box
          sx={{
            display: "block",
            postion: "relative",
            textAlign: "right",
            width: "70%",
          }}
        >
          <CardHeader
            subheader={` ${new Date(consultation?.startTime).toLocaleTimeString(
              "en-GB",
              options
            )} (${Math.floor(
              (new Date(consultation?.endTime) -
                new Date(consultation?.startTime)) /
                60000
            )} minutes) `}
          />
        </Box>
      </Box>
      {patient && therapist && <IconTable rows={rows} size="small" />}
      <Divider />
      <Typography variant="h5" display="block" gutter={2} gutterBottom>
        {t("titles.costs")}
      </Typography>
      {consultation.costs?.length > 0 ? (
        <>
          <CostTable costs={consultation.costs} />
        </>
      ) : (
        <Typography variant="caption" display="block" gutter={2} gutterBottom>
          {t("messages.noCosts")}
        </Typography>
      )}
      <Divider />
      <Typography variant="h5" display="block" gutter={2} gutterBottom>
        {t("titles.notes")}
      </Typography>
      {consultation.id !== undefined && (
        <>
          <ConsultationNote consultationId={consultation?.id} />
        </>
      )}
      <Divider />
      <Typography variant="h5" display="block" gutter={2} gutterBottom>
        {t("titles.consultationSet")}
      </Typography>
      {consultation.consultationSetId ? (
        <>
          <SingleSetWithCons setId={consultation.consultationSetId} />
        </>
      ) : (
        <Typography variant="caption" display="block" gutter={2}>
          {t("messages.noSet")}
        </Typography>
      )}

      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </Detail>
  );
}
export default ConsultationDetail;
