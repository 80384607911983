import { FormHelperText } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import UserPickerByName from "../../controlled-inputs/userPickerByName";

export const FormUserPicker = ({
  name,
  control,
  label,
  errors,
  margin,
  sx,
  displayAsRequired = false,
  disabled = false,
}) => {
  const { t } = useTranslation("forms");
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <>
            <UserPickerByName
              margin={margin ?? "normal"}
              variant="outlined"
              setUser={onChange}
              user={value}
              label={`${label}${displayAsRequired ? " *" : ""}`}
              errors={!!errors}
              sx={sx}
              disabled={disabled}
            />
            {!!errors && errors.label && errors.label.message && (
              <FormHelperText error>{t(errors.label.message)}</FormHelperText>
            )}
          </>
        )}
      />
    </>
  );
};
