import AccessibilityIcon from "@mui/icons-material/Accessibility";
// Icons
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Box, CardHeader, Divider, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { axiosInstance, handleAxiosError } from "../../api/axios";
import { parsePatientResponse } from "../../helpers/patients/parsers";
import CompleteConsultationList from "../consultations/consultationList/CompleteConsultationList";
import Note from "../consultationsets/Note";
import Detail from "../shared/default/Detail";
import { IconTable, IconTableRow } from "../shared/tables/iconTable";
import { urlGetConsultationSetId } from "./../../api/urls/consultationset";
import PageLoading from "./../shared/loading/PageLoading";
import ConsultationSetButtonBar from "./ConsultationSetButtonBar";
import FailureSnackbar from "../shared/menus/snackbars/FailureSnackbar";

/**
 * A consultation set holds a list of consultations in that set, as well as details regarding the amount of consultations and payback status.
 * @returns
 */
function ConsultationSetDetail() {
  const { id } = useParams();
  const [t] = useTranslation("consultations");
  const [patient, setPatient] = useState();

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();
  const [failed, setFailed] = useState(false);

  const [set, setSet] = useState();

  useEffect(() => {
    const url = urlGetConsultationSetId(id);
    axiosInstance
      .get(url)
      .then((response) => {
        setSet(response.data);
        let getPatientQuery = axiosInstance.get(
          `/v2/patients/${response.data.patientId}/info`
        );
        axios
          .all([getPatientQuery])
          .then(
            axios.spread((patient) => {
              setPatient(
                parsePatientResponse(patient, response.data.patientId).patient
              );
              setIsLoadingPage(false);
            })
          )
          .catch((error) =>
            setErrorLoadingPage({
              title: "error.request.get.consultationset",
              axiosError: handleAxiosError(error),
            })
          );
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: "error.request.get.consultationset",
          axiosError: handleAxiosError(error),
        })
      );
  }, [id]);

  if (isLoadingPage) {
    return (
      <PageLoading isLoading={isLoadingPage} errorLoading={errorLoadingPage} />
    );
  }
  const rows = [
    new IconTableRow(
      0,
      <DateRangeIcon />,
      t("fields.consultation.startTime"),
      set && set.startDate && new Date(set?.startDate).toLocaleDateString()
    ),
    new IconTableRow(
      1,
      <EventAvailableIcon />,
      t("fields.consultation.endTime"),
      set && set.endDate
        ? new Date(set?.endDate).toLocaleDateString()
        : t("messages.noEnddate")
    ),
    new IconTableRow(
      2,
      <AccessibilityIcon />,
      t("fields.consultation.patient"),
      patient?.label
    ),
    new IconTableRow(
      3,
      <DoneAllIcon />,
      t("fields.consultation.amountOfConsultations"),
      set?.amountOfConsultations
    ),
  ];

  return (
    <Detail>
      <ConsultationSetButtonBar
        id={set.id}
        setFailed={setFailed}
        deletable={set.consultationDtos.length === 0}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ display: "block" }}>
          <CardHeader title={set?.title} />
        </Box>
      </Box>
      {patient && <IconTable rows={rows} size="small" />}

      <Divider />
      {set && set.notes ? (
        <Note content={set?.notes} />
      ) : (
        <Typography variant="caption" display="block" gutter={2}>
          {t("messages.noNotes")}
        </Typography>
      )}
      <Divider />

      <Typography variant="h5" display="block" gutterBottom>
        {t("titles.consultations")}
      </Typography>
      <CompleteConsultationList setFilter={id} />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </Detail>
  );
}
export default ConsultationSetDetail;
