/**
 * This will return true if the invoice line can be .
 * @param {Invoice} invoice
 * @returns {boolean}
 */
export function isDeleteableInvoiceLine(invoice) {
  /* Delete can only happen when invoice is not finalized (isProcssed === false) (=> not exported to PDF)*/
  if (invoice.isProcessed === false) return true;
  return false;
}
