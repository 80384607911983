import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, number } from "yup";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { axiosInstance } from "../../../api/axios";
import { urlPostCost } from "../../../api/urls/cost";
import { FormTextfield } from "../../shared/forms/form-elements/FormTextfield";
import { FormNumberfield } from "../../shared/forms/form-elements/FormNumberField";

/**
 * A yup validation schema
 * @returns {object} Schema
 */
const individualCostSchema = object({
  title: string().required(),
  costAmount: string()
    .matches(/^[0-9]*\.?[0-9]*$/, "Must be only digits, seperate with dot ") //todo translate
    .required(),
}).required();

/**
 * Modal for Create individual cost
 * @param {{}} props
 * @returns {FormModal}
 */
export default function CreateIndividualCostModal({
  open,
  close,
  patientId,
  therapistId,
  handleSucces,
  setFailed,
}) {
  const [t] = useTranslation("costs");
  const {
    control,
    //formState: { errors },
    handleSubmit,
    reset,
    //getValues,
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(individualCostSchema),
  });

  /**
   * Handle close
   */
  const handleClose = () => {
    close();
    reset({
      title: "",
      costAmount: "",
    });
  };

  /**
   * Handle Valid Submit
   * @param {{title: string, costAmount: number}} validObject
   */
  const handleValidSubmit = (validObject) => {
    const cost = {
      patientId: Number(patientId),
      consultationId: null,
      therapistId: therapistId,
      title: validObject.title,
      compensationType: 0,
      complementaryPayBackAmount: 0,
      costAmount: Number(Number(validObject.costAmount).toFixed(2)),
    };
    /**
     * Add indivdual cost
     */
    const url = urlPostCost();
    axiosInstance
      .post(url, cost)
      .then((response) => {
        // Pass the created id to the parent component
        handleSucces(response.data);
      })
      .catch((error) => {
        console.error(`POST ${url}`, error);
        setFailed(t("snackbars.failure.addindividualcost"));
      });
    handleClose();
  };

  /**
   * Handle Invalid Submit
   * @param {{}} errors
   */
  const handleInvalidSubmit = (errors) => {
    setFailed(t("snackbars.failure.invalid"));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form
        style={{ margin: "unset" }}
        onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}
      >
        <DialogTitle>{t("modal.addindividualcost.title")}</DialogTitle>
        <DialogContent>
          <FormTextfield
            control={control}
            name="title"
            displayAsRequired
            label={t("form.fields.title")}
            //errors={errors}
          />
          <FormNumberfield
            displayAsRequired
            control={control}
            name="costAmount"
            label={t("form.fields.costAmount")}
            //errors={errors}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">{t("modal.addindividualcost.submit")}</Button>
          <Button onClick={handleClose}>
            {t("modal.addindividualcost.cancel")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
