import { Card, Box } from "@mui/material";
import { CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../../api/axios";

/**
 * a consultation note represents a comment that a therapist added to a consultation
 * @param {*} content
 */
function Note({ consultationId }) {
  const [t] = useTranslation("consultations");
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    let isMounted = true;
    axiosInstance
      .get(`/v2/consultations/${consultationId}/notes`)
      .then((response) => {
        if (isMounted) setNotes(response.data);
      });
    return () => {
      isMounted = false;
    };
  }, [consultationId]);

  return (
    <>
      {notes && notes[notes.length - 1] ? (
        <>
          <Box>
            <Card>
              <CardContent>
                <Typography
                  variant="body2"
                  sx={{ maxHeight: "10em", overflowY: "auto" }}
                >
                  {notes &&
                    notes[notes.length - 1] &&
                    notes[notes.length - 1].content}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </>
      ) : (
        <Typography variant="caption" display="block" gutter={2}>
          {t("messages.noNotes")}
        </Typography>
      )}
    </>
  );
}
export default Note;
