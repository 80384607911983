import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Collapse, IconButton, TableSortLabel } from "@mui/material";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { useState } from "react";

import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function CollapsingTable({
  rows,
  columns,
  pageSize,
  page,
  setPage,
  setPageSize,
  createSortHandler = () => {},
  orderBy,
  order,
  tableSizeSmall,
  tableCellAlign,
  rowCount,
}) {
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div style={{ height: "fit-content", maxHeight: "100vw", width: "100%" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            size={tableSizeSmall && "small"}
          >
            <TableHead>
              <TableRow>
                <TableCell />
                {columns &&
                  columns.map((column) => (
                    <TableCell
                      data-testid="collapsing-table_column"
                      key={column.id}
                      align={tableCellAlign ? tableCellAlign : "center"}
                      style={{ minWidth: column.minWidth, width: column.width }}
                    >
                      <TableSortLabel
                        disabled={column.sortable === false}
                        active={orderBy === column.field.toUpperCase()}
                        direction={
                          orderBy === column.field.toUpperCase() ? order : "asc"
                        }
                        onClick={createSortHandler(column.id)}
                      >
                        {column.headerName}
                        {orderBy === column.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {columns &&
                rows &&
                rows.map((row) => {
                  return (
                    <CollapsingRow
                      key={row.id}
                      row={row}
                      columns={columns}
                      tableCellAlign={tableCellAlign}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {!!rowCount &&
          page !== null &&
          pageSize !== null &&
          rows.length <= pageSize &&
          page <= rowCount / pageSize &&
          typeof setPage === "function" &&
          typeof setPageSize === "function" && (
            <TablePagination
              data-testid="CollapsingTable-pagination-block"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rowCount}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
      </Paper>
    </div>
  );
}

function CollapsingRow({ row, columns, tableCellAlign }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        data-testid="collapsing-table_row"
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column) => {
          const value = row[column.field];
          return (
            <TableCell
              key={column.id}
              align={tableCellAlign ? tableCellAlign : "center"}
            >
              {column.format && value instanceof Date
                ? column.format(value)
                : value}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow sx={{ height: "fit-content" }}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {row.collapsingContentTitle}
              </Typography>
              {row.collapsingContent ?? row.collapsingContent}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default CollapsingTable;
