import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ExportCollectionStateFormStep from "./ExportCollectionStateFormStep";
import Header from "./../../../shared/default/Header";
import Wizard from "./../../../shared/wizards/Wizard";
import FailureSnackbar from "./../../../shared/menus/snackbars/FailureSnackbar";
import { useHistory, useParams } from "react-router";
import { urlPostCollectionState } from "./../../../../api/urls/collectionstateinvoice/post";
import { axiosInstance } from "./../../../../api/axios/axiosInstance";
import { useState } from "react";
import { urlGetHealthInsuranceFunds } from "./../../../../api/urls/utilities/get";
import { useEffect } from "react";
import { urlGetPatientHealthInsuranceFund } from "./../../../../api/urls/patient/get";

//ExportCollectionState schema
const schema = object({
  healthInsuranceFund: string().required("error.required").default(""),
  prescriptionNumber: string().required("error.required").default(""),
}).required();

export default function ExportCollectionStateWizard() {
  const [t] = useTranslation("invoices");
  const { invoiceId, patientId } = useParams();
  const history = useHistory();

  const [failed, setFailed] = useState();
  const {
    control,
    formState: { errors /*, isValid, isValidating */ },
    handleSubmit,
    trigger,
    setValue,
    /*formState :{  errors },*/
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const [healthInsuranceFundsList, setHealthInsuranceFundsList] = useState([]);
  const [healthInsuranceFundIdPatient, setHealthInsuranceFundIdPatient] =
    useState();

  useEffect(() => {
    const mapHealthInsuranceFunds = (healtInsuranceItems) => {
      return healtInsuranceItems.map((h) => {
        return { id: h.Key, value: h.Key, label: h.Value };
      });
    };

    var url = urlGetHealthInsuranceFunds();
    axiosInstance
      .get(url)
      .then((response) => {
        setHealthInsuranceFundsList(mapHealthInsuranceFunds(response.data));
      })
      .catch((response) => {});

    var urlGetPatientHealthInsurance =
      urlGetPatientHealthInsuranceFund(patientId);

    axiosInstance
      .get(urlGetPatientHealthInsurance)
      .then((response) => {
        setHealthInsuranceFundIdPatient(response.data.id);
        setValue("healthInsuranceFund", response.data.id);
      })
      .catch((response) => {});
  }, [patientId]);

  /**
   * This function will be executed when the form is valid.
   * It will send a POST request to add the collectionState
   * @param {{}} validCollectionState
   */

  const onValidSubmit = (validCollectionState) => {
    const url = urlPostCollectionState();
    axiosInstance
      .post(url, {
        invoiceId: Number(invoiceId),
        healthInsuranceFundId: healthInsuranceFundIdPatient,
        prescriptionNumber: validCollectionState.prescriptionNumber,
      })
      .then((response) => {
        history.push(`/invoices/export/collectionstate/${invoiceId}`);
      })
      .catch(() => setFailed(t("snackbars.failure.add.collectionstate")));
  };

  const onInvalidSubmit = (errors) => {};

  const steps = [
    {
      id: 0,
      title: t("wizard.add.steps.addexportcollection.title"),
      description: t("wizard.add.steps.addexportcollection.description"),
      content: (
        <ExportCollectionStateFormStep
          control={control}
          errors={errors}
          healthInsuranceFundsList={healthInsuranceFundsList}
          value={healthInsuranceFundIdPatient}
          setValue={setHealthInsuranceFundIdPatient}
        />
      ),
      onNext: () => trigger(["healthInsuranceFund", "prescriptionNumber"]),
    },
  ];

  return (
    <>
      <Header title={t("wizard.add.steps.addexportcollection.title")} />
      <Wizard
        steps={steps}
        handleFinish={handleSubmit(onValidSubmit, onInvalidSubmit)}
        wide={true}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
