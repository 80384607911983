import { Button, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MyDateRangePicker from "../../shared/controlled-inputs/DateRangePicker";
import UserPickerByName from "../../shared/controlled-inputs/userPickerByName";
import { initialstateFilters } from "../../../constants/initialstates-constants";

function ConsultationSetFilterBar({
  styles,
  setConsultationsFilter,
  setDateFilter,
  setPatientFilter,
  setTherapistFilter,
}) {
  const { t } = useTranslation("consultations");

  const [consultations, setConsultations] = useState(
    initialstateFilters.string
  );
  const [startTime, setStartTime] = useState(initialstateFilters.dateRange);
  const [patient, setPatient] = useState(initialstateFilters.userPicker);

  const applyFilters = () => {
    setDateFilter(startTime);
    setConsultationsFilter(consultations);
    setPatientFilter(patient?.userId);
  };

  const resetFilters = () => {
    setStartTime(initialstateFilters.dateRange);
    setDateFilter(initialstateFilters.dateRange);
    setPatient(initialstateFilters.userPicker);
    setPatientFilter(initialstateFilters.userPicker);
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <MyDateRangePicker
          value={startTime}
          setValue={setStartTime}
          startText={t("filters.labels.daterange")}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <UserPickerByName
          user={patient}
          setUser={setPatient}
          label={t("filters.labels.patient")}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          label={t("filters.labels.consultations")}
          variant="outlined"
          value={consultations}
          type="number"
          onChange={(event) => setConsultations(event.target.value)}
        />
      </Stack>
      <Stack direction="row" spacing={2} style={styles.stack}>
        <div style={styles.containerActions}>
          <Button
            variant="outlined"
            color="secondary"
            sx={styles.buttonReset}
            onClick={resetFilters}
          >
            {t("filters.reset")}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={styles.buttonApply}
            onClick={applyFilters}
          >
            {t("filters.apply")}
          </Button>
        </div>
      </Stack>
    </>
  );
}
export default ConsultationSetFilterBar;
