import { Stack } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../../auth/useAuth";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import SuccesSnackbar from "../../shared/menus/snackbars/SuccesSnackbar";
import CompleteConsultationList from "./CompleteConsultationList";
import ConsultationFilterBar from "./ConsultationFilterBar";

function FilteredConsultationList({ showFilters, stylesFilters }) {
  const [succes, setSucces] = useState(false);
  const [failed, setFailed] = useState();
  const { t } = useTranslation("consultations");
  const { id } = useAuth();

  const [locationFilter, setLocationFilter] = useState();
  const [fromFilter, setFromFilter] = useState();
  const [untillFilter, setUntillFilter] = useState();

  const [patientFilter, setPatientFilter] = useState();
  const [therapistFilter, setTherapistFilter] = useState(id);

  return (
    <div className="card-list">
      <Stack spacing={2}>
        {showFilters === true && (
          <ConsultationFilterBar
            styles={stylesFilters}
            setLocationFilter={setLocationFilter}
            setPatientFilter={setPatientFilter}
            setTherapistFilter={setTherapistFilter}
            setDateFilter={(values) => {
              setFromFilter(
                values[0] ? moment(values[0]).format("YYYY-MM-DD") : undefined
              );
              setUntillFilter(
                values[1] ? moment(values[1]).format("YYYY-MM-DD") : undefined
              );
            }}
          />
        )}
        <CompleteConsultationList
          locationFilter={locationFilter}
          fromFilter={fromFilter}
          patientFilter={patientFilter}
          therapistFilter={therapistFilter}
          untillFilter={untillFilter}
        />
      </Stack>
      <SuccesSnackbar
        open={!!succes}
        setOpen={setSucces}
        text={t("snackbars.succes")}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </div>
  );
}
export default FilteredConsultationList;
