/**
 * Get a collectionState by ID
 * @param {number} collectionStateId
 * @returns {string} url
 */
export const urlGetCollectionStateById = (collectionStateId) =>
  `v2/collectionStateInvoices/${collectionStateId}`;

  

  /**
 * Get a collectionState by ID
 * @param {number} invoiceId
 * @returns {string} url
 */
export const urlGetExportedCollectionState = (invoiceId) =>
`v2/invoices/collectionState/${invoiceId}/export`;

/**
 * Get a exported collectionState by invoiceId
 * @param {number} invoiceId
 * @returns {string} url
 */
export const urlGetCollectionStateExportedByInvoiceId = (invoiceId) =>
  `v2/collectionStateInvoices/${invoiceId}/export`;

/**
 * Get a collectionState by invoiceId
 * @param {number} invoiceId
 * @returns {string} url
 */
export const urlGetCollectionStateByInvoiceId = (invoiceId) =>
  `v2/collectionStateInvoices/${invoiceId}/collectionStateInvoice`;

/**
 * Check if there is an existing collectionState for invoice
 * @param {number} invoiceId
 * @returns {string} url
 */
export const urlGetDoesCollectionStateExistForInvoice = (invoiceId) =>
  `v2/collectionStateInvoices/${invoiceId}/existingCollectionState`;
