import moment from "moment";

/**
 * Convert a string of a date to date with format DD/MM/YYYY
 * @param {string} dateFromJson
 * @returns {string} dateString
 */
export function dateToString(dateFromJson) {
  return moment(dateFromJson).format("DD/MM/YYYY").toString();
}
