import EditConsultationSetScreen from "./../../screens/EditConsultationSetScreen";
import ConsultationSetDetailScreen from "./../../screens/ConsultationSetDetailScreen";

/**
 * @type {Array<{path: string, screen: JSX.Element}>}
 */
// prettier-ignore
export const routesConsultationSet = [
    { path: "/consultationset/:id/edit", screen: <EditConsultationSetScreen />, },
    { path: "/consultationSet/:id/detail", screen: <ConsultationSetDetailScreen />, },
    { path: "/consultationset/new", screen: <EditConsultationSetScreen />, },
];
