import PropTypes from "prop-types";
import { IconTableRow } from "./index";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

IconTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape(IconTableRow)),
  align: PropTypes.string,
  size: PropTypes.string,
};

const styles = {
  tableCellIcon: { width: "50px" },
  tableCellTitle: { width: "150px", fontWeight: "bold" },
};

/**
 * IconTable is intended for displaying the details of an object.
 * Each row consists of an icon, a title and a value.
 * @see {@link IconTableRow}
 * @param {{rows: IconTableRow[], align: string, size: string}} props
 * @returns {JSX.Element} IconTable
 */
export default function IconTable({ rows, align = "left", size = "medium" }) {
  return (
    <TableContainer>
      <Table size={size}>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell align={align} style={styles.tableCellIcon}>
                  {row.icon}
                </TableCell>
                <TableCell align={align} style={styles.tableCellTitle}>
                  {row.title}
                </TableCell>
                <TableCell align={align}>{row.value}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
