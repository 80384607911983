export const locations = [
  { id: 0, value: 0, label: "location.home.label", text: "location.home.label" },
  { id: 1, value: 1, label: "location.school.label", text: "location.school.label" },
  { id: 2, value: 2, label: "location.office.label", text: "location.office.label" },
  { id: 3, value: 3, label: "location.hospital.label", text: "location.hospital.label" },
  {
    id: 4,
    value: 4,
    label: "location.revalidationcenter.label",
    text: "location.revalidationcenter.label",
  },
];
export const titledLocations = [
  { id: -1, value: undefined, label: "None", disabled: true },
  ...locations,
];
