import PatientDetailScreen from "./../../screens/PatientDetailScreen";
import AddInvoiceScreen from "./../../screens/AddInvoiceScreen";
import InvoicesPatientScreen from "./../../screens/InvoicesPatientScreen";
import InvoiceScreen from "../../screens/InvoiceScreen.jsx";

/**
 * @type {Array<{path: string, screen: JSX.Element}>}
 */
// prettier-ignore
export const routesPatients = [
    { path: "/patients/:id", screen: <PatientDetailScreen />, },
    { path: "/patients/:patientId/invoices/new", screen: <AddInvoiceScreen />, },
    { path: "/patients/:patientId/invoices/:invoiceId", screen: <InvoiceScreen />, },
    { path: "/patients/:patientId/invoices", screen: <InvoicesPatientScreen />, },
];
