import { Redirect, Route, Switch } from "react-router";
import useAuth from "../auth/useAuth";
import PageNotFoundScreen from "../screens/PageNotFoundScreen";
import {
  consultations,
  consultationset,
  files,
  home,
  invoices,
  patients,
  scheduler,
  settings,
  therapists,
} from "./routes";
function AuthenticatedRoute(props) {
  const { token } = useAuth();

  if (!token) return <Redirect url={process.env.REACT_APP_V1_URL} />;

  return <Route {...props} />;
}

/**
 * Routes of the application
 * @returns {Switch} Switch with root routes
 */
export function Routes() {
  /**
   * Maps exact route paths
   * @param {Array<{path: string, screen: JSX.Element}>} list list of exact routes
   * @returns {Array<JSX.Element>}
   */
  const mapRoutesExactPath = (list) =>
    list.map((r, index) => (
      <AuthenticatedRoute exact path={r.path} key={index}>
        {r.screen}
      </AuthenticatedRoute>
    ));

  return (
    <Switch>
      {mapRoutesExactPath(files)}
      {mapRoutesExactPath(consultations)}
      {mapRoutesExactPath(consultationset)}
      {mapRoutesExactPath(settings)}
      {mapRoutesExactPath(therapists)}
      {mapRoutesExactPath(patients)}
      {mapRoutesExactPath(invoices)}
      {mapRoutesExactPath(scheduler)}
      <Route path="/404">
        <PageNotFoundScreen />
      </Route>
      {mapRoutesExactPath(home)}
      <Redirect to="/404" />
    </Switch>
  );
}
