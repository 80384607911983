import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { costTypes, legalCostTypes } from "../../../constants/cost-constants";
import { FormDropdown } from "../../shared/forms/form-elements/FormDropdown";
import { FormTextfield } from "../../shared/forms/form-elements/FormTextfield";

function AddCosts({ fields, errors, control, remove, append, editing, watch }) {
  const { t } = useTranslation("consultations");
  const watchCosts = watch({
    control,
    name: "costs",
  });
  const controlledCosts = fields.map((field, index) => {
    return {
      ...field,
      ...watchCosts[index],
    };
  });

  return (
    <form>
      <legend>
        {editing ? t("edit.titles.edit.costs") : t("edit.titles.new.costs")}
      </legend>
      {watchCosts.costs && watchCosts.costs.length === 0 && (
        <Typography variant="caption" display="block" gutterBottom>
          {t("edit.messages.noCosts")}
        </Typography>
      )}
      {controlledCosts.map((item, index) => (
        <div className="form-row" key={item.id}>
          <Stack
            spacing={2}
            direction="column"
            sx={{
              border: "1px solid grey",
              padding: 2,
              borderRadius: 2,
              margin: 2,
            }}
          >
            <FormTextfield
              margin="none"
              label={t("edit.fields.costs.title")}
              displayAsRequired
              value={item.title}
              name={`costs.${index}.title`}
              errors={errors.costs && errors.costs[index]?.title ? errors.costs[index].title : false}
              control={control}
            />
            <Stack spacing={2} direction="row">
              <FormDropdown
                style={{ width: "500px" }}
                label={t("edit.fields.costs.type")}
                displayAsRequired
                sx={{ width: "30em" }}
                value={item.costType}
                name={`costs.${index}.costType`}
                errors={errors.costs && errors.costs[index]?.costType ? errors.costs[index].costType : false}
                options={costTypes.map((cost) => {
                  cost.label = t(`${cost.label}`, { ns: "translations"});
                  return cost;
                })}
                margin="none"
                control={control}
              />
              {watchCosts.costs[index].costType === 0 && (
                <FormDropdown
                  style={{ width: "500px" }}
                  label={t("edit.fields.costs.paybackType")}
                  displayAsRequired
                  sx={{ width: "30em" }}
                  value={item.paybackType}
                  name={`costs.${index}.paybackType`}
                  errors={errors.costs && errors.costs[index]?.paybackType ? errors.costs[index].paybackType : false}
                  options={legalCostTypes}
                  margin="none"
                  control={control}
                />
              )}
              {watchCosts.costs[index].costType === 101 && (
                <FormTextfield
                  margin="none"
                  label={t("edit.fields.costs.paybackAmount")}
                  displayAsRequired
                  errors={errors.costs && errors.costs[index]?.paybackAmount ? errors.costs[index].paybackAmount : false}
                  value={item.paybackAmount}
                  name={`costs.${index}.paybackAmount`}
                  control={control}
                />
              )}
            </Stack>

            <FormTextfield
              margin="none"
              label={t("edit.fields.costs.amount")}
              displayAsRequired
              value={item.amount}
              errors={errors.costs && errors.costs[index]?.amount ? errors.costs[index].amount : false}
              name={`costs.${index}.amount`}
              control={control}
            />
            <Button
              variant="outlined"
              color="warning"
              onClick={() => remove(index)}
            >
              {t("edit.buttons.deleteCost")}
            </Button>
          </Stack>
        </div>
      ))}
      <Button
        variant="outlined"
        onClick={() =>
          append({
            title: "",
            costType: -1,
            paybackType: -1,
            paybackAmount: 0,
            amount: 0,
          })
        }
      >
        {t("edit.buttons.newCost")}
      </Button>
    </form>
  );
}
export default AddCosts;
