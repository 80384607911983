/**
 * This will return true if the invoice can be edited.
 * @param {Invoice} invoice
 * @returns {boolean}
 */
export function isEditableInvoice(invoice) {
  /* An edit to the invoice can only happen when isProcessed has been set on false*/
  if (invoice.isProcessed === false) {
    return true;
  }
  return false;
}
