import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { locations } from "../../../constants/consultation-constants";
import CostTable from "../../costs/CostTable";
import {
  FormDatePicker,
  FormTimePicker,
} from "../../shared/forms/form-elements/FormDateTimePicker";
import { FormDropdown } from "../../shared/forms/form-elements/FormDropdown";
import { FormTextfield } from "../../shared/forms/form-elements/FormTextfield";
import { FormUserPicker } from "../../shared/forms/form-elements/FormUserPicker";

export default function EditConsultationOverview({
  onSubmit,
  editing,
  loading,
  errors,
  control,
  fields,
  remove,
  append,
  selectedSet,
  sets,
  setId,
  watch,
  setAddAppointment,
  addAppointment,
}) {
  const { t } = useTranslation("consultations");

  const watchCosts = watch({
    control,
    name: "costs",
  });
  const parseCosts = (costs) => {
    return costs.map((c) => {
      return {
        title: c.title,
        compensationType:
          c.costType === 0 ? c.paybackType : c.costType === 5 ? 0 : c.costType,
        complementaryPayBackAmount: parsePayback(c.compensationType, c.complementaryPayBackAmount, c.amount),
        costAmount: c.amount,
      };
    });
  };

  const parsePayback = (compensationType, paybackAmount, costAmount) => {
    switch(compensationType) {
      case 75:
        return costAmount * 0.75;
      case 60:
        return costAmount * 0.60;
      case 90:
        return costAmount * 0.90;
      case 100:
        return costAmount;
      case 101:
        return paybackAmount;
      default: 
        return 0;
    }
  }

  const card = (
    <>
      {selectedSet && (
        <CardContent>
          <CardHeader subheader={t("titles.selectedSet") + `: ${selectedSet?.consultationSetDto.title}`}></CardHeader>
          <p>{selectedSet?.title}</p>
          <p>
            {t("edit.fields.maxConsultationCount")}:{" "}
            {selectedSet?.consultationSetDto.amountOfConsultations}
          </p>
        </CardContent>
      )}
    </>
  );
  return (
    <>
      <form onSubmit={onSubmit()}>
        {/*page 1*/}
        {loading === true && <CircularProgress />}
        {loading === false && (
          <>
            <fieldset
              style={{
                border: "none",
                display: "flex",
                flexDirection: "column",
                maxWidth: "900px",
              }}
            >
              <legend>
                {editing
                  ? t("edit.titles.edit.consultation")
                  : t("edit.titles.new.consultation")}
              </legend>
              <FormUserPicker
                control={control}
                name="patient"
                label={t("edit.fields.patient")}
                errors={errors.patient}
                disabled
              />
              <FormDropdown
                control={control}
                name="location"
                label={t("edit.fields.location")}
                options={locations}
                errors={errors.location}
              />
              <span>
                <FormDatePicker
                  control={control}
                  name="date"
                  label={t("edit.fields.date")}
                  errors={errors.date}
                />
                <FormTimePicker
                  control={control}
                  name="starttime"
                  label={t("edit.fields.starttime")}
                  errors={errors.starttime}
                />
                <FormTimePicker
                  control={control}
                  name="endtime"
                  label={t("edit.fields.endtime")}
                  errors={errors.endtime}
                />
              </span>
            </fieldset>

            <fieldset
              style={{
                border: "none",
                display: "flex",
                flexDirection: "column",
                maxWidth: "900px",
              }}
            >
              <legend>
                {editing
                  ? t("edit.titles.edit.costs")
                  : t("edit.titles.new.costs")}
              </legend>
              <Typography variant="caption" display="block" gutterBottom>
                {t("edit.messages.noCosts")}
              </Typography>
              {watchCosts.costs && watchCosts.costs.length > 0 && (
                <CostTable costs={parseCosts(watchCosts.costs)} />
              )}
            </fieldset>

            <fieldset
              style={{
                border: "none",
                display: "flex",
                flexDirection: "column",
                maxWidth: "900px",
              }}
            >
              <legend>{t("titles.consultationSet")}</legend>
              <Box sx={{ minWidth: 275 }}>
                <Card variant="outlined">{card}</Card>
              </Box>
              <FormTextfield
                control={control}
                name="nomenclature"
                label={t("fields.consultation.nomenclature")}
                errors={errors.nomenclature}
              />
              <FormTextfield
                control={control}
                name="note"
                label={t("edit.fields.note")}
                multiline
                rows="3"
                errors={errors.note}
              />
            </fieldset>
          </>
        )}
      </form>
      {!editing && (
        <>
          <Divider />
          <FormControlLabel
            control={
              <Checkbox onChange={() => setAddAppointment(!addAppointment)} />
            }
            label={t("edit.fields.addAppointment")}
          />
        </>
      )}
    </>
  );
}
