import { Card, Box } from "@mui/material";
import { CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * a consultation note represents a comment that a therapist added to a consultation
 * @param {*} content
 */
function Note({ content }) {
  const [t] = useTranslation("consultations");
  return (
    <Box sx={{ pt: 5, pb: 5 }}>
      <Typography variant="h6">{t("titles.consultation.notes")}</Typography>
      <Card>
        <CardContent>
          <Typography
            variant="body2"
            sx={{ maxHeight: "10em", overflowY: "auto" }}
          >
            {content ?? content}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
export default Note;
