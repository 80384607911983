import DeleteIcon from "@mui/icons-material/Delete";
import GenericIconButton from "./GenericIconButton";

export default function DeleteButton({
  tooltip,
  onClick,
  color,
  ...restprops
}) {
  return (
    <GenericIconButton tooltip={tooltip} onClick={onClick} {...restprops}>
      <DeleteIcon color={color} />
    </GenericIconButton>
  );
}
