import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import moment from "moment";
import { Button, Grid } from "@mui/material";
import { checkOrUnCheckIcon } from "../../../helpers/checkOrUncheckIcon";
import { IconTable, IconTableRow } from "../../shared/tables/iconTable";
import { daysBetweenTwoDates } from "../../../helpers/dates";
// Icons
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PersonIcon from "@mui/icons-material/Person";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import PaymentsIcon from "@mui/icons-material/Payments";
import CachedIcon from "@mui/icons-material/Cached";

/**
 * Tab content with details about the invoice
 * @param {{ invoice: {} }} props
 * @returns {JSX.Element}
 */
export default function InvoiceOverviewDetail({ invoice }) {
  const [t] = useTranslation("invoices");
  const history = useHistory();

  /**
   * Format the "days left"
   * @param {Date} dueDate
   * @returns {string} # days left
   */
  const formatDueDateText = (dueDate) => {
    const diff = daysBetweenTwoDates(dueDate, new Date());
    if (diff === 0) return ``;
    return `(${t("detail.daysleft", { amountOfDays: diff })})`;
  };

  /**
   * Rows with status information
   * @returns {IconTableRow[]} rows
   */
  // prettier-ignore
  const statusRows = [
    new IconTableRow(0, <CachedIcon />, t("tables.columns.isProcessed"), checkOrUnCheckIcon(invoice.isProcessed)),
    new IconTableRow(1, <PaymentsIcon />, t("tables.columns.isPaid"), checkOrUnCheckIcon(invoice.isPaid)),
  ];

  /**
   * Rows with dates
   * @returns {IconTableRow[]} rows
   */
  // prettier-ignore
  const dateRows = [
    new IconTableRow(0, <DateRangeIcon />, t("tables.columns.date"), moment(new Date(invoice.date)).format("DD/MM/YYYY")),
    new IconTableRow(1, <EventAvailableIcon />, t("tables.columns.dueDate"), `${moment(new Date(invoice.dueDate)).format("DD/MM/YYYY")} ${formatDueDateText(new Date(invoice.dueDate))}`),
  ];

  /**
   * Rows with invoice information
   * @returns {IconTableRow[]} rows
   */
  // prettier-ignore
  const infoRows = [
    new IconTableRow(0, <InfoIcon />, t("tables.columns.number"), invoice.number),
    new IconTableRow(1, <HelpIcon />, t("tables.columns.reference"), invoice.reference),
    new IconTableRow(4, <AccessibilityIcon />, t("tables.columns.creditor"), 
      <Button sx={{ padding: 0 }} onClick={() => history.push(`/therapists/${invoice.creditor.userId}`)}>
        {`${invoice.creditor.firstName} ${invoice.creditor.lastName}`}
      </Button>),
    new IconTableRow(5, <PersonIcon />, t("tables.columns.customer"), 
      <Button sx={{ padding: 0 }} onClick={() => history.push(`/patients/${invoice.customer.userId}`)}>
      {`${invoice.customer.firstName} ${invoice.customer.lastName}`}
      </Button>),
  ];

  /**
   * Section with title and IconTable
   * @param {string} title
   * @param {IconTableRow[]} rows
   * @returns {JSX.Element}
   */
  const sectionIconTable = (title, rows) => (
    <>
      <h3>{title}</h3>
      <IconTable rows={rows} align="left" size="small" />
    </>
  );

  return (
    <Grid container spacing={3} columns={16}>
      <Grid item xs={8}>
        {sectionIconTable(t("detail.section.status"), statusRows)}
      </Grid>
      <Grid item xs={8}>
        {sectionIconTable(t("detail.section.dates"), dateRows)}
      </Grid>
      <Grid item xs={8}>
        {sectionIconTable(t("detail.section.info"), infoRows)}
      </Grid>
      {invoice.remarks && (
        <Grid item xs={8}>
          <h3>{t("detail.section.remarks")}</h3>
          {invoice.remarks}
        </Grid>
      )}
    </Grid>
  );
}
