import AddInvoiceWizard from "../components/invoices/AddInvoiceWizard";

/**
 * Add Invoice Screen
 * @returns {JSX.Element}
 */
const AddInvoiceScreen = () => {
  return <AddInvoiceWizard />;
};
export default AddInvoiceScreen;
