import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { StepContent, StepLabel, Stack } from "@mui/material";
import FailureSnackbar from "../menus/snackbars/FailureSnackbar";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

function Wizard({ steps, handleFinish, wide = false }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [failed, setFailed] = React.useState();
  const { t } = useTranslation("wizard");
  const history = useHistory();

  const handleNext = () => {
    if (steps[activeStep].onNext !== undefined) {
      steps[activeStep].onNext().then((valid) => {
        if (valid) {
          const newActiveStep = activeStep + 1;
          setActiveStep(newActiveStep);
        } else {
          setFailed(t("invalid"));
        }
      });
    } else {
      const newActiveStep = activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };
  const onCancel = () => {
    history.goBack();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: wide ? "100%" : "fit-content",
        backgroundColor: "white",
      }}
    >
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{ height: "fit-content", padding: "1em 1em 0 1em", width: "20em" }}
      >
        {steps.map((step, index) => (
          <Step key={step.id} data-testid="wizard-step">
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">{t("final-step")}</Typography>
                ) : null
              }
            >
              {step.title}
            </StepLabel>
            <StepContent>
              <Typography variant="caption">{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Stack
        sx={{
          minWidth: "40em",
          width: "80%",
          justifyContent: "space-between",
          padding: "1em",
        }}
      >
        <div
          data-testid={"wizard-step__content"}
          sx={{
            width: "fit-content",
          }}
        >
          {steps[activeStep].content}
        </div>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            padding: "1em",
            margin: 0,
          }}
        >
          {activeStep !== 0 && (
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mt: 1, mr: 1 }}
              data-testid="wizard-step__back-button"
            >
              {t("actions.back")}
            </Button>
          )}
          {activeStep === 0 && (
            <Button
              variant="outlined"
              onClick={() => onCancel()}
              hidden={activeStep !== 0}
            >
              Cancel
            </Button>
          )}

          <Button
            variant="contained"
            onClick={
              activeStep === steps.length - 1 ? handleFinish : handleNext
            }
            sx={{ mt: 1, mr: 1 }}
            data-testid="wizard-step__next-button"
          >
            {activeStep === steps.length - 1
              ? t("actions.finish")
              : t("actions.continue")}
          </Button>
          {}
        </Box>
      </Stack>

      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </Box>
  );
}
export default Wizard;
