import { Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";

function DefaultNoteSetting({ name, value, setValue, description, type }) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Stack spacing={0}>
      <Box
        direction="row"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        <div>
          {name}{" "}
          <InfoIcon
            sx={{ fontSize: "medium" }}
            color="primary"
            onClick={() => setCollapsed(!collapsed)}
          />
          {description && !collapsed && (
            <Typography variant="caption" gutterBottom margin={1}>
              {description}
            </Typography>
          )}
        </div>{" "}
        <Stack spacing={5} direction="row">
          <TextField
            type={type}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            multiline
            rows={4}
            fullWidth
          />
        </Stack>
      </Box>
    </Stack>
  );
}
export default DefaultNoteSetting;
