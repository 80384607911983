import { useTranslation } from "react-i18next";
import { IconTableRow, IconTable } from "../../shared/tables/iconTable";
import { mapLocationType } from "./../../../helpers/enumMappers";

// Icons
import PersonIcon from "@mui/icons-material/Person";
import ShortTextIcon from "@mui/icons-material/ShortText";
import BusinessIcon from "@mui/icons-material/Business";

/**
 * Confirm patient consultation step
 * @returns {JSX.Element}
 */
export default function ConfirmPatientConsultationStep({
  agendaItem,
  patient,
}) {
  const [t] = useTranslation(["agendaitem", "translations"]);

  /**
   * Rows for the IconTable
   * @type {IconTableRow[]}
   */
  // prettier-ignore
  const rows = [    
    new IconTableRow(0, <ShortTextIcon/>, t("fields.title"), agendaItem?.title),
    new IconTableRow(1, <BusinessIcon/>, t("fields.location"), t(`${mapLocationType(agendaItem?.location)}`,  {ns: "translations" })),
    new IconTableRow(2, <PersonIcon/>, t("fields.patient.name"), `${patient?.userProfile.firstName} ${patient?.userProfile.lastName}`),
  ]

  return <>{agendaItem && patient && <IconTable rows={rows} />}</>;
}
