/**
 * This will return true if the invoice can be paid.
 * @param {Invoice} invoice
 * @returns {boolean} payable
 */
export function isPayableInvoice(invoice) {
  /* The invoite can only be paid when isProcessed is true*/
  if (invoice.isPaid === true) return false;
  if (invoice.isProcessed === true) return true;
  return false;
}
