import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid } from "@mui/material";
import moment from "moment";
import { IconTable, IconTableRow } from "../../shared/tables/iconTable";
import { axiosInstance } from "../../../api/axios";
import CostTable from "./../../costs/CostTable";

// Urls
import { urlGetCostById } from "./../../../api/urls/cost";

// Icons
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import NotesIcon from "@mui/icons-material/Notes";

/**
 * Overview step of Add Invoice Wizard
 * @param {{ getAllValues: () => { invoice: {} }, selectedConsultationCosts: Set<number>, selectedIndividualCosts: Set<number> }} props
 * @returns {JSX.Element}
 */
export default function OverviewStep({
  invoiceValues,
  selectedConsultationCosts,
  selectedIndividualCosts,
}) {
  const [t] = useTranslation("invoices");

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingConsultationCosts, setIsLoadingConsultationCosts] =
    useState(true);
  const [isLoadingIndividualCosts, setIsLoadingIndividualCosts] =
    useState(true);

  const [invoice, setInvoice] = useState();
  const [consultationCosts, setConsultationCosts] = useState();
  const [individualCosts, setIndividualCosts] = useState();

  /**
   * Transform incomming objects
   */
  useEffect(() => {
    /**
     * Process Invoice
     */
    const processInvoice = () => {
      const currentValues = invoiceValues();
      const parseInvoice = (value) => {
        return {
          date: new Date(value.date),
          dueDate: new Date(value.dueDate),
          creditorId: value.creditorId,
          number: value.number,
          reference: value.reference,
          remarks: value.remarks,
        };
      };
      setInvoice(parseInvoice(currentValues.invoice));
      setIsLoading(false);
    };

    /**
     * Get costs from API and returns a array
     * @param {Set<number>} costSet
     * @returns {Array<{}>} costArray
     */
    const getCostsFromApiAndAddToArray = async (costSet) => {
      if (costSet.length !== 0) {
        const costs = [];
        for (const costId of Array.from(costSet)) {
          await axiosInstance
            .get(urlGetCostById(costId))
            .then((reponse) => {
              costs.push(reponse.data);
            })
            .catch((error) => {
              // Do nothing
            });
        }
        return costs;
      }
      return [];
    };

    /**
     * Process Consultation Costs
     */
    const processConsultationCosts = async () => {
      const cCosts = await getCostsFromApiAndAddToArray(
        selectedConsultationCosts
      );
      setConsultationCosts(cCosts);
      setIsLoadingConsultationCosts(false);
    };

    /**
     * Process Individual Costs
     */
    const processIndividualCosts = async () => {
      const iCosts = await getCostsFromApiAndAddToArray(
        selectedIndividualCosts
      );
      setIndividualCosts(iCosts);
      setIsLoadingIndividualCosts(false);
    };

    processInvoice();
    processConsultationCosts();
    processIndividualCosts();

    return () => {};
  }, [invoiceValues, selectedConsultationCosts, selectedIndividualCosts]);

  /**
   * Invoice section
   * @returns {JSX.Element}
   */
  const sectionInvoice = () => {
    return (
      <>
        <Grid container spacing={2} columns={16}>
          <Grid item xs={8}>
            {/* prettier-ignore */}
            <IconTable
              rows={[
                new IconTableRow(0, <DateRangeIcon />, t("tables.columns.date"), moment(invoice.date).format("DD/MM/YYYY")),
                new IconTableRow(1, <EventAvailableIcon />, t("tables.columns.dueDate"), moment(invoice.dueDate).format("DD/MM/YYYY")),
              ]}
              align="left"
              size="small"
            />
          </Grid>
          <Grid item xs={8}>
            {/* prettier-ignore */}
            <IconTable
              rows={[
                new IconTableRow(0, <InfoIcon />, t("tables.columns.number"), invoice.number),
                new IconTableRow(1, <HelpIcon />, t("tables.columns.reference"), invoice.reference),
              ]}
              align="left"
              size="small"
            />
          </Grid>
        </Grid>
        {/* prettier-ignore */}
        {invoice.remarks && invoice.remarks !== "" && (
          <>
            {/* prettier-ignore */}
            <IconTable
              rows={[
                new IconTableRow(0, <NotesIcon />, t("tables.columns.remarks"), invoice.remarks),
              ]}
              align="left"
              size="small"
            />
          </>
        )}
      </>
    );
  };

  /**
   * Consultation Costs
   * @returns {JSX.Element}
   */
  const sectionConsultationCosts = () => {
    return (
      <>
        {/* prettier-ignore */}
        <h4>{t("wizard.add.steps.overview.sections.selectedconsultationcosts")}</h4>
        <CostTable
          costs={consultationCosts}
          textAlign="left"
          responsiveWidth={true}
        />
      </>
    );
  };

  /**
   * Individual Costs
   * @returns {JSX.Element}
   */
  const sectionIndividualCosts = () => {
    return (
      <>
        {/* prettier-ignore */}
        <h4>{t("wizard.add.steps.overview.sections.selectedindividualcosts")}</h4>
        <CostTable
          costs={individualCosts}
          textAlign="left"
          responsiveWidth={true}
        />
      </>
    );
  };

  return (
    <>
      {/* General*/}
      <h3>{t("wizard.add.steps.overview.sections.general")}</h3>
      {isLoading === true ? <CircularProgress /> : sectionInvoice()}
      {/* Consultation Costs*/}
      {isLoadingConsultationCosts === true ? (
        <CircularProgress />
      ) : (
        <>
          {consultationCosts &&
            consultationCosts.length !== 0 &&
            sectionConsultationCosts()}
        </>
      )}
      {/* Individual Costs*/}
      {isLoadingIndividualCosts === true ? (
        <CircularProgress />
      ) : (
        <>
          {individualCosts &&
            individualCosts.length !== 0 &&
            sectionIndividualCosts()}
        </>
      )}
    </>
  );
}
