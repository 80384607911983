import { React, useState, useEffect } from "react";
import { Page, Document, PDFViewer, StyleSheet } from "@react-pdf/renderer";
import { dateToString } from "../../../helpers/dates/dateToString";
import { useTranslation } from "react-i18next";
import mapValutaType from "./../../../helpers/enumMappers/mapValutaType";
import { useHistory } from "react-router";
import moment from "moment";
import SimpleAlert from "./../../shared/alerts/SimpleAlert";
import { Button } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  InvoicePdfDetailsSection,
  InvoicePdfHeaderSection,
  InvoicePdfExportnoteSection,
  InvoicePdfLinesTableSection,
  InvoicePdfUsersSection,
  InvoicePdfTotalCostSection,
  InvoicePdfBankSection,
  InvoicePdfDefaultNote,
  InvoicePdfAdditionalLinesTableSection,
} from "./invoicePdfSections";
import { mapLocationType } from "../../../helpers/enumMappers/index";

/**
 * Invoice PDF
 * @returns {PDFViewer} PDF
 */
export default function InvoicePdf({
  therapist,
  patient,
  invoice,
  valutaType,
  invoiceKeyValues,
  invoiceAmount,
  additionalCosts,
}) {
  const [t] = useTranslation(["invoices", "translations"]);
  const history = useHistory();
  const [hasWarnings, setHasWarnings] = useState(true);
  /**
   * Users with details for the users section
   * @type {{id: number, title: string, value: string | number }} users
   */
  const users = [
    {
      id: 0,
      title: `${therapist.firstName + " " + therapist.lastName}`,
      address: `${
        therapist.address +
        " " +
        therapist.houseNumber +
        ", " +
        therapist.zipcode +
        " " +
        therapist.city
      }`,
      country: therapist.country,
      phone: therapist.phone,
      email: therapist.email,
    },
    {
      id: 1,
      title: `${patient.firstName + " " + patient.lastName}`,
      address: `${
        patient.address +
        " " +
        patient.houseNumber +
        ", " +
        patient.zipcode +
        " " +
        patient.city
      }`,
      country: patient.country,
    },
  ];

  /**
   * Details about the invoice for the details section
   * @type {{id: number, title: string, value: string | number}} details
   */
  const details = [
    {
      id: 0,
      title: t("export.labels.number"),
      value: invoice.number,
    },
    {
      id: 1,
      title: t("export.labels.date"),
      value: dateToString(invoice.date),
    },
    {
      id: 2,
      title: t("export.labels.reference"),
      value: invoice.reference,
    },
    {
      id: 3,
      title: t("export.labels.dueDate"),
      value: dateToString(invoice.dueDate),
    },
  ];
  const invoicePdfLinesTableSectionText = t("export.consultation");
  const invoicePdfAdditionalLinesTableSection = t("export.additionalcost");
  const invoicePdfTotalCostSectionText = [
    { id: 0, text: t("export.totalcostwithoutcompensation") },
    { id: 1, text: t("export.compensationamount") },
    { id: 2, text: t("export.totalcost") },
  ];
  const invoicePdfHeaderSectionText =
    invoice.isCreditNote === false
      ? t("export.invoice")
      : t("export.creditnote");
  const invoicePdfBankSectionText = mapBankSectionText();

  function mapBankSectionText() {
    if (invoice.reference === "") {
      return t("export.banksectionwithoutreference", {
        valuta: mapValutaType(valutaType),
        invoiceamount: invoiceAmount.totalAmount.toFixed(2),
        date: moment(invoice.dueDate).format("DD/MM/YYYY"),
        iban: therapist.iban,
        reference: invoice.reference,
      });
    } else {
      return t("export.banksection", {
        valuta: mapValutaType(valutaType),
        invoiceamount: invoiceAmount.totalAmount.toFixed(2),
        date: moment(invoice.dueDate).format("DD/MM/YYYY"),
        iban: therapist.iban,
        reference: invoice.reference,
      });
    }
  }

  const keyValuesMapped = () => {
    for (const keyvalue of invoiceKeyValues) {
      keyvalue.key.location = t(`${mapLocationType(keyvalue.key.location)}`, {
        ns: "translations",
      });
    }
  };

  useEffect(() => {
    keyValuesMapped();
    if (
      therapist.iban !== null &&
      therapist.iban !== undefined &&
      therapist.address !== null &&
      therapist.address !== undefined
    ) {
      setHasWarnings(false);
    }
  }, [therapist.address, therapist.iban]);

  return (
    <>
      {therapist.iban === null || therapist.iban === undefined ? (
        <>
          <SimpleAlert
            severity="warning"
            title={t("export.noibantitle")}
            description={t("export.noiban")}
            action={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => history.push("/settings?key=0")}
              >
                <SettingsIcon></SettingsIcon>
              </Button>
            }
          ></SimpleAlert>
        </>
      ) : null}

      {therapist.address === null || therapist.address === undefined ? (
        <>
          <SimpleAlert
            severity="warning"
            title={t("export.addresstitle")}
            description={t("export.address")}
            action={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => history.push("/settings?key=2")}
              >
                <SettingsIcon></SettingsIcon>
              </Button>
            }
          ></SimpleAlert>
        </>
      ) : null}
      {hasWarnings === false ? (
        <PDFViewer showToolbar={true} style={styles.pdfViewer}>
          <Document>
            <Page size="A4" style={styles.page}>
              {/* Header */}
              <InvoicePdfHeaderSection
                styles={styles}
                invoicePdfHeaderSectionText={invoicePdfHeaderSectionText}
              />
              {/* Users */}
              <InvoicePdfUsersSection styles={styles} users={users} />
              {/* Details */}
              <InvoicePdfDetailsSection styles={styles} details={details} />
              {/* Export note */}
              <InvoicePdfExportnoteSection
                styles={styles}
                exportNotes={invoice.exportNotes}
              />
              {/* Table Loop trough key values*/}
              <InvoicePdfLinesTableSection
                styles={styles}
                keyValues={invoiceKeyValues}
                invoicePdfLinesTableSectionText={
                  invoicePdfLinesTableSectionText
                }
                isCreditNote={invoice.isCreditNote}
              />
              {/* Table Loop trough additionalCosts*/}
              <InvoicePdfAdditionalLinesTableSection
                styles={styles}
                additionalCosts={additionalCosts}
                invoicePdfAdditionalLinesTableSection={
                  invoicePdfAdditionalLinesTableSection
                }
                isCreditNote={invoice.isCreditNote}
              />
              {/* Total cost*/}
              <InvoicePdfTotalCostSection
                styles={styles}
                invoiceAmount={invoiceAmount}
                valutaType={valutaType}
                invoicePdfTotalCostSectionText={invoicePdfTotalCostSectionText}
                isCreditNote={invoice.isCreditNote}
              />
              {/* Bank information */}
              <InvoicePdfBankSection
                styles={styles}
                invoicePdfBankSectionText={invoicePdfBankSectionText}
                isCreditNote={invoice.isCreditNote}
              />
              {/* Pay*/}
              <InvoicePdfDefaultNote
                styles={styles}
                defaultNote={invoice.defaultNote}
              />
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  pdfViewer: {
    width: "100%",
    height: "40em",
  },
  page: {
    width: "max-content",
    height: "max-content",
    flexDirection: "column",
    backgroundColor: "white",
    padding: 30,
  },
  textTitle: {
    fontSize: 20,
  },
  textText: {
    fontSize: 11,
    marginRight: 10,
  },
  textBold: {
    fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
    margin: 10,
    marginBottom: 30,
  },
  rowHeader: {
    alignContent: "center",
    justifyContent: "space-between",
  },
  rowInvoiceUsers: {
    alignContent: "center",
    justifyContent: "space-between",
  },
  rowInvoiceDetail: {
    alignContent: "flex-start",
    justifyContent: "space-around",
  },
  rowExportNote: {
    border: 1,
    borderColor: "black",
    padding: 15,
    marginBottom: 10,
  },
  rowConsultationCosts: {
    flexDirection: "column",
    justifyContent: "flex-end",
    alignContent: "flex-end",
  },
  consultation: {
    flexDirection: "column",
    marginBottom: 10,
  },
  tableCosts: {
    border: 1,
    borderColor: "black",
    padding: 5,
    flexDirection: "column",
  },
  tableRowCosts: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-between",
  },
  rowTotalCost: {
    justifyContent: "flex-end",
    alignContent: "flex-end",
  },
  footerBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: 1,
    borderColor: "black",
    padding: 15,
    marginBottom: 10,
  },
  footerColumn: {
    display: "flex",
    flexDirection: "column",
  },
  totalCostSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    textAlign: "right",
    // marginRight: "5em",
  },
  totalCostItems: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    textAlign: "right",
  },
});
