/**
 * Cost types
 *  0.CONVENTIONAL = 75,
    1.NON_CONVENTIONAL = 60,
    2.WIGW = 90,
    3.MAXIMUM_BILL = 100,
    4.COMPLEMENTARY = 101,
    5.NONE = 0
 */

export const costTypes = [{
    id: -1,
    value: -1,
    label: "cost.selectcost.label"
  },
  {
    id: 0,
    value: 0,
    label: "cost.type.legal"
  },
  {
    id: 4,
    value: 101,
    label: "cost.type.additional"
  },
  {
    id: 5,
    value: 5,
    label: "cost.type.none"
  },
];

export const legalCostTypes = [{
    id: -1,
    value: -1,
    label: "cost.selectcost.label"
  },
  {
    id: 0,
    value: 75,
    label: "cost.type.conventional"
  },
  {
    id: 1,
    value: 60,
    label: "cost.type.nonconventional"
  },
  {
    id: 2,
    value: 90,
    label: "cost.type.wigw"
  },
  {
    id: 3,
    value: 100,
    label: "cost.type.maxbill"
  },
];

export const completeCostTypes = [{
    id: -1,
    value: undefined,
    label: "select costtype"
  },
  {
    id: 0,
    value: 0,
    label: "cost.type.conventional"
  },
  {
    id: 1,
    value: 1,
    label: "cost.type.nonconventional"
  },
  {
    id: 2,
    value: 2,
    label: "cost.type.wigw"
  },
  {
    id: 3,
    value: 3,
    label: "cost.type.maxbill"
  },
  {
    id: 4,
    value: 4,
    label: "cost.type.additional"
  },
  {
    id: 5,
    value: 5,
    label: "cost.type.none"
  },
];

export const currencies = [{
    value: "USD",
    label: "$"
  },
  {
    value: "EUR",
    label: "€"
  },
  {
    value: "BTC",
    label: "฿"
  },
  {
    value: "JPY",
    label: "¥"
  },
];

export const compensationTypes = [{
    id: 0,
    value: 75,
    label: "cost.type.conventional"
  }, // -> Standard
  {
    id: 1,
    value: 60,
    label: "cost.type.nonconventional"
  },
  {
    id: 2,
    value: 90,
    label: "cost.type.wigw"
  },
  {
    id: 3,
    value: 100,
    label: "cost.type.maxbill"
  },
  {
    id: 4,
    value: 101,
    label: "cost.type.complementary"
  },
  {
    id: 5,
    value: 0,
    label: "cost.type.none"
  },
];

export const valutaTypes = [{
    id: 0,
    value: 0,
    label: "€"
  },
  {
    id: 1,
    value: 1,
    label: "£"
  },
];