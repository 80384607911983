import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

/**
 * A modal with a form as content
 * @param {Dialog} Dialog
 */
export default function ButtonModal({
  open,
  title,
  description,
  content,
  handleClose,
}) {
  const [t] = useTranslation("common");
  return (
    <Dialog open={open} keepMounted onClose={handleClose}>
      <form style={{ margin: "unset" }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {description}
          </DialogContentText>
          {content}
        </DialogContent>
      </form>
    </Dialog>
  );
}
