import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { locations } from "../../../constants/consultation-constants";
import {
  FormDatePicker,
  FormTimePicker,
} from "../../shared/forms/form-elements/FormDateTimePicker";
import { FormDropdown } from "../../shared/forms/form-elements/FormDropdown";
import { FormTextfield } from "../../shared/forms/form-elements/FormTextfield";
import { FormUserPicker } from "../../shared/forms/form-elements/FormUserPicker";

function AddConsultationDetails({ control, errors, editing, loading }) {
  const { t } = useTranslation(["consultations", "translations"]);
  return (
    <>
      <form>
        {loading === true && <CircularProgress />}
        {loading === false && (
          <fieldset
            style={{
              border: "none",
              display: "flex",
              flexDirection: "column",
              maxWidth: "900px",
            }}
          >
            <legend>
              {editing
                ? t("edit.titles.edit.consultation")
                : t("edit.titles.new.consultation")}
            </legend>
            <FormTextfield
              control={control}
              name="description"
              label={t("edit.fields.description")}
              errors={errors.description}
            />
            <FormUserPicker
              disabled={editing}
              control={control}
              displayAsRequired
              name="patient"
              label={t("edit.fields.patient")}
              errors={errors.patient}
            />
            <FormDropdown
              control={control}
              name="location"
              displayAsRequired
              label={t("edit.fields.location")}
              options={locations}
              errors={errors.location}
            />
            <span>
              <FormDatePicker
                control={control}
                name="date"
                displayAsRequired
                label={t("edit.fields.date")}
                errors={errors.date}
              />
              <FormTimePicker
                control={control}
                name="starttime"
                displayAsRequired
                label={t("edit.fields.starttime")}
                errors={errors.starttime}
              />
              <FormTimePicker
                control={control}
                name="endtime"
                displayAsRequired
                label={t("edit.fields.endtime")}
                errors={errors.endtime}
              />
            </span>
            <FormTextfield
              control={control}
              name="note"
              label={t("edit.fields.note")}
              multiline
              rows="3"
              errors={errors.note}
            />
          </fieldset>
        )}
      </form>
    </>
  );
}
export default AddConsultationDetails;
