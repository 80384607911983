import { Text, View } from "@react-pdf/renderer";

/**
 * Users section for the Invoice PDF
 * @param {{ styles: {}, users: []}} props
 * @returns {View} Users
 */

function mapAddress(address) {
  if(address.includes("null")) {
    address = address.replace("null", "");
    address = address.replace(" null", "");
    address = address.replace("null ", "");
    address = address.replace("null,", "");
    address = address.replace(", null", "");

  }

  return address;
}
export default function InvoicePdfUsersSection({ styles, users }) {
  return (
    <View style={{ ...styles.row, ...styles.rowInvoiceUsers }}>
      {users.map((item) => {
        return (
          <View key={item.id} style={styles.information}>
            <Text>{item.title}</Text>
            <Text style={styles.textText}>{mapAddress(item.address)}</Text>
            {item.email && <Text style={styles.textText}>{item.email}</Text>}
            {item.phone && <Text style={styles.textText}>{item.phone}</Text>}
            
          </View>
        );
      })}
    </View>
  );
}
