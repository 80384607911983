import { Text, View } from "@react-pdf/renderer";

/**
 * Users section for the Invoice PDF
 * @param {{ styles: {}, users: []}} props
 * @returns {View} Users
 */
export default function CollectionStatePdfUsersSection({ styles, users }) {
  return (
    <View style={{ ...styles.row, ...styles.rowInvoiceUsers }}>
      {users.map((item) => {
        return (
          <View key={item.id} style={styles.information}>
            {item.title && <Text style={styles.textText}>{item.title}</Text>}
            {item.address && (
              <Text style={styles.textText}>{item.address}</Text>
            )}
            {item.email && <Text style={styles.textText}>{item.email}</Text>}
            {item.phone && <Text style={styles.textText}>{item.phone}</Text>}
            {item.iban && <Text style={styles.textText}>{item.iban}</Text>}
          </View>
        );
      })}
    </View>
  );
}
