import ContentLoadingProgress from "./../../shared/progress/ContentLoadingProgress";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../../../api/axios";
import { Column, FormattedColumn } from "../../shared/tables/Datatable";
import CostsPerConsultation from "./CostPerConsultation";
import { locations } from "../../../constants/consultation-constants";
import CollapsingTable from "../../shared/tables/CollapsingTable";
import { urlGetConsultationsPatientIdTherapistIdUninvoicedCosts } from "../../../api/urls/consultation";

export default function ConsultationCostsStep({
  patientId,
  therapistId,
  setFailed,
  selected,
  setSelected,
}) {
  const [t] = useTranslation(["consultations", "translations"]);

  const [isLoading, setIsLoading] = useState(true);

  const [consultations, setConsultations] = useState();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);

  /**
   * Columns for the consultations table
   * @type {Column[] | FormattedColumn[]} Columns or FormattedColumns
   */
  // prettier-ignore
  const columns = [
    new FormattedColumn(1, "startTime", t("tables.columns.onlyDate"), 80, (value) => moment(value).format("DD/MM/YYYY")),
    new FormattedColumn(2, "startTime", t("tables.columns.onlyStartTime"), 80, (value) => moment(value).format("HH:MM")),
    new FormattedColumn(3, "endTime", t("tables.columns.onlyEndTime"), 80, (value) => moment(value).format("HH:MM")),
    new Column(4, "location", t("tables.columns.location"), 50),
  ];

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    let url = urlGetConsultationsPatientIdTherapistIdUninvoicedCosts(
      patientId,
      therapistId
    );
    url += `&PageNumber=${page + 1}`;
    url += `&PageSize=${pageSize}`;
    axiosInstance
      .get(url, {
        setsRequestCancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        setConsultations(response.data.data.consultationDtos);

        //Adding all cost on init, so they're all checked
        response.data.data.consultationDtos.forEach((element) => {
          element.costs.forEach((element) => {
            selected.add(element.id);
          });
        });

        setPage(response.data.pageNumber - 1);
        setPageSize(response.data.pageSize);
        setRowCount(response.data.totalRecords);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setFailed(t("snackbars.failure.getconsultationcosts"));
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [page, pageSize, patientId, setFailed, t, therapistId]);

  /**
   * Parses the consultations for the CollpasingTable
   * @param {[]} cs consultations
   * @returns parsed consultations
   */
  const mapConsultationsWithUninvoicedCosts = (cs) => {
    return cs.map((c) => {
      return {
        startTime: new Date(c.startTime),
        endTime: new Date(c.endTime),
        location: t(`${locations.find((l) => l.value === c?.location)?.label}`, { ns: "translations" }),
        id: c.id,
        collapsingContent: (
          <>
            <CostsPerConsultation
              consultationId={c.id}
              setFailed={setFailed}
              selected={selected}
              setSelected={setSelected}
            />
          </>
        ),
      };
    });
  };

  if (isLoading) {
    return <ContentLoadingProgress />;
  }

  return (
    <>
      {consultations && (
        <>
          {consultations.length === 0 && (
            <p>{t("tables.noconsultationsforpatient")}</p>
          )}
          {consultations.length !== 0 && (
            <CollapsingTable
              rows={mapConsultationsWithUninvoicedCosts(consultations)}
              columns={columns}
              pageSize={pageSize}
              page={page}
              setPage={setPage}
              setPageSize={setPageSize}
              tableSizeSmall={true}
              tableCellAlign="left"
              rowCount={rowCount}
            />
          )}
        </>
      )}
    </>
  );
}
