/**
 * A row in an IconTable
 * @see {@link IconTable}
 */
export class IconTableRow {
  constructor(id, icon, title, value) {
    this.id = id;
    this.icon = icon;
    this.title = title;
    this.value = value;
  }
}
