import { genderTypes } from "../../constants/user-constants";

/**
 * Maps gender Enum from backend to text
 * @param {number} value
 * @returns {string} gender
 */
function MapGenderType(value) {
  return genderTypes.find((t) => t.value === value)?.label;
}

export default MapGenderType;
