import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "@mui/material";
import { useHistory } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import Header from "../../shared/default/Header";
import InvoicesFilterBar from "./InvoicesFilterBar";
import InvoicesOverview from "./InvoicesOverview";
import { urlGetPatientId } from "./../../../api/urls/patient";
import { axiosInstance, handleAxiosError } from "./../../../api/axios";
import PageLoading from "./../../shared/loading/PageLoading";

/**
 * Overview of invoices with filtering
 * @returns {JSX.Element}
 */
export default function FilteredInvoicesOverview({ therapistId, customerId }) {
  const [t] = useTranslation("invoices");
  const history = useHistory();

  const [showFilters, setShowFilters] = useState(false);
  const [customer, setCustomer] = useState();
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();

  // Filters
  const [isProcessedFilter, setIsProcessedFilter] = useState();
  const [isOverDueFilter, setIsOverDueFilter] = useState();
  const [isPaidFilter, setIsPaidFilter] = useState();
  const [isCreditNoteFilter, setIsCreditNoteFilter] = useState();
  const [dateFromFilter, setDateFromFilter] = useState();
  const [dueDateFromFilter, setDueDateFromFilter] = useState();
  const [dateUntilFilter, setDateUntilFilter] = useState();
  const [dueDateUntilFilter, setDueDateUntilFilter] = useState();
  const [numberFilter, setNumberFilter] = useState();
  const [referenceFilter, setReferenceFilter] = useState();
  const [nameFilter, setNameFilter] = useState();

  /**
   * If customerId (= patientId) is available it will first get the details of the patient so header title references to the patient
   */
  useEffect(() => {
    if (customerId === undefined) setIsLoadingPage(false);
    else {
      /**
       * Get customer (patient)
       */
      const url = urlGetPatientId(customerId);
      axiosInstance
        .get(url)
        .then((response) => {
          setCustomer(response.data);
          setIsLoadingPage(false);
        })
        .catch((error) =>
          setErrorLoadingPage({
            title: "error.request.get.customer",
            axiosError: handleAxiosError(error),
          })
        );
    }

    return () => {};
  }, [customerId]);

  /**
   * Actions for the header
   * @returns {Tooltip | Button[]}
   */
  const headerItems = () => {
    const items = [];
    // Filters
    if (showFilters) {
      items.push(
        <Tooltip title={t("tables.filters.hide")}>
          <Button variant="outlined" onClick={() => setShowFilters(false)}>
            {t("tables.filters.hide")}
          </Button>
        </Tooltip>
      );
    } else {
      items.push(
        <Tooltip title={t("tables.filters.show")}>
          <Button variant="outlined" onClick={() => setShowFilters(true)}>
            {t("tables.filters.show")}
          </Button>
        </Tooltip>
      );
    }
    /**
     * If customerId (= patientId) is available it will directly go to the AddInvoiceWizard.
     * If not it will first go to /invoices/new to first select a user and then go to the AddInvoiceWizard.
     */
    if (customerId !== undefined) {
      items.push(
        <Tooltip title={t("tooltips.title.create")}>
          <Button
            variant="outlined"
            onClick={() => history.push(`/patients/${customerId}/invoices/new`)}
            startIcon={<AddIcon />}
          >
            {t("tooltips.title.create")}
          </Button>
        </Tooltip>
      );
    } else {
      items.push(
        <Tooltip title={t("tooltips.title.create")}>
          <Button
            variant="outlined"
            onClick={() => history.push(`/invoices/new`)}
            startIcon={<AddIcon />}
          >
            {t("tooltips.title.create")}
          </Button>
        </Tooltip>
      );
    }
    return items;
  };

  if (isLoadingPage) {
    return (
      <PageLoading isLoading={isLoadingPage} errorLoading={errorLoadingPage} />
    );
  }

  return (
    <>
      <Header
        title={
          customerId !== undefined && customer
            ? t("header.title.invoicestherapistpatient", {
                patientName: `${customer.userProfile.firstName} ${customer.userProfile.lastName}`,
              })
            : t("header.title.invoicestherapist")
        }
        items={headerItems()}
      />
      {showFilters === true && (
        <InvoicesFilterBar
          setIsProcessedFilter={setIsProcessedFilter}
          setIsOverDueFilter={setIsOverDueFilter}
          setIsPaidFilter={setIsPaidFilter}
          setIsCreditNoteFilter={setIsCreditNoteFilter}
          setDateFilter={([from, until]) => {
            setDateFromFilter(
              from ? moment(from).format("YYYY-MM-DD") : undefined
            );
            setDateUntilFilter(
              until ? moment(until).format("YYYY-MM-DD") : undefined
            );
          }}
          setDueDateFilter={([from, until]) => {
            setDueDateFromFilter(
              from ? moment(from).format("YYYY-MM-DD") : undefined
            );
            setDueDateUntilFilter(
              until ? moment(until).format("YYYY-MM-DD") : undefined
            );
          }}
          setNumberFilter={setNumberFilter}
          setReferenceFilter={setReferenceFilter}
          setNameFilter={setNameFilter}
        />
      )}
      <InvoicesOverview
        therapistId={therapistId}
        customerId={customerId}
        isProcessedFilter={isProcessedFilter}
        isOverDueFilter={isOverDueFilter}
        isPaidFilter={isPaidFilter}
        isCreditNoteFilter={isCreditNoteFilter}
        dateFromFilter={dateFromFilter}
        dueDateFromFilter={dueDateFromFilter}
        dateUntilFilter={dateUntilFilter}
        dueDateUntilFilter={dueDateUntilFilter}
        numberFilter={numberFilter}
        referenceFilter={referenceFilter}
        nameFilter={nameFilter}
      />
    </>
  );
}
