import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import SimpleAlert from "./SimpleAlert";

/**
 * Error Alert
 *
 * This component is used to display a simple error on the screen for the user.
 * It uses the {@link SimpleAlert} component.
 *
 * The available props are title and description.
 * If no props are given it will display the standard "error.title" & "error.description" given in the translation file.
 *
 * @param { { title: string | undefined, description: string | undefined, translate: boolean } } props
 * @returns {JSX.Element} {@link SimpleAlert}
 */
export default function ErrorAlert({ title, description, translate = false }) {
  const [t] = useTranslation("alerts");
  const defaultValues = {
    title: t("error.title"),
    description: t("error.description"),
  };

  /**
   * Based on the translate property it will choose the right title.
   * If title undefined, it will take the default value.
   * @returns {string} title
   */
  // prettier-ignore
  const getTitle = () => title ? (translate ? t(title) : title) : defaultValues.title;

  /**
   * Based on the translate property it will choose the right description.
   * If description undefined, it will take the default value.
   * @returns {string} description
   */
  // prettier-ignore
  const getDescription = () => description ? (translate ? t(description) : description) : defaultValues.description;

  return (
    <SimpleAlert
      title={getTitle()}
      description={getDescription()}
      severity="error"
    />
  );
}

ErrorAlert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  translate: PropTypes.bool,
};
