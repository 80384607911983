import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../../../api/axios";
import { urlGetInvoiceByIdInvoiceLines } from "../../../api/urls/invoice";
import useAuth from "../../../auth/useAuth";
import { checkOrUnCheckIcon } from "../../../helpers/checkOrUncheckIcon";
import {
  mapCompensationType,
  mapValutaType,
} from "../../../helpers/enumMappers/index";
import ConfirmationModal from "../../shared/menus/modals/ConfirmationModal";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import ContentLoadingProgress from "../../shared/progress/ContentLoadingProgress";
import DataTable, { Column } from "../../shared/tables/Datatable";
import CreateInvoiceLineModal from "../shared/CreateInvoiceLineModal";
import { urlDeleteInvoicelineById } from "./../../../api/urls/invoiceline";
import SuccesSnackbar from "./../../shared/menus/snackbars/SuccesSnackbar";

/**
 * Tab content with invoiceLines of the invoice
 * @param {props} props
 * @returns {JSX.Element}
 */
export default function InvoiceOverviewLines({
  invoiceId,
  invoicePatientId,
  canInvoiceLinesBeDeleted,
  canInvoiceBeEdited,
}) {
  const [t] = useTranslation(["invoices", "translations"]);
  const [invoiceLines, setInvoiceLines] = useState(true);
  const [changed, setChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [failed, setFailed] = useState();
  const [succes, setSucces] = useState();
  // eslint-disable-next-line no-unused-vars
  const [addInvoiceLinePrompted, setAddInvoiceLinePrompted] = useState(false);
  const [deletePrompted, setDeletePrompted] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const { id } = useAuth();

  /**
   * Columns for the invoice lines table
   * @returns {Column[]} columns
   */
  // prettier-ignore
  const columns = [
    new Column(0, "title", t("tables.columns.lines.title"), 80),
    new Column(1, "costAmount", t("tables.columns.lines.costAmount"), 80),
    new Column(2, "compensationType", t("tables.columns.lines.compensationType"), 80),
    new Column(3, "complementaryPayBackAmount", t("tables.columns.lines.complementaryPayBackAmount"), 80),
    new Column(4, "hasBeenCredited", t("tables.columns.lines.hasBeenCredited"), 80),
    new Column(5, "actions", "", 80),
  ];

  /**
   * Fetch the invoice lines with the invoice id it gets from the props
   */
  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    let url = urlGetInvoiceByIdInvoiceLines(invoiceId);
    url += `?PageNumber=${page + 1}`;
    url += `&PageSize=${pageSize}`;
    axiosInstance
      .get(url, {
        setsRequestCancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        setInvoiceLines(response.data.data.invoiceLines);
        setPage(response.data.pageNumber - 1);
        setPageSize(response.data.pageSize);
        setRowCount(response.data.totalRecords);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setFailed(t("snackbars.failure.lines.get"));
      });
    return () => {};
  }, [invoiceId, page, pageSize, setFailed, t, changed]);

  /**
   * This function will change the state of "changed" so the useEffect will execute again.
   */
  const updateInvoiceLines = () => setChanged(!changed);

  /**
   * Maps invoice lines for table
   * @param {[]} invoiceLines
   * @returns {[]} parsedInvoiceLines
   */
  // prettier-ignore
  const mapInvoiceLines = (invoiceLines) => {
    // The deletable is the same for each invoiceline
    const isDeleteable = canInvoiceLinesBeDeleted();
    return invoiceLines.map((line) => {
      return {
        id: line.id,
        title: line.costDto.title,
        info: line.info,
        costAmount: mapValutaType(line.costDto.valuta) + " " + line.costDto.costAmount,
        compensationType: t(mapCompensationType(line.costDto?.compensationType),  { ns: "translations" }),
        complementaryPayBackAmount: line.costDto.complementaryPayBackAmount,
        // hasBeenCredited has been removed 
        // we now have to check on the line.creditNoteId (null | number)
        hasBeenCredited: line.creditNoteId === null ? checkOrUnCheckIcon(false) : checkOrUnCheckIcon(true),
        actions: (
          <>
            <Button
              disabled={!isDeleteable}
              onClick={() => setDeletePrompted(line.id)}
            >
              <DeleteIcon />
            </Button>
          </>
        ),
      };
    });
  };

  /**
   * This function will close the AddInvoiceLinePopup and will execute nothing else.
   */
  const closeCreateInvoiceLineModal = () => setAddInvoiceLinePrompted(false);

  /**
   * Handle succes of create invoice line
   */
  const handleSuccessCreateInvoiceLine = () => {
    setSucces(true);
    updateInvoiceLines();
  };

  /**
   * Handle if create invoice line failed
   * @param {string} message
   */
  const handleFailedCreateInvoiceLine = (message) => {
    closeCreateInvoiceLineModal();
    setFailed(message);
  };

  /**
   * Delete invoice line by id
   * @param {number} id
   */
  const deleteInvoiceLine = (invoiceLineId) => {
    const url = urlDeleteInvoicelineById(invoiceLineId);
    axiosInstance
      .delete(url)
      .then((response) => {
        setSucces(true);
        updateInvoiceLines();
      })
      .catch((error) => {
        console.error(error);
        setFailed(t("snackbars.failure.lines.delete"));
      });
  };

  if (isLoading) {
    return <ContentLoadingProgress />;
  }

  return (
    <>
      {invoiceLines && (
        <>
          {canInvoiceBeEdited() === true && (
            <div style={{ marginBottom: "1.5em" }}>
              {/* Closed & can add invoice line*/}
              {!addInvoiceLinePrompted && (
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!canInvoiceBeEdited}
                  onClick={() => setAddInvoiceLinePrompted(true)}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  {t("detail.addline")}
                </Button>
              )}
              {/* Opened */}
              {addInvoiceLinePrompted && (
                <CreateInvoiceLineModal
                  open={!!addInvoiceLinePrompted}
                  close={closeCreateInvoiceLineModal}
                  invoiceId={invoiceId}
                  patientId={invoicePatientId}
                  therapistId={id}
                  handleSuccess={handleSuccessCreateInvoiceLine}
                  handleFailed={handleFailedCreateInvoiceLine}
                  setFailed={setFailed}
                />
              )}
            </div>
          )}
          {invoiceLines.length === 0 && <p>{t("tables.empty.invoicelines")}</p>}
          {invoiceLines.length > 0 && (
            <DataTable
              rows={mapInvoiceLines(invoiceLines)}
              columns={columns}
              createSortHandler={() => {}}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              rowCount={rowCount}
            />
          )}
        </>
      )}
      <ConfirmationModal
        open={!!deletePrompted}
        handleClose={() => setDeletePrompted(false)}
        command={() => deleteInvoiceLine(deletePrompted)}
        content={t("modal.delete.lines.content")}
        title={t("modal.delete.lines.title")}
        positivetext={t("modal.delete.yes")}
        negativetext={t("modal.delete.no")}
      />
      <SuccesSnackbar
        open={succes}
        setOpen={setSucces}
        text={t("snackbars.succes.succes")}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
