/**
 * Checks if env variable has been set
 * If there is a env key called REACT_APP_INSIGHTS_INSTRUMENTATION_KEY it will return true
 * @returns {boolean} enabled
 */
export function isAppInsigthsEnabled() {
  const instrumentationKey = process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY;
  let enabled = false;
  if (
    instrumentationKey &&
    instrumentationKey !== null &&
    instrumentationKey !== ""
  )
    enabled = true;
  return enabled;
}

/**
 * This will call the isAppInsightsEnabled but will also log information at startup
 * @see {@link isAppInsightsEnabeld}
 * @returns {boolean} enabled
 */
export function isRootAppInsightsEnabled() {
  const enabled = isAppInsigthsEnabled(true);
  if (process.env.NODE_ENV !== "production") {
    console.log("isAppInsightsEnabeld", enabled);
    if (enabled) {
      const instrumentationKey =
        process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY;
      console.log("instrumentationKey", instrumentationKey);
    }
  }
  return enabled;
}
