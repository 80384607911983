import { Alert, AlertTitle } from "@mui/material";

/**
 * Simple Page Alert
 * @param {{ title: string, description: string | undefined, severity: string | undefined, variant: string | undefined, action: Button | undefined }} props
 * @returns {Alert} Alert
 */
export default function SimpleAlert({
  title,
  description,
  severity = "info",
  variant,
  action,
}) {
  return (
    <Alert
      severity={severity}
      variant={variant && variant}
      action={action && action}
      sx={{ marginBottom: "1em" }}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {description && description}
    </Alert>
  );
}
