import { Divider, Stack } from "@mui/material";

function BasicButtonBar({
  mainItems,
  secondaryItems,
  justifyContent = "end",
  ...restprops
}) {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent={justifyContent}
      divider={<Divider orientation="vertical" flexItem />}
      {...restprops}
    >
      {mainItems && mainItems}
      {secondaryItems && secondaryItems}
    </Stack>
  );
}
export default BasicButtonBar;
