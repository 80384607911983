import { valutaTypes } from "../../constants/cost-constants";

/**
 * Maps valuta Enum from backend to symbol
 * @param {number} value
 * @returns {string} symbol
 */
export default function mapValutaType(value) {
  return valutaTypes.find((t) => t.value === value).label;
}
