import { Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { axiosInstance, handleAxiosError } from "../../../api/axios";
import { urlGetInvoicesByTherapistId } from "../../../api/urls/invoice";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import SuccesSnackbar from "../../shared/menus/snackbars/SuccesSnackbar";
import PageLoading from "./../../shared/loading/PageLoading";
import InvoiceList from "./InvoiceList";
import { applyFilters } from "../../../helpers/filters/applyFilters";

/**
 * Overview of invoices
 * @returns {JSX.Element}
 */
export default function InvoicesOverview({
  therapistId,
  customerId,
  isProcessedFilter,
  isOverDueFilter,
  isPaidFilter,
  isCreditNoteFilter,
  dateFromFilter,
  dueDateFromFilter,
  dateUntilFilter,
  dueDateUntilFilter,
  numberFilter,
  referenceFilter,
  nameFilter
}) {
  const [t] = useTranslation("invoices");
  const history = useHistory();

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();

  const [succes, setSucces] = useState();
  const [failed, setFailed] = useState();

  // Invoices
  const [invoices, setInvoices] = useState();
  const [changed, setChanged] = useState(false);
  const updateList = () => setChanged(!changed);

  // Pagination
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("NUMBER");

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    let url = urlGetInvoicesByTherapistId(therapistId);
    url += `?PageNumber=${page + 1}`;
    url += `&PageSize=${pageSize}`;
    if (customerId !== undefined) url += `&CustomerId=${customerId}`;
    if (orderBy !== undefined) url += `&OrderBy=${orderBy}`;
    // prettier-ignore
    if (order !== undefined) url += `&IsAscending=${order === "asc" ? true : false}`;
    url += applyFilters([
      { queryName: "IsProcessed", filterObject: isProcessedFilter },
      { queryName: "IsOverDue", filterObject: isOverDueFilter },
      { queryName: "IsPaid", filterObject: isPaidFilter },
      { queryName: "IsCreditNote", filterObject: isCreditNoteFilter },
      { queryName: "DateFrom", filterObject: dateFromFilter },
      { queryName: "DueDateFrom", filterObject: dueDateFromFilter },
      { queryName: "DateUntil", filterObject: dateUntilFilter },
      { queryName: "DueDateUntil", filterObject: dueDateUntilFilter },
      { queryName: "Number", filterObject: numberFilter },
      { queryName: "Reference", filterObject: referenceFilter },
      { queryName: "Name", filterObject: nameFilter}
    ]);

    axiosInstance
      .get(url, {
        setsRequestCancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const pagedResponse = response.data;
        setInvoices(pagedResponse.data.invoiceDtos);
        setPage(pagedResponse.pageNumber - 1);
        setPageSize(pagedResponse.pageSize);
        setRowCount(pagedResponse.totalRecords);
        setIsLoadingPage(false);
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: t("error.request.get.invoices"),
          axiosError: handleAxiosError(error),
        })
      );

    return () => {
      cancelTokenSource.cancel();
    };
  }, [
    history,
    t,
    therapistId,
    customerId,
    page,
    pageSize,
    order,
    orderBy,
    isProcessedFilter,
    isOverDueFilter,
    isPaidFilter,
    dateFromFilter,
    dueDateFromFilter,
    dateUntilFilter,
    dueDateUntilFilter,
    isCreditNoteFilter,
    numberFilter,
    referenceFilter,
    nameFilter
  ]);

  if (isLoadingPage) {
    return (
      <PageLoading isLoading={isLoadingPage} errorLoading={errorLoadingPage} />
    );
  }

  return (
    <>
      {invoices && !invoices.length ? (
        <Typography variant="caption" display="block" gutter={2}>
          {t("messages.noInvoices")}
        </Typography>
      ) : (
        <InvoiceList
          invoices={invoices}
          changed={changed}
          updateList={updateList}
          page={page}
          rowCount={rowCount}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={(val) => setOrderBy(val)}
        />
      )}
      <SuccesSnackbar
        open={succes}
        setOpen={setSucces}
        text={t("snackbars.succes.succes")}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
