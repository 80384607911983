import { Text, View } from "@react-pdf/renderer";
/**
 * Invoice default note for the Invoice PDF
 * @param {{ styles: {}, therapist: {}}} props
 * @returns {View} Pay
 */
export default function InvoicePdfDefaultNote({ styles, defaultNote }) {
  return (
    <>
      {defaultNote ? (
        <View style={[styles.row, styles.rowExportNote]}>
          <Text style={styles.textText}>{defaultNote}</Text>
        </View>
      ) : null}
    </>
  );
}
