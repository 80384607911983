import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import GenericIconButton from "./GenericIconButton";

function UnfoldButton({ tooltip, onClick, open, ...restprops }) {
  return (
    <>
      {tooltip && onClick && (
        <GenericIconButton
          color="primary"
          tooltip={tooltip}
          onClick={onClick}
          {...restprops}
        >
          {open === true ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
        </GenericIconButton>
      )}
    </>
  );
}
export default UnfoldButton;
