import { Checkbox, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import React from "react";
import { useTranslation } from "react-i18next";

export const FormCheckbox = ({
  name,
  control,
  label,
  errors,
  margin,
  displayAsRequired = false,
}) => {
  const { t } = useTranslation("forms");
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <>
          <div>
            <label>{`${label}${displayAsRequired ? " *" : ""}`}</label>
            <Checkbox
              margin={margin ?? "normal"}
              variant="outlined"
              onChange={onChange}
              value={value ?? false}
              label={label}
              error={errors}
              checked={value ?? ""}
            />

            {!!errors && errors.message && (
              <FormHelperText error>{t(errors.message)}</FormHelperText>
            )}
          </div>
        </>
      )}
    />
  );
};
