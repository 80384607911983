import CreateAgendaItemTypeScreen from "./../../screens/CreateAgendaItemTypeScreen";
import SchedulerScreen from "./../../screens/SchedulerScreen";

/**
 * @type {Array<{path: string, screen: JSX.Element}>}
 */
// prettier-ignore
export const routesScheduler = [
    { path: "/scheduler/:agendaitemId/new", screen: <CreateAgendaItemTypeScreen />, },
    { path: "/scheduler", screen: <SchedulerScreen />, },
];
