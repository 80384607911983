export const parsePatientResponse = (response, patientId) => {
  let patient = response.data.userProfile;
  return {
    patient: {
      userId: patientId,
      label: `${patient.firstName} ${patient.lastName}`,
      firstName: patient.firstName,
      lastName: patient.lastName,
    },
  };
};
