import { Tooltip } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";

/**
 * Returns a icon for the specific invoice
 * @param {Invoice} invoice
 * @returns {Tooltip} Tooltip with Material UI Icon
 */
export function invoiceTypeIcon(invoice) {
  return invoice.isCreditNote === false ? (
    <Tooltip title={"Invoice"}>
      <ReceiptIcon color="primary" />
    </Tooltip>
  ) : (
    <Tooltip title={"Creditnote"}>
      <ReceiptIcon color="secondary" />
    </Tooltip>
  );
}
