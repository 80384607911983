import i18next from "i18next";
import common_en from "./en/common.json";
import consultation_en from "./en/consultations.json";
import dashboard_en from "./en/dashboard.json";
import wizard_en from "./en/wizard.json";
import settings_en from "./en/settings.json";
import invoices_en from "./en/invoices.json";
import costs_en from "./en/costs.json";
import agendaItem_en from "./en/agendaItem.json";
import scheduler_en from "./en/scheduler.json";
import patients_en from "./en/patients.json";
import therapists_en from "./en/therapists.json";
import alerts_en from "./en/alerts.json";
import forms_en from "./en/forms.json";
import en from "./en.json";

import common_nl from "./nl/common.json";
import consultation_nl from "./nl/consultations.json";
import dashboard_nl from "./nl/dashboard.json";
import wizard_nl from "./nl/wizard.json";
import settings_nl from "./nl/settings.json";
import invoices_nl from "./nl/invoices.json";
import costs_nl from "./nl/costs.json";
import agendaItem_nl from "./nl/agendaItem.json";
import scheduler_nl from "./nl/scheduler.json";
import patients_nl from "./nl/patients.json";
import therapists_nl from "./nl/therapists.json";
import alerts_nl from "./nl/alerts.json";
import forms_nl from "./nl/forms.json";
import nl from "./nl.json";

import common_de from "./de/common.json";
import consultation_de from "./de/consultations.json";
import dashboard_de from "./de/dashboard.json";
import wizard_de from "./de/wizard.json";
import settings_de from "./de/settings.json";
import invoices_de from "./de/invoices.json";
import costs_de from "./de/costs.json";
import agendaItem_de from "./de/agendaItem.json";
import scheduler_de from "./de/scheduler.json";
import patients_de from "./de/patients.json";
import therapists_de from "./de/therapists.json";
import alerts_de from "./de/alerts.json";
import forms_de from "./de/forms.json";
import de from "./de.json";

const setupTranslations = () => {
  i18next.init({
    interpolation: {
      escapeValue: false
    }, // React does escaping for us
    resources: {
      en: {
        common: common_en, // 'common' is our custom namespace,
        alerts: alerts_en,
        consultations: consultation_en,
        forms: forms_en,
        dashboard: dashboard_en,
        wizard: wizard_en,
        settings: settings_en,
        invoices: invoices_en,
        costs: costs_en,
        agendaitem: agendaItem_en,
        scheduler: scheduler_en,
        patients: patients_en,
        therapists: therapists_en,
        translations: en,
      },
      nl: {
        common: common_nl, // 'common' is our custom namespace,
        alerts: alerts_nl,
        consultations: consultation_nl,
        forms: forms_nl,
        dashboard: dashboard_nl,
        wizard: wizard_nl,
        settings: settings_nl,
        invoices: invoices_nl,
        costs: costs_nl,
        agendaitem: agendaItem_nl,
        scheduler: scheduler_nl,
        patients: patients_nl,
        therapists: therapists_nl,
        translations: nl,
      },
      de: {
        common: common_de, // 'common' is our custom namespace,
        alerts: alerts_de,
        consultations: consultation_de,
        forms: forms_de,
        dashboard: dashboard_de,
        wizard: wizard_de,
        settings: settings_de,
        invoices: invoices_de,
        costs: costs_de,
        agendaitem: agendaItem_de,
        scheduler: scheduler_de,
        patients: patients_de,
        therapists: therapists_de,
        translations: de,
      },
    },
  });
};
export default setupTranslations;