import { FormHelperText, MenuItem, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const FormDropdownStandardValue = ({
  name,
  control,
  label,
  options,
  errors,
  displayAsRequired = false,
  margin,
  value,
  setValue,
}) => {
  const generateSelectOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem
          key={option.id}
          value={option.value}
          disabled={option.id === -1}
        >
          {option.label}
        </MenuItem>
      );
    });
  };

  const { t } = useTranslation("forms");
  return (
    <Controller
      control={control}
      name={name}
      render={() => (
        <>
          <TextField
            sx={{ minWidth: 120 }}
            select
            label={`${label}${displayAsRequired ? " *" : ""}`}
            margin={margin ?? "normal"}
            variant="outlined"
            onChange={(event) => setValue(event.target.value)}
            value={value ?? ""}
            error={!!errors}
          >
            {generateSelectOptions()}
          </TextField>

          {!!errors && errors.message && (
            <FormHelperText error>{t(errors.message)}</FormHelperText>
          )}
        </>
      )}
    />
  );
};
