import FormModal from "./../../shared/menus/modals/FormModal";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, number } from "yup";
import { Grid } from "@mui/material";
import { costTypes, legalCostTypes } from "../../../constants/cost-constants";
import { FormDropdown } from "../../shared/forms/form-elements/FormDropdown";
import { FormTextfield } from "../../shared/forms/form-elements/FormTextfield";
import { FormNumberfield } from "./../../shared/forms/form-elements/FormNumberField";

/**
 * A yup validation schema
 * @returns {object} Schema
 */

export default function CreateConsultationCostModal({
  open,
  close,
  setFailed,
  handleSucces,
}) {
  const [t] = useTranslation("costs");

  //Cost schema
  const costSchema = object({
    title: string().required("error.required").default(""),
    costType: number("error.number").required("error.required").min(0),
    paybackType: number("error.number"),
    paybackAmount: number("error.number").min(0),
    amount: string()
      .matches(/^[0-9]*\.?[0-9]*$/, t("amounterror")) //todo translate
      .required(),
  }).required();

  const {
    control,
    /*   formState :{  errors },*/
    handleSubmit,
    watch,
    reset,
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(costSchema),
  });

  /**
   * Object will change if costType changes in the form
   * @type {string} current costType
   */
  const watchCostType = watch({
    control,
    name: "costType",
  });

  useEffect(() => {
    // Default values can for some reason not be set in the yup schema
    reset({
      title: "",
      costType: -1,
      paybackType: 0,
      paybackAmount: 0,
      amount: 0,
    });
    return () => {};
  }, [reset]);

  /**
   * Handle form when exportNotes is valid
   * @param {{exportNotes: string}} validObject
   */
  const handleValidSubmit = (validObject) => handleSucces(validObject);

  /**
   * Handle form when exportNotes is unValid
   * @param {{}} errors
   */
  // eslint-disable-next-line no-unused-vars
  const handleUnValidSubmit = (errors) => {
    setFailed(t("modal.add.error"));
  };

  return (
    <>
      <FormModal
        open={open}
        title={t("modal.add.title")}
        handleClose={close}
        handleSubmit={handleSubmit(handleValidSubmit, handleUnValidSubmit)}
        submitButtonContent={t("modal.add.submit")}
        closeButtonContent={t("modal.add.cancel")}
        content={
          <>
            <fieldset style={styles.fieldset}>
              <Grid container direction="column">
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <FormTextfield
                        label={t("fields.cost.title")}
                        name="title"
                        control={control}
                        //errors={errors}
                      />
                    </Grid>
                    <Grid item>
                      <FormDropdown
                        label={t("fields.cost.type")}
                        name="costType"
                        control={control}
                        //errors={errors}
                        options={costTypes}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <FormNumberfield
                        label={t("fields.cost.amount")}
                        name="amount"
                        control={control}
                        //errors={errors}
                      />
                    </Grid>

                    {watchCostType.costType === 0 && (
                      <Grid item>
                        <FormDropdown
                          label={t("fields.cost.paybackType")}
                          name="paybackType"
                          control={control}
                          //errors={errors}
                          options={legalCostTypes}
                        />
                      </Grid>
                    )}
                    {watchCostType.costType === 101 && (
                      <Grid item>
                        <FormNumberfield
                          label={t("fields.cost.paybackAmount")}
                          name="paybackAmount"
                          control={control}
                          //errors={errors}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </fieldset>
          </>
        }
      />
    </>
  );
}

const styles = {
  form: {
    margin: "unset",
    width: "100%",
  },
  fieldset: {
    borderWidth: 0,
  },
  wrapperButtons: {
    display: "flex",
    justifyContent: "end",
  },
};
