import { Tab } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TabPanel from "../shared/tabs/TabPanel";
import VerticalTabs from "../shared/tabs/VerticalTabs";
import InvoiceSettings from "./InvoiceSettings";
import DefaultNoteSettings from "./DefaultNoteSettings";
import AddressSetting from "./AddressSetting";

export default function Settings() {
  const [t] = useTranslation("settings");
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = [
    <Tab label={t("settings.invoices")} key={0} />,
    <Tab label={t("settings.notes")} key={1} />,
    <Tab label={t("settings.address")} key={2} />,
  ];

  const tabPanels = [
    <TabPanel
      selectedTab={selectedTab}
      index={0}
      content={<InvoiceSettings />}
      key={0}
    />,
    <TabPanel
      selectedTab={selectedTab}
      index={1}
      content={<DefaultNoteSettings />}
      key={1}
    />,
    <TabPanel
      selectedTab={selectedTab}
      index={2}
      content={<AddressSetting />}
      key={2}
    />,
  ];

  var isNumber = function isNumber(value) {
    return typeof value === "number" && isFinite(value);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const value = parameters.get("key");
    if (value !== undefined && value !== null && isNumber(parseInt(value))) {
      setSelectedTab(parseInt(value));
    } else {
      setSelectedTab(0);
    }
  }, []);

  return (
    <>
      <VerticalTabs
        tabs={tabs}
        tabPanels={tabPanels}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </>
  );
}
