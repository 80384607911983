import AddIcon from "@mui/icons-material/Add";
import GenericIconButton from "./GenericIconButton";

function NewButton({ tooltip, onClick, ...restprops }) {
  return (
    <>
      {tooltip && (
        <GenericIconButton tooltip={tooltip} onClick={onClick} {...restprops}>
          <AddIcon />
        </GenericIconButton>
      )}
    </>
  );
}
export default NewButton;
