import useAuth from "../auth/useAuth";
import SchedulerPOC from "../components/scheduler/Scheduler";
import { pageViewConstants } from "../constants/insight-constants";
import { useAppInsightsContext } from "../insights/AppInsightsContext";
import { trackPageView } from "../insights/AppInsightsTrack";
import { isAppInsigthsEnabled } from "./../insights/isAppInsightsEnabled";

export default function SchedulerScreen() {
  const { id } = useAuth();

  const isAiEnabled = isAppInsigthsEnabled();
  const appInsights = useAppInsightsContext();
  const trackProps = {
    user: { id: id },
  };

  // prettier-ignore
  trackPageView(isAiEnabled, appInsights, pageViewConstants.SCHEDULER, trackProps);

  return <SchedulerPOC />;
}
