import PropTypes from "prop-types";
import { Stack } from "@mui/material";

Header.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
};

/**
 * Layout for the Header with a title and optionally some actions
 * @param {props} props
 * @returns {JSX.Element}
 */
export default function Header({ title, items = [] }) {
  return (
    <>
      <header style={styles.header}>
        <h2>{title}</h2>
        {items && (
          <>
            <Stack direction="row" spacing={2}>
              {items.map((item, index) => (
                <div key={index} style={styles.wrapper}>
                  {item}
                </div>
              ))}
            </Stack>
          </>
        )}
      </header>
    </>
  );
}

const styles = {
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actions: { display: "flex", flexDirection: "row" },
  wrapper: {},
};
