import axios from "axios";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import Header from "./../../shared/default/Header";
import FailureSnackbar from "./../../shared/menus/snackbars/FailureSnackbar";
import { axiosInstance, handleAxiosError } from "./../../../api/axios";
import CollectionStatePdf from "./CollectionStatePdf";
import PageLoading from "./../../shared/loading/PageLoading";
import { urlGetExportedCollectionState } from "./../../../api/urls/collectionstateinvoice";

export default function CollectionStateExportOverview() {
  const { invoiceId } = useParams();
  const [t] = useTranslation("invoices");

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();

  const [therapist, setTherapist] = useState();
  const [patient, setPatient] = useState();
  const [date, setDate] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [prescriptionNumber, setPrescriptionNumber] = useState();

  const [healthInsuranceFund, setHealthInsuranceFund] = useState();
  const [failed, setFailed] = useState();

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    const url = urlGetExportedCollectionState(invoiceId);
    axiosInstance
      .get(url)
      .then((response) => {

        const responseWithItems = response.data;

        setTotalAmount(responseWithItems.totalAmount);
        setDate(responseWithItems.date);
        setInvoiceNumber(responseWithItems.invoiceNumber);
        setPrescriptionNumber(responseWithItems.prescriptionNumber);
        setTherapist(responseWithItems.therapist);
        setPatient(responseWithItems.patientUserProfileDto);
        setHealthInsuranceFund(responseWithItems.healthInsuranceFundDto);
        setIsLoadingPage(false);
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: "error.request.get.collectionstate",
          axiosError: handleAxiosError(error),
        })
      );
    return () => {
      cancelTokenSource.cancel();
    };
  }, [invoiceId, t]);

  if (isLoadingPage) {
    return (
      <PageLoading
        header={<Header title={t("collectionstate.title")} />}
        isLoading={isLoadingPage}
        errorLoading={errorLoadingPage}
      />
    );
  }

  return (
    <>
      <Header title={`${t("collectionstate.title")}`} />
      {therapist &&
        patient &&
        date &&
        totalAmount &&
        invoiceNumber &&
        prescriptionNumber && (
          <CollectionStatePdf
            therapist={therapist}
            patient={patient}
            date={date}
            totalAmount={totalAmount}
            invoiceNumber={invoiceNumber}
            prescriptionNumber={prescriptionNumber}
            healthInsuranceFund={healthInsuranceFund}
          />
        )}

      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
