import { Page } from "../components/shared/default/Page";
import SelectPatientWithRoute from "../components/patients/selectPatient/SelectPatientWithRoute";
import { useTranslation } from "react-i18next";

/**
 * Create invoice for a patient screen
 * @returns {JSX.Element}
 */
export default function CreateInvoicePatientScreen() {
  const [t] = useTranslation("invoices");

  return (
    <Page
      content={
        <SelectPatientWithRoute
          header={{ title: t("header.title.createinvoicepatient") }}
          route={(patientId) => `/patients/${patientId}/invoices/new`}
        />
      }
    />
  );
}
