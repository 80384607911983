import { AuthProvider } from "./auth/useAuth";
import Dashboard from "./screens/Dashboard";
import "./scss/main.scss";
import { AppInsightsContextProvider } from "./insights/AppInsightsContext";
import { isRootAppInsightsEnabled } from "./insights/isAppInsightsEnabled";

export default function App() {
  /**
   * Variable will be true if applicatoin insights has been enabled
   * @type {boolean} enabled
   */
  const isAppInsightsEnabeld = isRootAppInsightsEnabled();

  /**
   * Childeren contains the components the user will see
   */
  const childeren = <Dashboard />;

  /**
   * Wraps the childeren around a {@link AuthProvider}
   * @param {JSX.Element} childeren 
   * @returns {AuthProvider} AuthProvidor with childeren
   */
  const authProvidor = (childeren) => <AuthProvider>{childeren}</AuthProvider>;

  /**
   * Wraps the childeren around a {@link AppInsightsContextProvider}
   * @param {JSX.Element} childeren 
   * @returns {AppInsightsContextProvider} AppInsightsContextProvider with childeren
   */
  const appInsightsContextProvidor = (childeren) => (
    <AppInsightsContextProvider>{childeren}</AppInsightsContextProvider>
  );

  if (isAppInsightsEnabeld && isAppInsightsEnabeld === true) {
    return authProvidor(appInsightsContextProvidor(childeren));
  } else {
    return authProvidor(childeren);
  }
}
