import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { axiosInstance } from "../../../api/axios";
import { locations } from "../../../constants/consultation-constants";
import { applyFilters } from "../../../helpers/filters/applyFilters";
import moment from "moment";
import DetailButton from "../../shared/iconButtons/DetailButton";
import EditButton from "../../shared/iconButtons/EditButton";
import ConfirmationModal from "../../shared/menus/modals/ConfirmationModal";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import SuccesSnackbar from "../../shared/menus/snackbars/SuccesSnackbar";
import { urlGetConsultations } from "./../../../api/urls/consultation";
import ContentLoadingProgress from "./../../shared/progress/ContentLoadingProgress";
import AbstractConsultationList from "./AbstractConsultationList";
import useAuth from "../../../auth/useAuth";
import { mapLocationType } from "../../../helpers/enumMappers"

/**
 * A consultation list has the responsability of getting the consultations from the api matching the filters handed down.
 * @returns a list of all consultations matching the parameters (paged, filtered) as consultation-list-items
 */
function CompleteConsultationList({
  locationFilter,
  fromFilter,
  untillFilter,
  setFilter,
  patientFilter,
  therapistFilter,
}) {
  const [t] = useTranslation(["consultations", "translations"]);

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState();
  const [succes, setSucces] = useState(false);
  const [failed, setFailed] = useState();
  const [deletePrompted, setDeletePrompted] = useState(false);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("STARTTIME");
  const { id } = useAuth();

  const onDelete = (value) => {
    axiosInstance
      .delete(`v2/consultations/${value}`)
      .then(setSucces(true))
      .catch((error) => {
        setFailed(`${t("snackbars.failure")} \n ${error.message}`);
      });
  };

  useEffect(() => {
    let mounted = true;
    const calculateRows = (data) => {
      return data?.data?.consultationDtos.map((cons) => {
        return {
          therapist: (
            <Button
              onClick={() =>
                history.push(`/therapists/${cons.therapist.userId}`)
              }
            >
              {`${cons.therapist.lastName.toUpperCase()} ${
                cons.therapist.firstName
              }`}
            </Button>
          ),
          patient: (
            <Button
              onClick={() => history.push(`/patients/${cons.patient.userId}`)}
            >
              {`${cons.patient.lastName.toUpperCase()} ${
                cons.patient.firstName
              }`}
            </Button>
          ),
          nomenclature: cons.nomenclature, 
          startTime: moment(new Date(cons.startTime)).format("DD/MM/YYYY HH:mm"),
          duration: `${Math.floor(
            (new Date(cons?.endTime) - new Date(cons?.startTime)) / 60000
          )} minutes `,
          location:  t(`${mapLocationType(cons?.location)}`, { ns: "translations" }),
          id: cons.id,
          actions: (
            <>
              <DetailButton
                onClick={() => history.push(`/consultations/${cons.id}/detail`)}
                tooltip={t("tooltips.detail")}
              />
              <EditButton
                onClick={() => history.push(`/consultations/${cons.id}/edit`)}
                tooltip={t("tooltips.edit")}
              />
            </>
          ),
        };
      });
    };

    let url = urlGetConsultations();
    url += `?PageNumber=${page + 1}`;
    url += `&PageSize=${pageSize}`;
    if (orderBy !== undefined) url += `&OrderBy=${orderBy}`;
    if (order !== undefined)
      url += `&IsAscending=${order === "asc" ? true : false}`;
    url += applyFilters([
      { queryName: "patientId", filterObject: patientFilter },
      { queryName: "therapistId", filterObject: id },
      { queryName: "Location", filterObject: locationFilter },
      { queryName: "from", filterObject: fromFilter },
      { queryName: "until", filterObject: untillFilter },
      { queryName: "ConsultationSetId", filterObject: setFilter },
    ]);

    axiosInstance
      .get(url)
      .then(function (response) {
        // handle success
        if (mounted) {
          setRows(calculateRows(response.data));
          setPage(response.data.pageNumber - 1);
          setPageSize(response.data.pageSize);
          setRowCount(response.data.totalRecords);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        // handle error
        if (mounted)
          setFailed(`${t("snackbars.data-failure")} \n ${error.message}`);
        setIsLoading(false);
      });
    return () => {
      mounted = false;
    };
  }, [
    history,
    t,
    page,
    pageSize,
    locationFilter,
    order,
    orderBy,
    fromFilter,
    untillFilter,
    setFilter,
    patientFilter,
    therapistFilter,
    succes,
    id
  ]);

  if (isLoading) {
    return <ContentLoadingProgress />;
  }

  return (
    <div className="card-list" style={{ marginLeft: 20, marginRight: 20 }}>
      {rows && rows.length ? (
        <AbstractConsultationList
          rows={rows}
          page={page}
          rowCount={rowCount}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={(val) => setOrderBy(val)}
        />
      ) : (
        <Typography variant="caption" display="block" gutter={2}>
          {t("messages.noConsultations")}
        </Typography>
      )}
      <ConfirmationModal
        open={!!deletePrompted}
        handleClose={() => setDeletePrompted(false)}
        command={() => onDelete(deletePrompted)}
        content={t("confirmations.remove.consultation")}
        title={t("labels.remove.consultation")}
      />
      <SuccesSnackbar
        open={!!succes}
        setOpen={setSucces}
        text={t("snackbars.succes")}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </div>
  );
}
export default CompleteConsultationList;
