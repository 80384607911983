import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: "light",
    mode: "light",
    primary: {
      dark: "#385E6E",
      main: "#51c0cf",
      light: "#6DACB5",
      extraLight: "#E3F1F8",
      contrastText: "#F2F2F2",
    },
    secondary: {
      main: "#545454",
      light: "#8d8d8d",
      dark: "#444444",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#FFFFFF",
    },
    grey: {
      light: "#EEEDED",
      main: "#8d8d8d",
      dark: "#444444",
    },
    main: "#51c0cf",
  },
  typography: {
    fontSize: 15,
    fontWeight: 600,
    caption: {
      color: "darkgrey",
    },
  },
  sizing: {
    drawerwidth: 240,
  },
  overrides: {
    MuiPaper: {
      root: {
        padding: "20px 10px",
        margin: "10px",
        backgroundColor: "#fff", // 5d737e
      },
    },
    MuiButton: {
      root: {
        margin: "5px",
      },
    },
  },
});
export default theme;
