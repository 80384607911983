import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, number } from "yup";
import { FormNumberfield } from "./../../shared/forms/form-elements/FormNumberField";
import useAuth from "../../../auth/useAuth";
import { axiosInstance } from "./../../../api/axios/axiosInstance";
import axios from "axios";
import ContentLoadingProgress from "../../shared/progress/ContentLoadingProgress";
import {
  urlPostInvoiceCreditnote,
  urlGetLatestTherapistInvoiceNumber,
  urlGetCheckValidInvoiceNumberForTherapist,
} from "../../../api/urls/invoice";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";

const invoiceNumberSchema = object({
  number: number().required().min(0).default(0),
}).required();

export default function CreateCreditnoteModal({
  open,
  close,
  invoiceId,
  therapistId,
  handleSucces,
  handleFailed,
  setFailed,
}) {
  const [t] = useTranslation("invoices");
  const { id } = useAuth();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(invoiceNumberSchema),
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    /**
     * Get latest invoice number for therapist
     */
    const url = urlGetLatestTherapistInvoiceNumber(therapistId);
    axiosInstance
      .get(url, {
        setsRequestCancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        setValue("number", Number(response.data.invoiceNumber), {
          shouldValidate: false,
        });
        setIsLoading(false);
      })
      .catch(() => setFailed(t("snackbars.failure.getnumber")));

    return () => {
      cancelTokenSource.cancel();
    };
  }, [id, setFailed, setValue, t, therapistId]);

  const handleClose = () => close();

  const handleValidSubmit = (validInvoiceNumberObject) => {
    const creditNoteNumber = validInvoiceNumberObject.number;
    /*
     * Check if invoice number is valid
     */
    const urlValidInvoiceNumber = urlGetCheckValidInvoiceNumberForTherapist(
      therapistId,
      creditNoteNumber
    );
    axiosInstance
      .get(urlValidInvoiceNumber)
      .then((response) => {
        const isValidCreditNoteNumber = Boolean(response.data);
        if (isValidCreditNoteNumber === true) {
          /*
           * Create creditnote based on invoice id
           */
          const urlCreditNote = urlPostInvoiceCreditnote(invoiceId);
          axiosInstance
            .post(urlCreditNote, {
              newInvoiceNumber: creditNoteNumber,
            })
            .then((response) => {
              const createdCreditNoteId = response.data;
              handleSucces(createdCreditNoteId);
            })
            .catch(() => handleFailed(t("snackbars.failure.creditnote")));
        }
        // When unvalid (the invoice number is likely already used)
        else setFailed(t("snackbars.failure.number.cannotbeused"));
      })
      .catch(() => setFailed(t("snackbars.failure.number.checkvalid")));
  };

  const handleInvalidSubmit = (errors) =>
    setFailed(t("snackbars.failure.invalid"));

  return (
    <Dialog open={open} onClose={handleClose}>
      <form
        style={{ margin: "unset" }}
        onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}
      >
        <DialogTitle>{t("modal.edit.creditnote.title")}</DialogTitle>
        <DialogContent>
          {isLoading === true && <ContentLoadingProgress />}
          {isLoading === false && (
            <>
              {t("modal.edit.creditnote.content")}
              <FormNumberfield
                control={control}
                name="number"
                label={t("form.fields.number")}
                errors={errors.number}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit(handleValidSubmit, handleInvalidSubmit)}
          >
            {t("modal.actions.yes")}
          </Button>
          <Button onClick={handleClose}>{t("modal.actions.no")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
