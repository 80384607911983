import { Button, Stack } from "@mui/material";
import React from "react";
import BasicButtonBar from "../shared/button-bar/basicButtonBar";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

function PatientButtonBar({ id }) {
  const { t } = useTranslation("patients");
  const history = useHistory();

  const handleOpenInvoices = () => {
    history.push(`/patients/${id}/invoices`);
  };
  const handleStartInvoice = () => {
    history.push(`/patients/${id}/invoices/new`);
  };

  const handleNewConsultation = () => {
    history.push(`/consultations/new`);
  };
  const mainItems = (
    <Stack direction="row" spacing={1}>
      <Button onClick={handleNewConsultation}>
        {t("buttons.consultation")}
      </Button>
      <Button onClick={handleStartInvoice}>{t("buttons.invoice")}</Button>
    </Stack>
  );
  const secondaryItems = (
    <Stack direction="row" spacing={1}>
      <Button onClick={handleOpenInvoices}>{t("buttons.openInvoices")}</Button>
    </Stack>
  );
  return (
    <BasicButtonBar mainItems={mainItems} secondaryItems={secondaryItems} />
  );
}
export default PatientButtonBar;
