import axios from "axios";

/**
 * Handle Axios Errors
 * @param {AxiosError} error
 * @returns { { status: number | undefined, message: string, method: string | undefined, baseURL: string | undefined, url: string | undefined} | {}} newErrorObject
 */
export const handleAxiosError = (error) => {
  let newErrorObject = {};
  if (axios.isAxiosError(error)) {
    const axiosErrorJson = error.toJSON();
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      newErrorObject = {
        status: axiosErrorJson.status,
        message: axiosErrorJson.message,
        method: axiosErrorJson.config.method.toUpperCase(),
        baseURL: axiosErrorJson.config.baseURL,
        url: axiosErrorJson.config.url,
      };
      // Log error with some extra information for debugging and logging purposes
      // prettier-ignore
      if (process.env.NODE_ENV !== "production") {
        console.log("Error", axiosErrorJson);
        console.error(`${newErrorObject.method} | ${newErrorObject.url} | ${newErrorObject.message}`);
      }
    }
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    else if (error.request) newErrorObject = { ...error.request };
    // Something happened in setting up the request that triggered an Error
    else newErrorObject.message = error.message;
  }
  // Standard JavaScript Error
  else {
    newErrorObject.message = error.message;
    if (process.env.NODE_ENV !== "production")
      console.log(newErrorObject.message, error);
  }
  return newErrorObject;
};
