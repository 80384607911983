/**
 * This will return true if the invoice can be downloaded.
 * @param {Invoice} invoice
 * @returns {boolean}
 */
export function isDownloadableInvoice(invoice) {
  /* The invoite can only be downloaded when isProcessed is true*/
  if (invoice.isProcessed === true) {
    return true;
  }
  return false;
}
