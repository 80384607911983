import { Page } from "../components/shared/default/Page";
import InvoicesOverviewTherapistPatient from './../components/invoices/invoicesOverview/InvoicesOverviewTherapistPatients';

/**
 * Invoices Screen for a therapist his patients
 * @returns {JSX.Element}
 */
const InvoicesPatientScreen = () => {
  return (
    <>
      <Page content={<InvoicesOverviewTherapistPatient />} />
    </>
  );
};
export default InvoicesPatientScreen;
