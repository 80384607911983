import { useState, useEffect } from "react";
import axios from "axios";
import { axiosInstance } from "../../../api/axios";
import ContentLoadingProgress from "./../../shared/progress/ContentLoadingProgress";
import CheckedTable, { CheckedColumn } from "../../shared/tables/CheckedTable";
import { useTranslation } from "react-i18next";
import { urlGetConsultationByIdCosts } from "../../../api/urls/consultation";

export default function CostsPerConsultation({
  consultationId,
  setFailed,
  setSelected,
  selected,
}) {
  const [t] = useTranslation("costs");
  const [isLoading, setIsLoading] = useState(true);
  const [consultationCosts, setConsultationCosts] = useState([]);

  /**
   * The columns for the costs per consultation table
   * @type {CheckedColumn[]} CheckedColumns
   */
  const columns = [
    new CheckedColumn(1, "title", t("tables.columns.title"), 80),
    new CheckedColumn(2, "cost", t("tables.columns.cost"), 80),
  ];

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    const url = urlGetConsultationByIdCosts(consultationId);
    axiosInstance
      .get(url, {
        setsRequestCancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const costs = response.data.costs;
        const parsedFields = costs.map((c) => {
          return { id: c.id, title: c.title, cost: c.costAmount };
        });
        setConsultationCosts(parsedFields);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setFailed(`${t("snackbars.failure.get")} \n ${error.message}`);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [consultationId, setFailed, t]);

  if (isLoading) {
    return <ContentLoadingProgress />;
  }

  return (
    <>
      {consultationCosts.length === 0 ? (
        <p>{t("tables.nocostinconsultation")}</p>
      ) : (
        <CheckedTable
          rows={consultationCosts}
          columns={columns}
          selected={selected}
          setSelected={setSelected}
          tableSizeSmall={true}
          tableCellAlign="left"
        />
      )}
    </>
  );
}
