import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import BasicButtonBar from "../shared/button-bar/basicButtonBar";
import EditButton from "../shared/iconButtons/EditButton";

function TherapistButtonBar() {
  const { t } = useTranslation("therapists");
  const handleEdit = () => {};
  const mainItems = <Stack direction="row" spacing={1}></Stack>;
  const secondaryItems = (
    <Stack direction="row" spacing={1}>
      <EditButton
        tooltip={t("buttons.edit.tooltip")}
        onClick={handleEdit}
        disabled
      />
    </Stack>
  );
  return (
    <BasicButtonBar mainItems={mainItems} secondaryItems={secondaryItems} />
  );
}
export default TherapistButtonBar;
