import { DateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import { FormHelperText, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const FormDatePicker = ({
  name,
  control,
  label,
  errors,
  displayAsRequired = false,
}) => {
  const { t } = useTranslation("forms");

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <DesktopDatePicker
              label={`${label}${displayAsRequired ? " *" : ""}`}
              value={value ?? null}
              onChange={onChange}
              inputFormat={"dd/MM/yyyy"}
              renderInput={(params) => (
                <TextField
                  margin="normal"
                  variant="outlined"
                  {...params}
                  error={!!errors}
                />
              )}
            />

            {!!errors && errors.message && (
              <FormHelperText error>{t(errors.message)}</FormHelperText>
            )}
          </>
        )}
      />
    </LocalizationProvider>
  );
};
export const FormTimePicker = ({
  name,
  control,
  label,
  errors,
  displayAsRequired,
}) => {
  const { t } = useTranslation("forms");

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <TimePicker
              defaultValue={null}
              label={`${label}${displayAsRequired ? " *" : ""}`}
              ampm={false}
              value={value}
              onChange={onChange}
              renderInput={(params) => (
                <TextField
                  margin="normal"
                  variant="outlined"
                  {...params}
                  error={!!errors}
                />
              )}
            />
            {!!errors && errors.message && (
              <FormHelperText error>{t(errors.message)}</FormHelperText>
            )}
          </>
        )}
      />
    </LocalizationProvider>
  );
};
export const FormDateTimePicker = ({
  name,
  control,
  label,
  errors,
  displayAsRequired,
}) => {
  const { t } = useTranslation("forms");

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <DateTimePicker
              defaultValue={null}
              label={`${label}${displayAsRequired ? " *" : ""}`}
              ampm={false}
              value={value}
              onChange={onChange}
              inputFormat={"dd/MM/yyyy HH:mm"}
              renderInput={(params) => (
                <TextField
                  margin="normal"
                  variant="outlined"
                  {...params}
                  error={!!errors}
                />
              )}
            />
            {!!errors && errors.message && (
              <FormHelperText error>{t(errors.message)}</FormHelperText>
            )}
          </>
        )}
      />
    </LocalizationProvider>
  );
};
