import EditIcon from "@mui/icons-material/Edit";
import GenericIconButton from "./GenericIconButton";

function EditButton({ tooltip, onClick, ...restprops }) {
  return (
    <>
      {tooltip && (
        <GenericIconButton tooltip={tooltip} onClick={onClick} {...restprops}>
          <EditIcon />
        </GenericIconButton>
      )}
    </>
  );
}
export default EditButton;
