/**
 * Get a list of healthInsuranceFunds
 * @param {} 
 * @returns {string} url
 */
 export const urlGetHealthInsuranceFunds = () => `/v2/utilities/healthInsuranceFunds`;

 

 /**
 * Get healthInsuranceFund
 * @param {} 
 * @returns {string} url
 */
  export const urlGetHealthInsuranceFundById = (id) => `/v2/utilities/healthInsuranceFunds/${id}`;

