import { Text, View } from "@react-pdf/renderer";

/**
 * Export note section for the Invoice PDF
 * @param {{ styles: {}, exportNotes: string}} props
 * @returns {View} Export note
 */
export default function InvoicePdfExportnoteSection({ styles, exportNotes }) {
    return (
      <>
        {exportNotes ? (
          <View style={[styles.row, styles.rowExportNote]}>
            <Text style={styles.textText}>{exportNotes}</Text>
          </View>
        ) : null}
      </>
    );
}
