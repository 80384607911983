/**
 * Update invoice to paid
 * @param {number} invoiceId
 * @returns {string} url
 */
export const urlPutInvoicePaid = (invoiceId) => `v2/invoices/${invoiceId}/paid`;

/**
 * Update invoice export note (and set isProcessed true)
 * @param {number} invoiceId
 * @returns {string} url
 */
export const urlPutInvoiceExportNote = (invoiceId) =>
  `v2/invoices/${invoiceId}/export`;

/**
 * Add costs to invoice by ID
 * @param {number} invoiceId
 * @returns {string} url
 */
export const urlPutInvoiceCosts = (invoiceId) =>
  `v2/invoices/${invoiceId}/costs`;
