import { Text, View } from "@react-pdf/renderer";
/**
 * Header section for the Invoice PDF
 * @param {{ styles: {}, invoice: {}}} props
 * @returns {View} Header
 */
export default function InvoicePdfHeaderSection({
  styles,
  invoicePdfHeaderSectionText,
}) {
  return (
    <View style={{ ...styles.row, ...styles.rowHeader }}>
      <Text style={{ color: "white" }}>LOGO</Text>
      <Text>{invoicePdfHeaderSectionText}</Text>
    </View>
  );
}
