import { Text, View } from "@react-pdf/renderer";

/**
 * Users section for the Invoice PDF
 * @param {{ styles: {}, users: []}} props
 * @returns {View} Users
 */
export default function CollectionStatePdfInvoiceNumberDate({
  styles,
  collectionStatePdfInvoiceNumberDate
}) {
  return (
    <View style={styles.row}>
      <View style={styles.information}>
        <Text style={styles.textText}>
          {collectionStatePdfInvoiceNumberDate}
        </Text>
      </View>
    </View>
  );
}
