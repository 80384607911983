/**
 * Get consultationset by ID
 * @param {number} consultationSetId
 * @returns {string} url
 */
export const urlGetConsultationSetId = (consultationSetId) =>
  `v2/consultationSets/${consultationSetId}`;

/**
 * Get all consultationsets
 * @returns {string} url
 */
export const urlGetConsultationSets = (therapistId) =>
  `v2/consultationSets/${therapistId}/consultationSets`;

/**
 * Get all consultationsets by patient ID
 * @param {number} patientId
 * @returns {string} url
 */
export const urlGetConsultationSetsByPatientId = (patientId) =>
  `v2/consultationSets/${patientId}/overviewConsultationSets`;
