import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import Header from "./../shared/default/Header";

/**
 * Page Not Found
 * @returns {JSX.Element}
 */
export default function PageNotFound() {
  const [t] = useTranslation("common");
  const history = useHistory();

  return (
    <>
      <Header
        title={t("pagenotfound.title")}
        items={[
          <Button
            variant="outlined"
            onClick={() => {
              history.goBack();
            }}
            startIcon={<ArrowBackIcon />}
          >
            {t("pagenotfound.actions.back")}
          </Button>,
          <Button
            variant="outlined"
            onClick={() => {
              history.push("/");
            }}
            startIcon={<HomeIcon />}
          >
            {t("pagenotfound.actions.home")}
          </Button>,
        ]}
      />
      <Stack direction="column" spacing={0}>
        <p>{t("pagenotfound.description1")}</p>
        <p>{t("pagenotfound.description2")}</p>
      </Stack>
    </>
  );
}
