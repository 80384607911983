export const parseTherapistResponse = (response, therapistId) => {
  let therapist = response.data.userProfile;
  return {
    therapist: {
      userId: therapistId,
      label: `${therapist.firstName} ${therapist.lastName}`,
      firstName: therapist.firstName,
      lastName: therapist.lastName,
    },
  };
};
