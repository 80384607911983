import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";

/**
 * Consultation Notes
 * @returns {JSX.Element}
 */
export default function ConsultationNotesStep({ noteContent, setNoteContent }) {
  const [t] = useTranslation("agendaitem");

  return (
    <>
      <TextField
        margin={"normal"}
        variant="outlined"
        label={t("fields.note.content")}
        onChange={(event) => setNoteContent(event.target.value)}
        value={noteContent ?? ""}
        fullWidth
        multiline
        rows="5"
      />
    </>
  );
}
