import { Text, View } from "@react-pdf/renderer";

/**
 * Users section for the Invoice PDF
 * @param {{ styles: {}, users: []}} props
 * @returns {View} Users
 */
export default function CollectionStatePdfInfoSection({
  styles,
  collectionStatePdfInfoSectionText,
}) {
  return (
    <View style={styles.row}>
      <View style={styles.information}>
        <Text style={styles.textText}>
        {collectionStatePdfInfoSectionText}
        </Text>
      </View>
    </View>
  );
}
