import { React, useState, useEffect } from "react";
import { Page, Document, PDFViewer, StyleSheet } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import SimpleAlert from "./../../shared/alerts/SimpleAlert";
import { Button } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useHistory } from "react-router";
import moment from "moment";
import {
  CollectionStatePdfUsersSection,
  CollectionStatePdfProviderIdentificationSection,
  CollectionStatePdfInvoiceNumberDate,
  CollectionStatePdfInfoSection,
  CollectionStatePdfPatientSection,
  CollectionStatePdfFinalSection,
} from "./collectionStatePdfSections";
import { InvoicePdfHeaderSection } from "./invoicePdfSections";

export default function CollectionStatePdf({
  therapist,
  patient,
  totalAmount,
  invoiceNumber,
  prescriptionNumber,
  healthInsuranceFund,
  date,
}) {
  const [t] = useTranslation("invoices");
  const history = useHistory();
  const [hasWarnings, setHasWarnings] = useState(true);

  const users = [
    {
      id: 0,
      title: `${
        therapist.userProfile.firstName + " " + therapist.userProfile.lastName
      }`,
      address: `${
        therapist.userProfile.zipcode + " " + therapist.userProfile.city
      }`,
      country: therapist.userProfile.country,
      phone: therapist.userProfile.phoneNumber,
      email: therapist.userProfile.email,
      iban: therapist.iban,
    },

    {
      id: 1,
      title: `${healthInsuranceFund.name}`,
      address: `${
        healthInsuranceFund.streetName +
        " " +
        healthInsuranceFund.houseNumber +
        ", " +
        healthInsuranceFund.zipCode +
        " " +
        healthInsuranceFund.city
      }`,
      country: therapist.country,
      phone: therapist.phone,
      email: therapist.email,
    },
  ];
  const providerIdentification = [
    {
      id: 0,
      title: `${
        therapist.userProfile.firstName + " " + therapist.userProfile.lastName
      }`,
      address: `${
        therapist.userProfile.streetName +
        " " +
        therapist.userProfile.houseNumber +
        ", " +
        therapist.userProfile.zipcode +
        " " +
        therapist.userProfile.city
      }`,
      rizivNumber: therapist.rizivNumber,
      iban: therapist.iban,
    },
  ];

  const formatNationalSecurityNumber = (val) => {
    if (val) {
      var format = val
        .split("")
        .reduce(
          (acc, val, idx) =>
            idx === 2 || idx === 4
              ? acc + "." + val
              : idx === 6 || idx === 9
              ? acc + "-" + val
              : acc + val,
          ""
        );
      return format;
    }
    return "";
  };
  const details = [
    {
      id: 0,
      title: t("collectionstate.export.labels.number"),
      value: prescriptionNumber,
    },
    {
      id: 1,
      title: t("collectionstate.export.labels.patient"),
      value: `${patient.firstName} ${patient.lastName}`,
    },
    {
      id: 2,
      title: t("collectionstate.export.labels.nationalregisternumber"),
      value: formatNationalSecurityNumber(patient.nationalRegistrationNumber),
    },
    {
      id: 3,
      title: t("collectionstate.export.labels.amounttopay"),
      value: totalAmount + " €",
    },
    {
      id: 4,
      title: t("collectionstate.export.labels.preserved"),
    },
  ];

  const providerIdentificationText = [
    {
      id: 0,
      value: t("collectionstate.identification.info"),
    },
    {
      id: 1,
      value: t("collectionstate.identification.name"),
    },
    {
      id: 2,
      value: t("collectionstate.identification.address"),
    },
    {
      id: 3,
      value: t("collectionstate.identification.riziv"),
    },
    {
      id: 4,
      value: t("collectionstate.identification.iban"),
    },
  ];

  const patientSectionText = [
    {
      id: 0,
      value: t("collectionstate.patientsection.prescriptionnumber"),
    },
    {
      id: 1,
      value: t("collectionstate.patientsection.patient"),
    },
    {
      id: 2,
      value: t("collectionstate.patientsection.nationalregisternumber"),
    },
    {
      id: 3,
      value: t("collectionstate.patientsection.amounttopay"),
    },
  ];

  const collectionStatePdfInfoSectionText = t("collectionstate.infosection", {
    healthinsurancefund: healthInsuranceFund.name,
  });

  const collectionStatePdfHeaderSectionText = t("collectionstate.header");

  const collectionStatePdfInvoiceNumberDate = t(
    "collectionstate.invoicenumberdatesection",
    {
      invoicenumber: invoiceNumber,
      date: moment(new Date(date)).format("DD/MM/YYYY"),
    }
  );

  const collectionStatePdfFinalSection = t("collectionstate.finalsection", {
    amount: totalAmount,
  });

  useEffect(() => {
    if (
      therapist.iban !== null &&
      therapist.iban !== undefined &&
      therapist.userProfile.streetName !== null &&
      therapist.userProfile.streetName !== undefined &&
      therapist.userProfile.city !== null &&
      therapist.userProfile.city !== undefined &&
      therapist.userProfile.country !== null &&
      therapist.userProfile.country !== undefined &&
      therapist.userProfile.houseNumber !== null &&
      therapist.userProfile.houseNumber !== undefined &&
      therapist.userProfile.zipcode !== null &&
      therapist.userProfile.zipcode !== undefined
    ) {
      setHasWarnings(false);
    }
  }, []);

  return (
    <>
      {therapist.iban === null || therapist.iban === undefined ? (
        <>
          <SimpleAlert
            severity="warning"
            title={t("export.noibantitle")}
            description={t("export.noiban")}
            action={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => history.push("/settings?key=0")}
              >
                <SettingsIcon></SettingsIcon>
              </Button>
            }
          ></SimpleAlert>
        </>
      ) : null}

      {therapist.userProfile.streetName === null ||
      therapist.userProfile.streetName === undefined ||
      therapist.userProfile.city === null ||
      therapist.userProfile.city === undefined ||
      therapist.userProfile.country === null ||
      therapist.userProfile.country === undefined ||
      therapist.userProfile.houseNumber === null ||
      therapist.userProfile.houseNumber === undefined ||
      therapist.userProfile.zipcode === null ||
      therapist.userProfile.zipcode === undefined ? (
        <>
          <SimpleAlert
            severity="warning"
            title={t("export.addresstitle")}
            description={t("export.address")}
            action={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => history.push("/settings?key=2")}
              >
                <SettingsIcon></SettingsIcon>
              </Button>
            }
          ></SimpleAlert>
        </>
      ) : null}

      {hasWarnings === false ? (
        <PDFViewer showToolbar={true} style={styles.pdfViewer}>
          <Document>
            <Page size="A4" style={styles.page}>
              {/* Header */}
              <InvoicePdfHeaderSection
                styles={styles}
                invoicePdfHeaderSectionText={
                  collectionStatePdfHeaderSectionText
                }
              />

              {/* Users */}
              <CollectionStatePdfUsersSection styles={styles} users={users} />

              {/* Provider Identification */}
              <CollectionStatePdfProviderIdentificationSection
                styles={styles}
                user={providerIdentification}
                providerIdentificationText={providerIdentificationText}
              />

              {/* InvoiceNumber & Date */}
              <CollectionStatePdfInvoiceNumberDate
                styles={styles}
                collectionStatePdfInvoiceNumberDate={
                  collectionStatePdfInvoiceNumberDate
                }
              />

              <CollectionStatePdfInfoSection
                styles={styles}
                collectionStatePdfInfoSectionText={
                  collectionStatePdfInfoSectionText
                }
              />

              <CollectionStatePdfPatientSection
                styles={styles}
                details={details}
                patientSectionText={patientSectionText}
              />

              <CollectionStatePdfFinalSection
                styles={styles}
                collectionStatePdfFinalSection={collectionStatePdfFinalSection}
              />
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  pdfViewer: {
    width: "100%",
    height: "40em",
  },
  page: {
    width: "max-content",
    height: "max-content",
    flexDirection: "column",
    backgroundColor: "white",
    padding: 30,
  },
  title: {
    fontSize: 12,
    fontWeight: "heavy",
    marginBottom: 5,
  },
  textTitle: {
    fontSize: 10,
  },
  textText: {
    marginBottom: 3.5,
    fontSize: 10,
  },
  textBold: {
    fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
    margin: 10,
    marginBottom: 30,
  },
  rowHeader: {
    alignContent: "center",
    justifyContent: "space-between",
  },
  rowInvoiceUsers: {
    paddingBottom: 5,
    //flexDirection: "column",
    alignContent: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  rowInvoiceDetail: {
    alignContent: "flex-start",
    justifyContent: "space-around",
  },
});
