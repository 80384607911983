import EditConsultationScreen from "./../../screens/EditConsultationScreen";
import ConsultationDetailScreen from "./../../screens/ConsultationDetailScreen";

/**
 * @type {Array<{path: string, screen: JSX.Element}>}
 */
// prettier-ignore
export const routesConsultations = [
  { path: "/consultations/:consultationId/edit", screen: <EditConsultationScreen /> },
  { path: "/consultations/:id/detail", screen: <ConsultationDetailScreen /> },
  { path: "/consultations/new", screen: <EditConsultationScreen /> },
];
