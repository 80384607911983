import FormModal from "./../../shared/menus/modals/FormModal";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { FormTextfield } from "../../shared/forms/form-elements/FormTextfield";

/**
 * A yup validation schema
 * @returns {object} Schema
 */
const schema = object({
  exportNotes: string().default(""),
}).required();

/**
 * Modal for Invoice Export
 * @returns {FormModal}
 */
export default function InvoiceExportModal({
  open,
  close,
  handleSucces,
  setFailed,
}) {
  const [t] = useTranslation("invoices");
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({ exportNotes: "" });
  }, [reset]);

  /**
   * Handle form when exportNotes is valid
   * @param {{exportNotes: string}} validObject
   */
  const handleValidSubmit = (validObject) => handleSucces(validObject);

  /**
   * Handle form when exportNotes is unValid
   * @param {{}} errors
   */
  // eslint-disable-next-line no-unused-vars
  const handleUnValidSubmit = (errors) => {
    setFailed(t("modal.export.errors"));
  };

  return (
    <FormModal
      open={open}
      title={t("modal.export.title")}
      description={t("modal.export.description")}
      content={
        <>
          <FormTextfield
            control={control}
            name="exportNotes"
            label={t("form.fields.exportNotes")}
            errors={errors.exportNotes}
          />
        </>
      }
      handleClose={close}
      handleSubmit={handleSubmit(handleValidSubmit, handleUnValidSubmit)}
      submitButtonContent={t("modal.export.submit")}
      closeButtonContent={t("modal.export.cancel")}
    />
  );
}
