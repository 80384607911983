import useAuth from "../auth/useAuth";
import ConsultationOverview from "../components/consultations/ConsultationOverview";
import { Page } from "../components/shared/default/Page";
import { pageViewConstants } from "../constants/insight-constants";
import { useAppInsightsContext } from "../insights/AppInsightsContext";
import { trackPageView } from "../insights/AppInsightsTrack";
import { isAppInsigthsEnabled } from "./../insights/isAppInsightsEnabled";

export default function FilesScreen() {
  const { id } = useAuth();

  const isAiEnabled = isAppInsigthsEnabled();
  const appInsights = useAppInsightsContext();
  const trackProps = {
    user: { id: id },
  };

  // prettier-ignore
  trackPageView(isAiEnabled, appInsights, pageViewConstants.FILES, trackProps);

  return <Page content={<ConsultationOverview />} />;
}
