import { Text, View } from "@react-pdf/renderer";

/**
 * Bank section for the Invoice PDF
 * @param {{ styles: {}, invoiceAmount: {}, valutaType: "", invoice: {}}} props
 * @returns {View} Bank
 */
export default function InvoicePdfBankSection({ styles, invoicePdfBankSectionText, isCreditNote }) {
  const content = isCreditNote ? (
    <Text /> //When isCreditNote is true no text will return
  ) : (
    <View style={[styles.row, styles.rowExportNote]}>
      <Text style={styles.textText}>{invoicePdfBankSectionText}</Text>
    </View>
  );
  return (
    <>
    {content}
    </>
  );
}
