import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { axiosInstance } from "../api/axios";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [token, setToken] = useState(null);
  const [id, setId] = useState();
  const [lang, setLang] = useState();

  const login = useCallback(() => {
    const url = window.location.search;
    const index = url.indexOf("?");
    if (index !== -1) {
      let params = url.slice(index + 1).split("&");
      let guid = params[1].slice(5);
      let lang = params[2]?.slice(5);
      let token = params[0].slice(6);
      fetchUserByGuid(guid);
      localStorage.token = token;
      if (lang) localStorage.lang = lang;
    }
    setLang(localStorage.lang ?? "nl"); // fallback language
    setToken(localStorage.token);
    setId(Number(localStorage.id));
  }, []);

  const fetchUserByGuid = (guid) => {
    axiosInstance.get(`/v2/user/${guid}/Id`).then((response) => {
      setId(response.data.id);
      localStorage.id = response.data.id;
    });
  };

  const memoedValue = useMemo(
    () => ({
      token,
      id,
      lang,
      login,
    }),
    [token, id, lang, login]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
