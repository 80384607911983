import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useParams } from "react-router";
import { axiosInstance, handleAxiosError } from "../../../api/axios";
import PageLoading from "./../../shared/loading/PageLoading";
import FailureSnackbar from "./../../shared/menus/snackbars/FailureSnackbar";
import Header from "./../../shared/default/Header";
import InvoicePdf from "./InvoicePdf";
import { urlGetInvoiceExport } from "../../../api/urls/invoice";

/**
 * Invoice Export Overview
 * @returns {JSX.Element}
 */
export default function InvoiceExportOverview() {
  const [t] = useTranslation("invoices");
  const { invoiceId } = useParams();

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();

  const [failed, setFailed] = useState();

  const [therapist, setTherapist] = useState(null);
  const [patient, setPatient] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [invoiceKeyValues, setInvoiceKeyValues] = useState(null);
  const [valutaType, setValutaType] = useState(null);
  const [invoiceAmount, setInvoiceAmount] = useState(null);
  const [additionalCosts, setAdditionalCosts] = useState(null);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    /*
     * Get invoice export url
     */
    const url = urlGetInvoiceExport(invoiceId);
    axiosInstance
      .get(url)
      .then((response) => {
        const responseWithItems = response.data;
        setInvoiceAmount(responseWithItems.exportInvoiceDto.invoiceAmount);
        setInvoice(responseWithItems.exportInvoiceDto.invoiceDto);
        setAdditionalCosts(responseWithItems.exportInvoiceDto.additionalCosts);
        // Valuta type hard coded just to get the symbol, will never have 2 different valutas in 1 invoice
        // There is now a check if there is one invoiceline it will take the valuta of that costDto otherwise it will just set the valutatype to euro (value: 0)
        // prettier-ignore
        if (responseWithItems.exportInvoiceDto.invoiceDto.invoiceLines[0] !== undefined){
          setValutaType(responseWithItems.exportInvoiceDto.invoiceDto.invoiceLines[0].costDto.valuta);
        }
        else {
          setValutaType(0);
        }
        setTherapist(responseWithItems.therapist);
        setPatient(responseWithItems.patient);
        setInvoiceKeyValues(responseWithItems.exportInvoiceDto.keyValues);
        setIsLoadingPage(false);
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: "error.request.get.invoiceexport",
          axiosError: handleAxiosError(error),
        })
      );

    return () => {
      cancelTokenSource.cancel();
    };
  }, [invoiceId, t]);

  if (isLoadingPage) {
    return (
      <PageLoading
        header={
          <Header
            title={`${t("export.title")} ${
              invoice?.isCreditNote === true ? t("creditnote") : t("invoice")
            }`}
          />
        }
        isLoading={isLoadingPage}
        errorLoading={errorLoadingPage}
      />
    );
  }

  return (
    <>
      <Header
        title={`${t("export.title")} ${
          invoice.isCreditNote === true ? t("creditnote") : t("invoice")
        }`}
      />
      <InvoicePdf
        therapist={therapist}
        patient={patient}
        invoice={invoice}
        valutaType={valutaType}
        invoiceKeyValues={invoiceKeyValues}
        invoiceAmount={invoiceAmount}
        additionalCosts={additionalCosts}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
