import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import MyDateRangePicker from "./../../shared/controlled-inputs/DateRangePicker";
import { initialstateFilters } from "../../../constants/initialstates-constants";

/**
 * Filterbar for the invoices
 * @returns {JSX.Element}
 */
export default function InvoicesFilterBar({
  // Booleans
  setIsProcessedFilter,
  setIsOverDueFilter,
  setIsPaidFilter,
  setIsCreditNoteFilter,
  // Dates
  setDateFilter,
  setDueDateFilter,
  // Numbers
  setNumberFilter,
  // Strings
  setReferenceFilter,
  setNameFilter
}) {
  const [t] = useTranslation("invoices");

  const [isProcessed, setIsProcessed] = useState(initialstateFilters.boolean);
  const [isOverDue, setIsOverDue] = useState(initialstateFilters.boolean);
  const [isPaid, setIsPaid] = useState(initialstateFilters.boolean);
  const [isCreditNote, setIsCreditNote] = useState(initialstateFilters.boolean);
  const [date, setDate] = useState(initialstateFilters.dateRange);
  const [dueDate, setDueDate] = useState(initialstateFilters.dateRange);
  const [number, setNumber] = useState(initialstateFilters.number);
  const [reference, setReference] = useState(initialstateFilters.string);
  const [name, setName] = useState(initialstateFilters.string);

  /**
   * Apply current filters
   */
  const applyFilters = () => {
    // Booleans
    setIsProcessedFilter(isProcessed);
    setIsOverDueFilter(isOverDue);
    setIsPaidFilter(isPaid);
    setIsCreditNoteFilter(isCreditNote);
    // Dates
    setDateFilter(date);
    setDueDateFilter(dueDate);
    // Numbers
    setNumberFilter(number);
    // Strings
    setReferenceFilter(reference);
    setNameFilter(name);
  };

  /**
   * Reset current filters
   */
  const resetFilters = () => {
    // Booleans
    setIsProcessed(initialstateFilters.boolean);
    setIsProcessedFilter(initialstateFilters.boolean);
    setIsOverDue(initialstateFilters.boolean);
    setIsOverDueFilter(initialstateFilters.boolean);
    setIsPaid(initialstateFilters.boolean);
    setIsPaidFilter(initialstateFilters.boolean);
    setIsCreditNote(initialstateFilters.boolean);
    setIsCreditNoteFilter(initialstateFilters.boolean);
    // Dates
    setDate(initialstateFilters.dateRange);
    setDateFilter(initialstateFilters.dateRange);
    setDueDate(initialstateFilters.dateRange);
    setDueDateFilter(initialstateFilters.dateRange);
    // Numbers
    setNumber(initialstateFilters.number);
    setNumberFilter(initialstateFilters.number);
    // Strings
    setReference(initialstateFilters.string);
    setReferenceFilter(initialstateFilters.string);
    setName(initialstateFilters.string);
    setNameFilter(initialstateFilters.string);
  };

  /**
   * Boolean filters
   * @type {[{inputLabel: {id: string, label: string}, select: {id: string, value: any, onChange: any}}]} filters
   */
  const booleanFilters1 = [
    {
      inputLabel: {
        id: "filters.isProcessed",
        label: t("tables.filters.isProcessed"),
      },
      select: {
        id: "input-filters.isProcessed",
        value: isProcessed ?? "",
        onChange: (event) => setIsProcessed(event.target.value),
      },
    },
    {
      inputLabel: {
        id: "filters.isOverDue",
        label: t("tables.filters.isOverDue"),
      },
      select: {
        id: "input-filters.isOverDue",
        value: isOverDue ?? "",
        onChange: (event) => setIsOverDue(event.target.value),
      },
    },
  ];

  /**
   * Boolean filters
   * @type {[{inputLabel: {id: string, label: string}, select: {id: string, value: any, onChange: any}}]} filters
   */
  const booleanFilters2 = [
    {
      inputLabel: { id: "filters.isPaid", label: t("tables.filters.isPaid") },
      select: {
        id: "input-filters.isPaid",
        value: isPaid ?? "",
        onChange: (event) => setIsPaid(event.target.value),
      },
    },
    {
      inputLabel: {
        id: "filters.isCreditNote",
        label: t("tables.filters.isCreditNote"),
      },
      select: {
        id: "input-filters.isCreditNote",
        value: isCreditNote ?? "",
        onChange: (event) => setIsCreditNote(event.target.value),
      },
    },
  ];

  /**
   * Boolean filter object
   * @param {{inputLabel: {id: string, label: string}, select: {id: string, value: any, onChange: any}}} filter
   * @param {number} index
   * @returns {JSX.Element} Filter input
   */
  const booleanFilterObject = (filter, index) => (
    <FormControl key={index} style={styles.booleanFilterObject} fullWidth>
      <InputLabel id={filter.inputLabel.id}>
        {filter.inputLabel.label}
      </InputLabel>
      <Select
        labelId={filter.inputLabel.id}
        id={filter.select.id}
        label={filter.inputLabel.label}
        value={filter.select.value}
        onChange={filter.select.onChange}
      >
        {booleanMenuItems.map((item, itemIndex) => (
          <MenuItem key={itemIndex} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  /**
   * MenuItems for boolean filters
   * @type {[{title: string, value: string | boolean}]}
   */
  const booleanMenuItems = [
    { title: t("tables.filters.options.all"), value: "" },
    { title: t("tables.filters.options.yes"), value: true },
    { title: t("tables.filters.options.no"), value: false },
  ];

  /**
   * String and numbers filters
   * @type {[{id: string, label: string, type: "number" | "text" value: any, onChange: any}]}
   */
  const stringAndNumberFilters = [
    {
      id: "input-filters.number",
      label: t("tables.filters.number"),
      type: "number",
      value: number ?? "",
      onChange: (event) => setNumber(Number(event.target.value)),
    },
    {
      id: "input-filters.reference",
      label: t("tables.filters.reference"),
      type: "text",
      value: reference ?? "",
      onChange: (event) => setReference(event.target.value),
    },
    {
      id: "input-filters.name",
      label: t("tables.filters.name"),
      type: "text",
      value: name ?? "",
      onChange: (event) => setName(event.target.value),
    }
  ];

  /**
   * String filter object
   * @param {{id: string, label: string, type: "number" | "text" value: any, onChange: any}} filter
   * @param {number} index
   * @returns {TextField}
   */
  const stringOrNumberFilterObject = (filter, index) => (
    <TextField
      key={index}
      id={filter.id}
      label={filter.label}
      type={filter.type}
      inputProps={
        filter.type === "number"
          ? { inputMode: "numeric", pattern: "[0-9]*" }
          : {}
      }
      value={filter.value}
      onChange={filter.onChange}
    />
  );

  return (
    <>
      {/* Date range pickers */}
      <Stack direction="row" spacing={2} style={styles.stack}>
        <MyDateRangePicker
          value={date}
          setValue={setDate}
          startText={t("tables.filters.date")}
        />
        <MyDateRangePicker
          value={dueDate}
          setValue={setDueDate}
          startText={t("tables.filters.duedate")}
        />
      </Stack>
      {/* Boolean filters 1*/}
      <Stack direction="row" spacing={2} style={styles.stack}>
        {booleanFilters1.map((filter, index) => {
          return booleanFilterObject(filter, index);
        })}
      </Stack>
      {/* Boolean filters 2 */}
      <Stack direction="row" spacing={2} style={styles.stack}>
        {booleanFilters2.map((filter, index) => {
          return booleanFilterObject(filter, index);
        })}
      </Stack>
      {/* Number & String filters */}
      <Stack direction="row" spacing={2} style={styles.stack}>
        {stringAndNumberFilters.map((filter, index) => {
          return stringOrNumberFilterObject(filter, index);
        })}
      </Stack>
      {/* Actions */}
      <Stack direction="row" spacing={2} style={styles.stack}>
        <div style={styles.containerActions}>
          <Button
            variant="outlined"
            color="secondary"
            sx={styles.buttonReset}
            onClick={resetFilters}
          >
            {t("tables.filters.reset")}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={styles.buttonApply}
            onClick={applyFilters}
          >
            {t("tables.filters.apply")}
          </Button>
        </div>
      </Stack>
    </>
  );
}

const styles = {
  stack: {
    marginBottom: "1em",
  },
  booleanFilterObject: {
    minWidth: "220px",
  },
  containerActions: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  buttonApply: {
    color: "green",
    borderColor: "green",
  },
  buttonReset: {
    marginRight: "0.5em",
    color: "red",
    borderColor: "red",
  },
};
