/**
 * Get therapist by id
 * @param {number} therapistId
 * @returns {string} url
 */
export const urlGetTherapistId = (therapistId) =>
  `v2/therapists/${therapistId}`;

/**
 * Get all patients from a therapist
 * @param {number} therapistId
 * @returns {string} url
 */
export const urlGetTherapistPatients = (therapistId) =>
  `v2/therapists/${therapistId}/invoiceablePatients`;


  /**
 * Get all patients from a therapist with a search query
 * @param {number} therapistId
 * @returns {string} url
 */
export const urlGetTherapistPatientsWithQuery = (id) =>
`v2/therapists/${id}/patients/search`;
