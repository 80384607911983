import { FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const FormTextfield = ({
  name,
  control,
  label,
  multiline,
  rows,
  errors,
  margin,
  sx,
  displayAsRequired = false,
  disabled = false,
  standardValue,
}) => {
  const { t } = useTranslation("forms");
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <>
          <TextField
            margin={margin ?? "normal"}
            variant="outlined"
            onChange={onChange}
            value={value ?? standardValue ?? ""}
            label={`${label}${displayAsRequired ? " *" : ""}`}
            multiline={multiline}
            rows={rows}
            error={!!errors}
            sx={sx}
            disabled={disabled}
          />
          {!!errors && errors.message && (
            <FormHelperText error>{t(errors.message)}</FormHelperText>
          )}
        </>
      )}
    />
  );
};
