/**
 * This will return true if the invoice can be credited.
 * @param {Invoice} invoice
 * @returns {boolean} creditable
 */
export function isCreditableInvoice(invoice) {
  /* An invoice can only be credited if itself isn't a credit note, there is one invoiceline that isn't credited and isProcessed is true */
  if (invoice.isCreditNote === false) {
    if (invoice.allInvoiceLinesCredited === false) {
      if (invoice.isProcessed === true) {
        return true;
      }
    }
  }
  return false;
}
