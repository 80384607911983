import { useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../../../api/axios";
import ConfirmationModal from "../../shared/menus/modals/ConfirmationModal";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import SuccesSnackbar from "../../shared/menus/snackbars/SuccesSnackbar";
import DataTable, {
  Column,
  SortableColumn,
  SortableFormattedColumn,
} from "../../shared/tables/Datatable";

/**
 * A consultation list has the responsability of getting the consultations from the api matching the filters handed down.
 * @returns a list of all consultations matching the parameters (paged, filtered) as consultation-list-items
 */
function AbstractConsultationList({
  rows,
  page,
  pageSize,
  rowCount,
  setPage,
  setPageSize,
  order,
  setOrder,
  orderBy,
  setOrderBy,
}) {
  const [t] = useTranslation("consultations");

  const columns = [
    new Column(0, "therapist", t("tables.columns.therapist"), 80),
    new Column(1, "patient", t("tables.columns.patient"), 80),
    new SortableColumn(
      2,
      "nomenclature",
      t("tables.columns.nomenclature"),
      undefined,
      30
    ),
    new SortableFormattedColumn(
      3,
      "startTime",
      t("tables.columns.startTime"),
      80,
      (value) => value.toLocaleString("nl-BE")
    ),
    new Column(4, "duration", t("tables.columns.duration"), 30),
    new SortableColumn(5, "location", t("tables.columns.location"), 50),
    new Column(6, "actions", "", undefined, 250),
  ];

  const [succes, setSucces] = useState(false);
  const [failed, setFailed] = useState();
  const [deletePrompted, setDeletePrompted] = useState(false);

  const onDelete = (value) => {
    axiosInstance
      .delete(`v2/consultations/${value}`)
      .then(setSucces(true))
      .catch((error) => {
        setFailed(`${t("snackbars.failure")} \n ${error.message}`);
      });
  };

  const handleSort = (row) => () => {
    let newOrderBy = columns[row].field.toUpperCase();
    if (orderBy === newOrderBy) {
      // Change order
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      // Change orderBy
      setOrderBy(newOrderBy);
    }
  };

  return (
    <div className="card-list">
      {rows && rows.length > 0 && (
        <DataTable
          rows={rows}
          columns={columns}
          rowCount={rowCount}
          pageSize={pageSize}
          page={page}
          setPage={setPage}
          setPageSize={setPageSize}
          createSortHandler={(value) => handleSort(value)}
          order={order}
          orderBy={orderBy}
        />
      )}
      <ConfirmationModal
        open={!!deletePrompted}
        handleClose={() => setDeletePrompted(false)}
        command={() => onDelete(deletePrompted)}
        content={t("confirmations.remove.consultation")}
        title={t("labels.remove.consultation")}
      />
      <SuccesSnackbar
        open={!!succes}
        setOpen={setSucces}
        text={t("snackbars.succes")}
      />
      <FailureSnackbar
        open={!!failed}
        setOpen={setFailed}
        text={t("snackbars.failure")}
      />
    </div>
  );
}
export default AbstractConsultationList;
