import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import ReceiptIcon from "@mui/icons-material/Receipt";

/**
 * Credit note overview detail
 * @param {{ invoice: {}}} props
 * @returns {JSX.Element}
 */
export default function CreditNoteOverviewDetail({ invoice }) {
  const [t] = useTranslation("invoices");
  const history = useHistory();

  return (
    <>
      <Grid container spacing={2} columns={16}>
        <Grid item xs>
          <Button
            variant="outlined"
            disabled={
              invoice.creditNoteForInvoiceId === undefined ? true : false
            }
            onClick={() => {
              history.push(`/invoices/${invoice.creditNoteForInvoiceId}`);
            }}
            startIcon={<ReceiptIcon />}
          >
            {t("detail.gotoinvoicecredited")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
