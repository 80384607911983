import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { axiosInstance } from "../../api/axios";
import BasicButtonBar from "../shared/button-bar/basicButtonBar";
import DeleteButton from "../shared/iconButtons/DeleteButton";
import EditButton from "../shared/iconButtons/EditButton";

function ConsultationSetButtonBar({ id, setFailed, deletable }) {
  const { t } = useTranslation("consultations");
  const history = useHistory();

  const mainItems = (
    <Stack direction="row" spacing={1}>
      <EditButton
        tooltip={t("tooltips.edit")}
        onClick={() => history.push(`/consultationset/${id}/edit`)}
      />
      <DeleteButton
        disabled={!deletable}
        tooltip={t("tooltips.delete")}
        onClick={() => {
          axiosInstance
            .delete(`v2/consultationsets/${id}`)
            .then(history.goBack())
            .catch((error) => {
              setFailed(`${t("snackbars.failure")} \n ${error.message}`);
            });
        }}
      />
    </Stack>
  );
  return <BasicButtonBar mainItems={mainItems} />;
}
export default ConsultationSetButtonBar;
