export function Page({ content }) {
  return (
    <div
      style={{
        position: "relative",
        margin: "15px",
        padding: "15px",
        backgroundColor: "white",
      }}
    >
      {content}
    </div>
  );
}
