import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { date, number, object, string, boolean } from "yup";
import { axiosInstance, handleAxiosError } from "../../api/axios";
// Urls
import {
  urlGetLatestTherapistInvoiceNumber,
  urlGetCheckValidInvoiceNumberForTherapist,
  urlPostInvoice,
  urlPutInvoiceCosts,
} from "../../api/urls/invoice";
import useAuth from "../../auth/useAuth";
import Header from "../shared/default/Header";
import FailureSnackbar from "../shared/menus/snackbars/FailureSnackbar";
import Wizard from "../shared/wizards/Wizard";
import { urlGetPatientIdInfo } from "./../../api/urls/patient";
import PageLoading from "./../shared/loading/PageLoading";
import {
  ConsultationCostsStep,
  CreateInvoiceStep,
  IndividualCostsStep,
  OverviewStep,
} from "./addInvoiceWizardSteps";

/**
 * A yup validation schema of invoice
 * @returns {object} Schema of invoice
 */
const invoiceSchema = object({
  date: date().required().default(new Date()),
  dueDate: date()
    .required()
    .default(new Date(new Date().setDate(new Date().getDate() + 30))),
  creditorId: number().required(),
  number: number().required().min(0),
  reference: string().default(""),
  remarks: string().default(""),
  thirdPayerSystem: boolean().default(true),
}).required();

/**
 * Add Invoice Form
 * @returns {JSX.Element}
 */
export default function AddInvoiceWizard() {
  const [t] = useTranslation("invoices");
  const history = useHistory();
  const { patientId } = useParams();

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = useState();

  const [failed, setFailed] = useState();
  const { id } = useAuth();

  const [isThirdPayerEnabled, setIsThirdPayerEnabled] = useState();

  const {
    control,
    formState: { errors /*, isValid, isValidating */ },
    handleSubmit,
    reset,
    trigger,
    getValues,
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(invoiceSchema),
  });
  // prettier-ignore
  const [selectedConsultationCosts, setSelectedConsultationCosts] = useState(new Set());
  // prettier-ignore
  const [selectedIndividualCosts, setSelectedIndividualCosts] = useState(new Set());

  const [patient, setPatient] = useState();

  useEffect(() => {
    const resetValues = {
      date: new Date(), // Date = today
      dueDate: new Date(new Date().setDate(new Date().getDate() + 30)), // Due Date = today + 30 days
      customerId: Number(patientId), // customer id = patient id from url
      creditorId: Number(id),
      remarks: "",
    };

    /**
     * Get latest invoice number the therapist can use
     */
    const url = urlGetLatestTherapistInvoiceNumber(id);
    axiosInstance
      .get(url)
      .then((reponse) => {
        const newInvoiceNumber = reponse.data.invoiceNumber;
        // Default values cannot be set in the schema for some reason
        reset({
          ...resetValues,
          number: Number(newInvoiceNumber),
        });
        setIsLoadingPage(false);
      })
      .catch((error) => {
        setFailed(t("snackbars.failure.getnumber"));
      });

    return () => {};
  }, [reset, patientId, t, id]);

  useEffect(() => {
    setIsThirdPayerEnabled(!isThirdPayerEnabled);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    /**
     * Get patient info (only return userProfile)
     */
    const url = urlGetPatientIdInfo(patientId);
    axiosInstance
      .get(url, {
        setsRequestCancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        setPatient(response.data);
      })
      .catch((error) =>
        setErrorLoadingPage({
          title: "error.request.get.patient",
          axiosError: handleAxiosError(error),
        })
      );

    return () => {
      cancelTokenSource.cancel();
    };
  }, [patientId, t]);

  /**
   * This function will be executed when the form is valid.
   * It will send a POST request to add the invoice
   * @param {{}} validInvoice
   */
  const onValidSubmit = (validInvoice) => {
    // Convert to UTC
    validInvoice.date = validInvoice.date.toISOString();
    validInvoice.dueDate = validInvoice.dueDate.toISOString();
    // Add missing lines to validInvoice
    validInvoice.id = 0;
    validInvoice.exportNotes = "";
    validInvoice.invoiceLines = [];
    validInvoice.isProcessed = false;
    validInvoice.isPaid = false;
    validInvoice.isCreditNote = false;
    validInvoice.creditNoteForInvoiceId = null;
    validInvoice.allInvoiceLinesCredited = false;
    validInvoice.thirdPayerSystem = getValues("thirdPayerSystem");
    // 2 sets to one array
    const costIds = [
      ...Array.from(selectedConsultationCosts),
      ...Array.from(selectedIndividualCosts),
    ];
    if (Number(validInvoice.number) < 0) {
      setFailed(t("snackbars.failure.number.invalid"));
      return;
    }
    if (costIds.length === 0) {
      setFailed(t("snackbars.failure.selectatleastonecost"));
      return;
    }
    /*
     * Check valid invoice number
     */
    // prettier-ignore
    const urlValidInvoiceNumber = urlGetCheckValidInvoiceNumberForTherapist(id, validInvoice.number);
    axiosInstance
      .get(urlValidInvoiceNumber)
      .then((reponse) => {
        const isValidInvoiceNumber = Boolean(reponse.data);
        // Check if invoice number is valid
        if (isValidInvoiceNumber === true) {
          /*
           * Create invoice
           */
          const urlCreateInvoice = urlPostInvoice();
          axiosInstance
            .post(urlCreateInvoice, validInvoice)
            .then((response) => {
              const createdInvoiceId = response.data;
              /*
               * Adding costs to invoice
               */
              const urlAddCostsToInvoice = urlPutInvoiceCosts(createdInvoiceId);
              axiosInstance
                .put(urlAddCostsToInvoice, costIds)
                .then((response) => {
                  history.push(`/invoices/${createdInvoiceId}`);
                })
                .catch(() => setFailed(t("snackbars.failure.add.costs")));
            })
            .catch(() => setFailed(t("snackbars.failure.add.invoice")));
        }
        // When unvalid (the invoice number is likely already used)
        else setFailed(t("snackbars.failure.number.cannotbeused"));
      })
      .catch(() => setFailed(t("snackbars.failure.number.checkvalid")));
  };

  /**
   * This function will be executed when the form is invalid
   * The object it receives contains for each invalid yup schema formfield the corresponding error
   * @param {{}} errors
   */
  const onInvalidSubmit = (errors) => {
    console.warn("Invalid inputfields", errors);
    setFailed(t("snackbars.failure.invalidforms"));
  };

  /**
   * Gives back the latest values in the form
   * @returns {{ invoice: {} }} currentFormObject
   */
  const getCurrentFormObject = () => {
    return {
      invoice: {
        date: getValues("date"),
        dueDate: getValues("dueDate"),
        creditorId: getValues("creditorId"),
        number: Number(getValues("number")),
        reference: getValues("reference"),
        remarks: getValues("remarks"),
      },
    };
  };

  /**
   * Steps for the wizard
   * @type {[{id: number, title: string, description: string, content: JSX.Element, onNext: Promise<boolean> | undefined}]} steps
   */
  const steps = [
    {
      id: 0,
      title: t("wizard.add.steps.addinvoice.title"),
      description: t("wizard.add.steps.addinvoice.description"),
      content: (
        <CreateInvoiceStep
          control={control}
          errors={errors}
          checked={isThirdPayerEnabled}
        />
      ),
      onNext: () =>
        trigger(["date", "dueDate", "number", "reference", "remarks"]),
    },
    {
      id: 1,
      title: t("wizard.add.steps.consultationcosts.title"),
      description: t("wizard.add.steps.consultationcosts.description"),
      content: (
        <ConsultationCostsStep
          patientId={patientId}
          therapistId={id}
          setFailed={setFailed}
          selected={selectedConsultationCosts}
          setSelected={setSelectedConsultationCosts}
        />
      ),
    },
    {
      id: 2,
      title: t("wizard.add.steps.individualcosts.title"),
      description: t("wizard.add.steps.individualcosts.description"),
      content: (
        <IndividualCostsStep
          therapistId={id}
          patientId={patientId}
          selected={selectedIndividualCosts}
          setSelected={setSelectedIndividualCosts}
        />
      ),
    },
    {
      id: 3,
      title: t("wizard.add.steps.overview.title"),
      description: t("wizard.add.steps.overview.description"),
      content: (
        <OverviewStep
          invoiceValues={getCurrentFormObject}
          selectedConsultationCosts={selectedConsultationCosts}
          selectedIndividualCosts={selectedIndividualCosts}
        />
      ),
    },
  ];

  if (isLoadingPage) {
    return (
      <PageLoading
        header={<Header title={t("wizard.add.titleplain")} />}
        isLoading={isLoadingPage}
        errorLoading={errorLoadingPage}
      />
    );
  }

  return (
    <>
      <Header
        title={t("wizard.add.title", {
          patientFullName: `${patient && patient.userProfile.lastName} ${
            patient && patient.userProfile.firstName
          }`,
        })}
      />
      <Wizard
        steps={steps}
        handleFinish={handleSubmit(onValidSubmit, onInvalidSubmit)}
        wide={true}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
