import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import axios from "axios";
import { axiosInstance } from "../../../api/axios";
import AddIcon from "@mui/icons-material/Add";
import DataTable, { Column } from "./../../shared/tables/Datatable";
import CreateConsultationCostModal from "./CreateConsultationCostModal";
import {
  mapCompensationType,
  mapValutaType,
} from "../../../helpers/enumMappers";
import {
  urlGetConsultationByIdCosts,
  urlPostCostToConsultation,
} from "../../../api/urls/consultation";

/**
 * Consultation costs
 * @returns {JSX.Element}
 */
export default function ConsultationCostsStep({
  consultationId,
  patientId,
  setSucces,
  setFailed,
}) {
  const [t] = useTranslation("agendaitem");

  const [costs, setCosts] = useState();
  const [addCostPrompted, setAddCostPrompted] = useState(false);

  const [changed, setChanged] = useState(false);
  const updateCosts = () => setChanged(!changed);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    /**
     * Get costs from consultation
     */
    const url = urlGetConsultationByIdCosts(consultationId);
    axiosInstance
      .get(url, {
        setsRequestCancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const costs = response.data.costs;
        setCosts(costs);
      })
      .catch((error) => {
        setFailed(t("snackbars.failure.getconsultationcosts"));
      });
    return () => {
      cancelTokenSource.cancel();
    };
  }, [consultationId, setFailed, t, changed]);

  /**
   * Columns for the costs table
   * @returns {Column[]} columns
   */
  // prettier-ignore
  const columns = [
    new Column(0, "title", t("tables.columns.title"), 80),
    new Column(1, "compensationType", t("tables.columns.compensationType"), 80),
    new Column(2, "costAmount", t("tables.columns.costAmount"), 80),
  ];

  /**
   * Maps the costs for the datatable
   * @param {[]} costs
   */
  const mapCosts = (costs) => {
    return costs.map((cost) => {
      return {
        id: cost.id,
        title: cost.title,
        costAmount: mapValutaType(cost.valuta) + " " + cost.costAmount,
        compensationType: mapCompensationType(cost.compensationType),
      };
    });
  };

  /**
   * This will open the addConsultationCostForm.
   */
  const openAddCostForm = () => setAddCostPrompted(true);

  /**
   * This will close the addConsultationCostForm.
   */
  const closeAddCostForm = () => setAddCostPrompted(false);

  /**
   * This function will send a request to the server to add the cost to this consultation.
   * @param {cost} costObj
   */
  const addCostToConsultation = (costObj) => {
    const cost = {
      patientId: patientId,
      consultationId: consultationId,
      therapistId: null,
      title: costObj.title,
      compensationType:
        costObj.costType === 0
          ? costObj.paybackType
          : costObj.costType === 5
          ? 0
          : costObj.costType,
      complementaryPayBackAmount: Number(costObj.paybackAmount.toFixed(2)),
      costAmount: Number(Number(costObj.amount).toFixed(2)),
    };
    /**
     * Add cost to consultation
     */
    const url = urlPostCostToConsultation(consultationId);
    axiosInstance
      .post(url, cost)
      .then((response) => {
        setSucces(true);
        updateCosts();
      })
      .catch((error) => {
        console.error(`POST ${url}`, error);
        setFailed(t("snackbars.failure.addconsultationcost"));
      });
    closeAddCostForm();
  };

  return (
    <>
      <div style={{ marginBottom: "1.5em" }}>
        {!addCostPrompted && (
          <Button
            variant="outlined"
            onClick={() => openAddCostForm()}
            startIcon={<AddIcon />}
          >
            Add cost
          </Button>
        )}

        {addCostPrompted && (
          <CreateConsultationCostModal
            open={!!addCostPrompted}
            close={closeAddCostForm}
            setFailed={setFailed}
            handleSucces={addCostToConsultation}
          />
        )}
      </div>

      {costs && costs.length !== 0 && (
        <DataTable rows={mapCosts(costs)} columns={columns} />
      )}
    </>
  );
}
