/**
 * This will return true if the invoice can be deleted.
 * @param {Invoice} invoice
 * @returns {boolean} isDeletable
 */
export function isDeleteableInvoice(invoice) {
  // At this point in time a credit note cannot be deleted
  // At 
  if (invoice.isCreditNote === true) return false;
  // Delete can only happen when the invoice isn't finalized yet
  // If there are already invoices lines present in the invoice, the API will revert those "invoicelines" to "costs"
  // (boolean HasBeenAddedToInvoice (inside cost) back to false and invoiceline will be deleted with reference to cost)
  if (invoice.isProcessed === false) return true;
  return false;
}
