import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import ErrorAlert from "./ErrorAlert";

/**
 * Page Error Alert.
 *
 * This component is meant to show a user friendly alert when loading the page when something goes wrong.
 * (Not when there are asynchronous requests on the page itself!)
 *
 * There is only one prop for this component: error.
 * When initializing the component, the error is used to display the alert and if setIsLoadingPage is passed in, it is set to false.
 * Error consists of 2 properties namely: title and axiosError(status, message, method, url).
 *
 * @param {{ error: { title: string, axiosError: { status: number, message: string, method: string, baseURL: string, url: string } } }} props
 * @returns {JSX.Element} {@link ErrorAlert}
 */
export default function PageLoadingErrorAlert({ error }) {
  const [t] = useTranslation("alerts");

  return (
    <ErrorAlert
      title={error?.title && t(error.title)}
      // prettier-ignore
      description={
        error?.axiosError && error.axiosError.status
          ? `${error.axiosError.status} - ${t(`error.request.status.${error.axiosError.status}`)}`
          : error.axiosError.message
      }
      translate={false}
    />
  );
}

PageLoadingErrorAlert.propTypes = {
  error: PropTypes.shape({
    title: PropTypes.string,
    axiosError: PropTypes.object,
  }),
};
