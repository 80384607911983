/**
 * Get Agendaitem by ID
 * @param {number} agendaitemId
 * @returns {string} url
 */
export const urlGetAgendaitemById = (agendaitemId) =>
  `v2/agendaItems/${agendaitemId}`;

/**
 * Get agendaitems overview
 * @returns {string} url
 */
export const urlGetAgendaitemsOverview = () =>
  `v2/agendaItems/overviewAgendaItems`;
