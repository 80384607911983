import {
  EditingState,
  IntegratedEditing,
  Resources,
  ViewState,
} from "@devexpress/dx-react-scheduler";
import {
  AllDayPanel,
  ConfirmationDialog,
  CurrentTimeIndicator,
  DateNavigator,
  DayView,
  DragDropProvider,
  MonthView,
  Scheduler,
  TodayButton,
  Toolbar,
  ViewSwitcher,
  WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import { Paper, Stack } from "@mui/material";
import {
  blue,
  cyan,
  deepOrange,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  teal,
  yellow,
} from "@mui/material/colors";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { axiosInstance, handleAxiosError } from "../../api/axios";
import useAuth from "../../auth/useAuth";
import { locations } from "../../constants/consultation-constants";
import { agendaTypes } from "../../constants/scheduler-constants";
import { mapAgendaItemType } from "../../helpers/enumMappers"
import { getRange } from "../../helpers/scheduler/date-range";
import Header from "../shared/default/Header";
import NewButton from "../shared/iconButtons/NewButton";
import UnfoldButton from "../shared/iconButtons/UnfoldButton";
import PageLoading from "../shared/loading/PageLoading";
import TDAppointmentTooltip from "./extension-components/ApointmentTooltip";
import TDAppointmentForm from "./extension-components/AppointmentForm";
import TDAppointments from "./extension-components/Appointments";
import { Page } from "./../shared/default/Page";
import SuccesSnackbar from "./../shared/menus/snackbars/SuccesSnackbar";
import FailureSnackbar from "./../shared/menus/snackbars/FailureSnackbar";
import {
  urlPutConsultation,
  urlGetConsultationById,
} from "../../api/urls/consultation";

const style = ({ palette }) => ({
  icon: {
    color: palette?.action.active,
  },
  icon_cons: {
    color: "#FFC107",
  },
  textCenter: {
    textAlign: "center",
  },
  header: {
    height: "260px",
    backgroundSize: "cover",
  },
  commandButton: {
    backgroundColor: "rgba(255,255,255,0.65)",
  },
});

function SchedulerPOC() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewName, setViewName] = useState("Week");
  const [AppointmentData, setAppointmentData] = useState([]);
  const [changed, setChanged] = useState(false);
  const history = useHistory();
  const { t } = useTranslation(["scheduler", "translations"]);
  const { id, lang } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [failed, setFailed] = useState(false);
  const [succes, setSucces] = useState();
  const [resources] = useState([
    {
      fieldName: "agendaType",
      title: t("agenda.type.title", { ns: "translations"}),
      instances: agendaTypes.map((type) => {
        type.text = t(`${type.text}`, { ns: "translations"});
        return type;
      }),
    },
    {
      fieldName: "location",
      title: t("general.location.title", { ns: "translations"}),
      instances: locations.map((location) => {
        location.text = t(`${location.text}`, { ns: "translations"});
        return location;
      }),
    },
  ]);

  const handleFailed = (error) => {
    setError({
      title: "error.request.get.agendaitem",
      axiosError: handleAxiosError(error),
    });
  };

  useEffect(() => {
    let mounted = true;
    const mapDates = (items) => {
      return items.map((ai) => {
        if (ai.allDay) {
          return {
            ...{ userIds: [] },
            ...ai,
          };
        } else {
          return {
            ...{ userIds: [] },
            ...ai,
            ...{
              startDate: new Date(ai.startDate),
              endDate: new Date(ai.endDate),
            },
          };
        }
      });
    };

    axiosInstance
      .get(
        `/v2/agendaItems/overviewAgendaItems?UserId=${id}&from=${
          getRange(viewName, currentDate).from
        }&until=${getRange(viewName, currentDate).to}`
      )
      .then((response) => {
        if (mounted) {
          setAppointmentData(mapDates(response.data.agendaItems));
          setIsLoading(false);
        }
      })
      .catch((error) => handleFailed(error));
    setChanged(false);
    return () => {
      mounted = false;
    };
  }, [changed, currentDate, viewName, id]);
  const remove = (id) => {
    axiosInstance.delete(`/v2/agendaItems/${id}`).then(setChanged(true));
  };
  const update = (model) => {
    for (const [key, value] of Object.entries(model)) {
      let errors = validate(
        {
          // eslint-disable-next-line eqeqeq
          ...AppointmentData.find((a) => a.id == key),
          ...value,
        },
        t
      );

      if (!errors.length)
        axiosInstance
          .put(`/v2/agendaItems/${key}`, {
            // eslint-disable-next-line eqeqeq
            ...AppointmentData.find((a) => a.id == key),
            ...value,
          })
          .then((response) => {
            setChanged(true);
            const updatedAgendaItem = response.data;

            //Not yet scalable once there are more object other then consultations fix
            if (
              updatedAgendaItem.externalObjectId &&
              updatedAgendaItem.agendaType === 1
            ) {
              //Updating external object when agendaItem has been updated and linked to an external object
              const getConsultationByIdUrl = urlGetConsultationById(
                updatedAgendaItem.externalObjectId
              );
              axiosInstance
                .get(getConsultationByIdUrl)
                .then((response) => {
                  const consultationItem = response.data;
                  consultationItem.startTime = updatedAgendaItem.startDate;
                  consultationItem.endTime = updatedAgendaItem.endDate;
                  axiosInstance
                    .put(updateConsultationUrl, consultationItem)
                    .then((response) => {
                      setSucces(true);
                    })
                    .catch((response) => {});
                })
                .catch((response) => {
                  setFailed(t("snackbars.failure.updateconsultation"));
                });
              const updateConsultationUrl = urlPutConsultation(
                response.data.externalObjectId
              );
            }
          })
          .catch((error) => handleFailed(error));
      else {
        let string = t("error.missing");
        errors.forEach((e) => (string += e));
        alert(string);
      }
    }
  };
  const create = (model) => {
    let errors = validate(model, t);

    if (!errors.length)
      axiosInstance
        .post(`v2/agendaItems/`, {
          ...{ userIds: [] },
          ...model,
          ...{ organiserId: id },
        })
        .then(() => {
          setChanged(true);
        })
        .catch((error) => handleFailed(error));
    else {
      let string = t("error.missing");
      errors.forEach((e) => (string += e));
      alert(string);
    }
  };
  const onCommitChanges = (data) => {
    if (data.deleted) remove(data.deleted);
    if (data.changed) update(data.changed);
    if (data.added) create(data.added);
  };

  const handelNew = () => {
    history.push("/scheduler/new");
  };

  const handleNavigate = (newDate) => {
    setCurrentDate(newDate);
  };

  const handleViewChange = (newView) => {
    setViewName(newView);
  };

  const TimeTableCell = ({ onDoubleClick, ...restProps }) => {
    return (
      <DayView.TimeTableCell
        height={20}
        onClick={onDoubleClick}
        {...restProps}
      />
    );
  };

  const [allDay, setAllDay] = useState(false);
  const flexibleSpace = ({ ...restProps }) => (
    <Toolbar.FlexibleSpace {...restProps}>
      <Stack direction="row" spacing={2} sx={{ pr: 2, m: 2 }}>
        {viewName !== "Month" && (
          <UnfoldButton
            tooltip={
              allDay ? t("tooltips.unfold_close") : t("tooltips.unfold_open")
            }
            open={allDay}
            onClick={() => setAllDay(!allDay)}
          />
        )}
      </Stack>
    </Toolbar.FlexibleSpace>
  );

  return (
    <Paper>
      {isLoading ? (
        <Page
          content={
            <PageLoading
              header={<Header title={t("loading")} />}
              isLoading={isLoading}
              errorLoading={error}
            />
          }
        />
      ) : (
        <Scheduler
          data={AppointmentData}
          firstDayOfWeek={1}
          locale={`${lang}-BE`}
        >
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={handleNavigate}
            currentViewName={viewName}
            onCurrentViewNameChange={handleViewChange}
          />
          <EditingState onCommitChanges={onCommitChanges} />

          <IntegratedEditing />
          <DayView
            timeTableCellComponent={TimeTableCell}
            startDayHour={allDay ? 0 : 7}
            endDayHour={allDay ? 24 : 21}
          />
          <WeekView
            startDayHour={allDay ? 0 : 7}
            endDayHour={allDay ? 24 : 21}
          />
          <MonthView />
          <AllDayPanel />
          <TDAppointments />
          <TDAppointmentTooltip style={style} />
          <TDAppointmentForm />
          <ConfirmationDialog />
          <DragDropProvider />
          <CurrentTimeIndicator shadePreviousCells shadePreviousAppointments />
          <Toolbar flexibleSpaceComponent={flexibleSpace} />
          <DateNavigator />
          <TodayButton />
          <ViewSwitcher />
          <Resources
            data={resources}
            mainResourceName={"location"}
            palette={[
              indigo,
              blue,
              lightBlue,
              cyan,
              teal,
              green,
              lightGreen,
              lime,
              yellow,
              orange,
              deepOrange,
            ]}
          />
        </Scheduler>
      )}
      <SuccesSnackbar
        open={!!succes}
        setOpen={setSucces}
        text={t("snackbars.succes")}
      />
      <FailureSnackbar
        open={!!failed}
        setOpen={setFailed}
        text={t("snackbars.failure")}
      />
    </Paper>
  );
}
export default SchedulerPOC;

const validate = (appointment, t) => {
  const hasTitle = (object) => {
    return !!object.title;
  };
  const hasAgendaType = (object) => {
    if (object.agendaType === null) return false;
    if (object.agendaType === undefined) return false;
    return agendaTypes.map((at) => at.value).includes(object.agendaType);
  };
  const hasTimes = (object) => {
    if (object.startDate === null) return false;
    if (object.startDate === undefined) return false;
    if (object.endDate === null) return false;
    if (object.endDate === undefined) return false;
    return true;
  };
  const hasValidTimes = (object) => {
    let endtime = moment(object.endDate);
    let startTime = moment(object.startDate);
    if (endtime.isBefore(startTime)) return false;
    return true;
  };
  const hasNotes = (object) => {
    return !!object.notes;
  };
  const hasLocation = (object) => {
    if (object.location === null) return false;
    if (object.location === undefined) return false;
    return true;
  };
  let errors = [];
  // appointment is a consultation
  //
  if (appointment.agendaType === 1) {
    if (!hasTitle(appointment)) errors.push(t("error.noTitle"));
    if (!hasAgendaType(appointment)) errors.push(t("error.noType"));
    if (!hasTimes(appointment)) errors.push(t("error.noTimes"));
    if (!hasValidTimes(appointment)) errors.push(t("error.wrongTimes"));
    if (!hasLocation(appointment)) errors.push(t("error.noLocation"));
  }
  // appointment is a videoconsultation
  if (appointment.agendaType === 2) {
    if (!hasTitle(appointment)) errors.push(t("error.noTitle"));
    if (!hasAgendaType(appointment)) errors.push(t("error.noType"));
    if (!hasTimes(appointment)) errors.push(t("error.noTimes"));
    if (!hasValidTimes(appointment)) errors.push(t("error.wrongTimes"));
    if (!hasLocation(appointment)) errors.push(t("error.noLocation"));
  }
  // appointment is a meeting
  if (appointment.agendaType === 3) {
  }
  // appointment is an other type
  if (appointment.agendaType === 4) {
    if (!hasTitle(appointment)) errors.push(t("error.noTitle"));
  }

  return errors;
};
