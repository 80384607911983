import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import mapCompensationType from "../../helpers/enumMappers/mapCompensationType";
import BasicTable from "../shared/tables/BasicTable";
import { Column, FormattedColumn } from "../shared/tables/Datatable";
import { useEffect, useState } from "react";

function CostTable({ costs, textAlign = "center", responsiveWidth = false }) {
  const { t } = useTranslation(["costs", "translations"]);
  const [calculatedCosts, setCalculatedCosts] = useState([]);

  useEffect(() => {
    setCalculatedCosts(costs?.map(cost => {
      cost.paybackAmount = parsePayback(cost.compensationType, cost.complementaryPayBackAmount, cost.costAmount).toFixed(2);
      return cost;
    }))
  }, [costs])
  
  const parsePayback = (compensationType, paybackAmount, costAmount) => {
    switch(compensationType) {
      case 75:
        return costAmount * 0.75;
      case 60:
        return costAmount * 0.60;
      case 90:
        return costAmount * 0.90;
      case 100:
        return costAmount;
      case 101:
        return paybackAmount;
      default: 
        return 0;
    }
  }
return (
    <>
      {costs && !costs.length ? (
        <Typography variant="caption" display="block" gutter={2}>
          {t("messages.noCosts")}
        </Typography>
      ) : (
        <BasicTable
          columns={[
            new Column(
              0,
              "title",
              t("tables.columns.title"),
              responsiveWidth === true ? undefined : "15em"
            ),
            new FormattedColumn(
              1,
              "compensationType",
              t("tables.columns.compensationtype"),
              responsiveWidth === true ? undefined : 80,
              (val) => {
                return t(`${mapCompensationType(val)}`, { ns: "translations" });
              }
            ),
            new Column(
              2,
              "paybackAmount",
              t("tables.columns.complementaryPayBackAmount"),
              undefined,
              responsiveWidth === true ? undefined : "6em"
            ),
            new Column(
              3,
              "costAmount",
              t("tables.columns.cost"),
              undefined,
              responsiveWidth === true ? undefined : "6em"
            ),
          ]}
          rows={calculatedCosts}
          textAlign={textAlign}
        />
      )}
    </>
  );
}
export default CostTable;
