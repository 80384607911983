export const genderTypes = [{
    id: 0,
    value: 0,
    label: "general.gender.male"
  },
  {
    id: 1,
    value: 1,
    label: "general.gender.female"
  },
  {
    id: 2,
    value: 2,
    label: "general.gender.other"
  },
];