import DownloadIcon from "@mui/icons-material/Download";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { axiosInstance } from "../../../api/axios";
import { urlPutInvoiceExportNote } from "../../../api/urls/invoice";
import { checkOrUnCheckIcon } from "../../../helpers/checkOrUncheckIcon";
import { invoiceTypeIcon } from "../../../helpers/invoice/invoiceTypeIcon";
import DetailButton from "../../shared/iconButtons/DetailButton";
import FailureSnackbar from "../../shared/menus/snackbars/FailureSnackbar";
import SuccesSnackbar from "../../shared/menus/snackbars/SuccesSnackbar";
import InvoiceExportModal from "../shared/InvoiceExportModal";
import DataTable, {
  Column,
  SortableColumn,
  SortableFormattedColumn,
} from "./../../shared/tables/Datatable";

/**
 * List of Invoices
 * @param {props} props
 * @returns {JSX.Element}
 */
export default function InvoiceList({
  invoices,
  changed,
  updateList,
  page,
  pageSize,
  rowCount,
  setPage,
  setPageSize,
  order,
  setOrder,
  orderBy,
  setOrderBy,
}) {
  const [t] = useTranslation("invoices");
  const history = useHistory();
  const [exportPrompted, setExportPrompted] = useState(false);
  const [rows, setRows] = useState([]);
  const [succes, setSucces] = useState();
  const [failed, setFailed] = useState();

  /**
   * Columns
   * @type {{Column | FormattedColumn} []}
   */
  const columns = [
    new Column(0, "type", t("tables.columns.type"), 20),
    new Column(1, "fullName", t("tables.columns.name"), 100),
    new SortableColumn(2, "number", t("tables.columns.number"), 30),
    // prettier-ignore
    new SortableFormattedColumn(3, "date", t("tables.columns.date"), 80, (value) => moment(value).format("DD/MM/YYYY")),
    // prettier-ignore
    new SortableFormattedColumn(4, "duedate", t("tables.columns.duedate"), 80, (value) => moment(value).format("DD/MM/YYYY")),
    new Column(5, "isPaid", t("tables.columns.isPaid"), 15),
    new Column(6, "actions", "", undefined, 350),
  ];

  useEffect(() => {
    const parsedInvoiceFields = invoices.map((d) => {
      const typeText = d.invoice.isCreditNote ? t("creditnote") : t("invoice");
      return {
        id: d.invoice.id,
        fullName: d.userFullName.firstName + " " + d.userFullName.lastName,
        type: invoiceTypeIcon(d.invoice),
        number: d.invoice.number,
        date: new Date(d.invoice.date),
        duedate: new Date(d.invoice.dueDate),
        creditor: d.invoice.creditorId,
        customer: d.invoice.customerId,
        isPaid: checkOrUnCheckIcon(d.invoice.isPaid),
        actions: (
          <>
            {/* Detail */}
            <DetailButton
              tooltip={t("tooltips.title.detail")}
              onClick={() => history.push(`invoices/${d.invoice.id}`)}
              color="primary"
            />
            {/* Export & Download*/}
            {d.isProcessed === false ? (
              <Tooltip title={t("tooltips.title.export")}>
                <IconButton
                  onClick={() => {
                    // Added extra close because when the user choses other invoice to export it will see the change
                    closeInvoiceExportModal();
                    setExportPrompted(d.invoice.id);
                  }}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t("tooltips.title.download", { type: typeText })}>
                <IconButton
                  onClick={() => {
                    history.push(`/invoices/${d.invoice.id}/export`);
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
      };
    });

    setRows(parsedInvoiceFields);

    return () => {};
  }, [history, invoices, changed, t]);

  /**
   * Closes the Invoice export modal
   */
  const closeInvoiceExportModal = () => setExportPrompted(false);

  /**
   * Updates the invoice exportNotes
   * @param {{exportNote: string}} validObject
   */
  const updateExportNote = (validObject) => {
    const invoiceId = Number(exportPrompted);
    const exportNotes = validObject.exportNotes;
    const url = urlPutInvoiceExportNote(invoiceId);
    axiosInstance
      .put(url, { exportNotes: exportNotes })
      .then((reponse) => {
        const updatedInvoiceId = reponse.data.invoiceId;
        history.push(`/invoices/${updatedInvoiceId}/export`);
      })
      .catch((error) => {
        setFailed(`${t("snackbars.failure.delete")}`);
      });
    closeInvoiceExportModal();
  };

  const handleSort = (row) => () => {
    let newOrderBy = columns[row].field.toUpperCase();
    if (orderBy === newOrderBy) {
      // Change order
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      // Change orderBy
      setOrderBy(newOrderBy);
    }
  };

  return (
    <>
      {rows && (
        <>
          {rows.length === 0 && <p>{t("tables.empty.invoices")}</p>}
          {rows.length > 0 && (
            <DataTable
              rows={rows}
              columns={columns}
              rowCount={rowCount}
              pageSize={pageSize}
              page={page}
              setPage={setPage}
              setPageSize={setPageSize}
              createSortHandler={(value) => handleSort(value)}
              order={order}
              orderBy={orderBy}
            />
          )}
        </>
      )}
      {exportPrompted && (
        <InvoiceExportModal
          open={!!exportPrompted}
          close={closeInvoiceExportModal}
          handleSucces={updateExportNote}
          setFailed={setFailed}
        />
      )}
      <SuccesSnackbar
        open={succes}
        setOpen={setSucces}
        text={t("snackbars.succes.succes")}
      />
      <FailureSnackbar open={!!failed} setOpen={setFailed} text={failed} />
    </>
  );
}
