import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import { useHistory } from "react-router";
import { useEffect } from "react";
import {
  urlGetDoesCollectionStateExistForInvoice,
  urlGetCollectionStateByInvoiceId,
} from "../../../../api/urls/collectionstateinvoice/get";
import { Button } from "@mui/material";
import { axiosInstance } from "../../../../api/axios";
import ButtonModal from "./ButtonModal";

export default function DownloadModal({
  open,
  close,
  invoiceId,
  patientId,
  thirdPayerSystem,
  hasRefundableCosts,
  isCreditNote,
}) {
  const [t] = useTranslation("invoices");

  const history = useHistory();

  // Download invoicePdf button
  const downloadInvoicePdfButton = () => {
    var text = isCreditNote
      ? t("actions.downloadcreditnote")
      : t("actions.downloadinvoice");

    var button = (
      <Button
        variant="outlined"
        onClick={() => history.push(`/invoices/export/invoice/${invoiceId}`)}
        startIcon={<DownloadIcon />}
      >
        {text}
      </Button>
    );

    return button;
  };

  const urlGetDoesCollectionStateExist =
    urlGetDoesCollectionStateExistForInvoice(invoiceId);

  const collectionStateUrlString = () =>
    axiosInstance

      .get(urlGetDoesCollectionStateExist)

      .then((response) => {
        if (response.data) {
          const urlGetCollectionStateWithInvoiceId =
            urlGetCollectionStateByInvoiceId(invoiceId);

          axiosInstance

            .get(urlGetCollectionStateWithInvoiceId)

            .then((response) => {
              history.push(`/invoices/export/collectionstate/${invoiceId}`);
            });
        } else {
          history.push(
            `/invoices/${invoiceId}/export/collectionState/user/${patientId}`
          );
        }
      })

      .catch((response) => history.push("/404"));

  // Download invoicePdf button

  const downloadCollectionStatePdfButton = (
    <Button
      variant="outlined"
      onClick={() => collectionStateUrlString()}
      startIcon={<DownloadIcon />}
    >
      {t("actions.downloadcollectionstate")}
    </Button>
  );

  const getTitleText = () => {
    var text = "";

    if (isCreditNote) {
      text = t("modal.export.creditnote");
    } else if (
      thirdPayerSystem === undefined ||
      (thirdPayerSystem === false && isCreditNote === false) ||
      !hasRefundableCosts ||
      !isCreditNote
    ) {
      text = t("modal.export.downloadwithoutthirdpayer");
    } else {
      text = t("modal.export.downloadwiththirdpayer");
    }

    return text;
  };

  return (
    <ButtonModal
      open={open}
      title={getTitleText()}
      content={
        <>
          {downloadInvoicePdfButton()}{" "}
          {thirdPayerSystem &&
            hasRefundableCosts === true &&
            !isCreditNote &&
            downloadCollectionStatePdfButton}
        </>
      }
      handleClose={close}
    />
  );
}
