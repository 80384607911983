import { Text, View } from "@react-pdf/renderer";

/**
 * Users section for the Invoice PDF
 * @param {{ styles: {}, users: []}} props
 * @returns {View} Users
 */
export default function CollectionStatePdfProviderIdentificationSection({
  styles,
  user,
  providerIdentificationText,
}) {
  return (
    // prettier-ignore
    <View style={{ ...styles.row, ...styles.rowInvoiceUsers }}>
      {user.map((item) => {
        return (
          <View key={item.id}>
            <Text style={styles.title}>{providerIdentificationText[0].value}</Text>
            {item.title && <Text style={styles.textText}>{providerIdentificationText[1].value}: {item.title}</Text>}
            {item.address && <Text style={styles.textText}>{providerIdentificationText[2].value}: {item.address}</Text>}
            {item.rizivNumber && <Text style={styles.textText}>{providerIdentificationText[3].value}: {item.rizivNumber}</Text>}
            {item.iban && <Text style={styles.textText}>{providerIdentificationText[4].value}: {item.iban}</Text>}
          </View>
        );
      })}
    </View>
  );
}
